import React from 'react'
import { useState, useEffect } from 'react'
import Select from 'react-select';
import Base from '../../Components/Base/Base'


import img01 from '../../custom assets/images/brands/bikes/harley davidson.png'
import img02 from '../../custom assets/images/brands/bikes/honda.png'
import img03 from '../../custom assets/images/brands/bikes/yamaha.png'
import img04 from '../../custom assets/images/brands/cars/alpha.png'
import img05 from '../../custom assets/images/brands/cars/maserati.png'
import img06 from '../../custom assets/images/brands/cars/freeari.png'
import img07 from '../../custom assets/images/brands/cars/tesla.png'
import img08 from '../../custom assets/images/brands/cars/mistsubishi.png'
import img09 from '../../custom assets/images/brands/appliance/whirlppol.png'
import img10 from '../../custom assets/images/brands/appliance/sony.png'
import img11 from '../../custom assets/images/brands/appliance/samsung.png'
import img12 from '../../custom assets/images/brands/furniture/ashley.png'
import img13 from '../../custom assets/images/brands/furniture/ikea.png'
import img14 from '../../custom assets/images/brands/furniture/westelm.png'
import { Link } from 'react-router-dom';



const initialData = [
    { id: 1, BrandName: 'Harley Davidson', Image: img01, Description: 'Known for its heavyweight cruisers and iconic designs', CategoryName: "Bikes", Status: 'Active', AdsCount: '12', Actions: 'act', },
    { id: 2, BrandName: 'Honda', Image: img02, Description: 'Offers a wide range of motorcycles, including sport bikes, cruisers, and off - road bikes', CategoryName: 'Bikes', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 3, BrandName: 'Yamaha', Image: img03, CategoryName: 'Bikes', Description: 'Known for its diverse lineup, including sport bikes, dirt bikes, and cruisers.', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 4, BrandName: 'Alpha Romero', Image: img04, CategoryName: 'Cars', Description: 'Stylish Italian luxury sports cars', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 5, BrandName: 'Maserati', Image: img05, CategoryName: 'Cars', Description: 'Italian luxury performance and elegance', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 6, BrandName: 'Ferrari', Image: img06, CategoryName: 'Cars', Description: 'Iconic high - performance sports cars', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 7, BrandName: 'Tesla', Image: img07, CategoryName: 'Cars', Description: 'Innovative electric vehicles pioneer.', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 8, BrandName: 'Mistsubishi', Image: img08, CategoryName: 'Cars', Description: 'Reliable and versatile automotive manufacturer', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 9, BrandName: 'whirlpool', Image: img09, CategoryName: 'Appliance', Description: 'Durable, versatile storage, efficient cooling, trusted household name', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 10, BrandName: 'Sony', Image: img10, CategoryName: 'Appliance', Description: 'Superior picture quality, advanced sound, reliable performance, elegant design', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 11, BrandName: 'Samsung', Image: img11, CategoryName: 'Appliance', Description: 'Innovative designs, smart features, energy - efficient, reliable performance', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 12, BrandName: 'Ashley Furniture', Image: img12, CategoryName: 'Appliance', Description: 'Stylish designs, durable materials, comfortable seating, versatile, affordable options', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 13, BrandName: 'IKEA', Image: img13, CategoryName: 'Appliance', Description: 'Modern, customizable, space - efficient, budget - friendly, functional, stylish seating solutions', Status: 'Active', AdsCount: '12', Actions: 'act' },
    { id: 14, BrandName: 'West Ilm', Image: img14, CategoryName: 'Appliance', Description: 'Elegant design, high - quality materials, comfortable, contemporary style, durable', Status: 'Active', AdsCount: '12', Actions: 'act' },
]
const subcats = [
   
    'Bikes',
    'Cars',
    'Furniture',
    'Appliances',
   
];
const BrandsList = () => {
    const [data, setData] = useState(initialData);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed


    // Reset current page to 1 when searchQuery or statusFilter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);



    //add category modal start
    const [brandName, setBrandName] = useState('');
    const handleBrandChange = (event) => {
        setBrandName(event.target.value);
        console.log(brandName)
    };

    const [Description, setDescription] = useState('');
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        console.log(Description)
    };

    const [status, setStatus] = useState('Active');
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    console.log(status)

    const [service, setService] = useState('NotService');
    const handleServiceChange = (event) => {
        setStatus(event.target.value);
    };

    const onChange = (selectedOption) => {
        console.log(`selected ${selectedOption.value}`);
    };

    //add category modal end

    // Filter data based on search query for all columns
    const filteredData = data.filter(item => {
        const itemString = Object.values(item).join(' ').toLowerCase();
        const matchesSearch = itemString.includes(searchQuery.toLowerCase());
        return matchesSearch;
    });

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span key="ellipsis">...</span>;

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'

                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    disabled={currentPage === 1}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-outline-primary'

                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(ellipsis);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-primary'

                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    // Function to get the color based on status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return 'badge badge-light-primary';
            case 'Inactive':
                return 'badge badge-light-danger';
            default:
                return 'black';
        }
    };
    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <Link to="/" className="text-gray-600 text-hover-primary">
                                    <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </Link>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}

                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Brands</li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-500">Brands List</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0 float-start">
                            Brands list</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}
                    {/*<!--begin::Action-->*/}
                    {/* <p className="btn bg-body d-flex flex-center h-35px h-lg-40px" data-bs-toggle="modal"
                        data-bs-target="#kt_modal_create_campaign">Create
                        <span className="d-none d-sm-inline ps-2">New</span></p> */}
                    {/*<!--end::Action-->*/}
                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Card-->*/}
                    <div className="card">
                        {/*<!--begin::Card header-->*/}
                        <div className="card-header border-0 pt-6">
                            {/*<!--begin::Card title-->*/}
                            <div className="card-title">
                                {/*<!--begin::Search-->*/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-customer-table-filter="search"
                                        className="form-control form-control-solid w-250px ps-12"
                                        placeholder="Search Brands"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                </div>
                                {/*<!--end::Search-->*/}
                            </div>
                            {/*<!--begin::Card title-->*/}
                            {/*<!--begin::Card toolbar-->*/}
                            <div className="card-toolbar">
                                {/*<!--begin::Toolbar-->*/}
                                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                    {/*<!--begin::Filter-->*/}
                                    <button type="button" className="btn btn-light-primary me-3"
                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i className="ki-duotone ki-filter fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>Filter</button>
                                    {/*<!--begin::Menu 1-->*/}
                                    <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                        data-kt-menu="true" id="kt-toolbar-filter">
                                        {/*<!--begin::Header-->*/}
                                        <div className="px-7 py-5">
                                            <div className="fs-4 text-gray-900 fw-bold">Filter Options</div>
                                        </div>
                                        {/*<!--end::Header-->*/}
                                        {/*<!--begin::Separator-->*/}
                                        <div className="separator border-gray-200"></div>
                                        {/*<!--end::Separator-->*/}
                                        {/*<!--begin::Content-->*/}
                                        <div className="px-7 py-5">
                                            {/*<!--begin::Input group-->*/}
                                            <div className="mb-10">
                                                {/*<!--begin::Label-->*/}
                                                <label className="form-label fs-5 fw-semibold mb-3">Month:</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input-->*/}
                                                <select className="form-select form-select-solid fw-bold"
                                                    data-kt-select2="true" data-placeholder="Select option"
                                                    data-allow-clear="true" data-kt-customer-table-filter="month"
                                                    data-dropdown-parent="#kt-toolbar-filter">
                                                    <option></option>
                                                    <option value="aug">August</option>
                                                    <option value="sep">September</option>
                                                    <option value="oct">October</option>
                                                    <option value="nov">November</option>
                                                    <option value="dec">December</option>
                                                </select>
                                                {/*<!--end::Input-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Input group-->*/}
                                            <div className="mb-10">
                                                {/*<!--begin::Label-->*/}
                                                <label className="form-label fs-5 fw-semibold mb-3">Payment
                                                    Type:</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Options-->*/}
                                                <div className="d-flex flex-column flex-wrap fw-semibold"
                                                    data-kt-customer-table-filter="payment_type">
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="all" checked="checked" />
                                                        <span className="form-check-label text-gray-600">All</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="visa" />
                                                        <span className="form-check-label text-gray-600">Visa</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="mastercard" />
                                                        <span
                                                            className="form-check-label text-gray-600">Mastercard</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="american_express" />
                                                        <span className="form-check-label text-gray-600">American
                                                            Express</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                </div>
                                                {/*<!--end::Options-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Actions-->*/}
                                            <div className="d-flex justify-content-end">
                                                <button type="reset"
                                                    className="btn btn-light btn-active-light-primary me-2"
                                                    data-kt-menu-dismiss="true"
                                                    data-kt-customer-table-filter="reset">Reset</button>
                                                <button type="submit" className="btn btn-primary"
                                                    data-kt-menu-dismiss="true"
                                                    data-kt-customer-table-filter="filter">Apply</button>
                                            </div>
                                            {/*<!--end::Actions-->*/}
                                        </div>
                                        {/*<!--end::Content-->*/}
                                    </div>
                                    {/*<!--end::Menu 1-->*/}
                                    {/*<!--end::Filter-->*/}
                                    {/*<!--begin::Export-->*/}
                                    <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal"
                                        data-bs-target="#kt_customers_export_modal">
                                        <i className="ki-duotone ki-exit-up fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>Export</button>
                                    {/*<!--end::Export-->*/}

                                    {/* <div className="dropdown">
                                        <button type="button" className="btn btn-primary dropdown-toggle m-0 no-caret"
                                            data-bs-toggle="dropdown">
                                            <i className="bi bi-person-lines-fill fs-1"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" href="#">Contact Adminstrator</Link></li>
                                            <li><Link className="dropdown-item" href="#">Link 2</Link></li>
                                            <li><Link className="dropdown-item" href="#">Link 3</Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                                {/*<!--end::Toolbar-->*/}
                                {/*<!--begin::Group actions-->*/}
                                <div className="d-flex justify-content-end align-items-center d-none"
                                    data-kt-customer-table-toolbar="selected">
                                    <div className="fw-bold me-5">
                                        <span className="me-2"
                                            data-kt-customer-table-select="selected_count"></span>Selected
                                    </div>
                                    <button type="button" className="btn btn-danger"
                                        data-kt-customer-table-select="delete_selected">Delete Selected</button>
                                </div>
                                {/*<!--end::Group actions-->*/}
                            </div>
                            {/*<!--end::Card toolbar-->*/}
                        </div>
                        {/*<!--end::Card header-->*/}
                        {/*<!--begin::Card body-->*/}
                        <div className="card-body pt-0">
                            {/*<!--begin::Table-->*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                                <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="w-10px pe-2">
                                            <div
                                                className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" data-kt-check="true"
                                                    data-kt-check-target="#kt_customers_table .form-check-input"
                                                    value="1" />
                                            </div>
                                        </th>
                                        <th className="text-center">Brand Name</th>
                                        <th className="text-center">Image</th>
                                        <th className="text-center">Description</th>
                                        <th className="text-center">Category Name</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">No. of Ads</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {currentData.map(item => (
                                        <tr key={item.id}>
                                            <td className='text-center'>{item.id}</td>
                                            <td className='text-center'>{item.BrandName}</td>
                                            <td>
                                                <img src={item.Image} width="90px" className='img-thumbnail' />
                                            </td>
                                            <td className='text-center'><span className='d-inline-block text-truncate' style={{ maxWidth: '150px' }}>{item.Description}</span></td>
                                            <td className='text-center'>{item.CategoryName}</td>
                                            <td><span className={getStatusColor(item.Status)}><b>{item.Status}</b></span></td>
                                            <td>{item.AdsCount}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        {/* <li><Link className="dropdown-item" href="#">Edit</Link></li> */}
                                                        <li><Link className="dropdown-item" href="#">View</Link></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '10px' }} className='float-end'>
                                <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                {renderPageNumbers()}
                                <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    Next
                                </button>
                            </div>
                            {/*<!--end::Table-->*/}
                        </div>
                        {/*<!--end::Card body-->*/}
                    </div>
                    {/*<!--end::Card-->*/}
                    {/*<!--begin::Modals-->*/}

                    {/*<!--begin::Modal - Adjust Balance-->*/}
                    <div className="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div className="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div className="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div className="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 className="fw-bold">Export Sellers</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div id="kt_customers_export_close"
                                        className="btn btn-icon btn-sm btn-active-icon-primary">
                                        <i className="ki-duotone ki-cross fs-1">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Form-->*/}
                                    <form id="kt_customers_export_form" className="form" action="#">
                                        {/*<!--begin::Input group-->*/}
                                        <div className="fv-row mb-10">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Select Export
                                                Format:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <select data-control="select2" data-placeholder="Select a format"
                                                data-hide-search="true" name="format"
                                                className="form-select form-select-solid">
                                                <option value="excell">Excel</option>
                                                <option value="pdf">PDF</option>
                                                <option value="cvs">CVS</option>
                                                <option value="zip">ZIP</option>
                                            </select>
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div className="fv-row mb-10">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Select Date
                                                Range:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input className="form-control form-control-solid" placeholder="Pick a date"
                                                name="date" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Row-->*/}
                                        <div className="row fv-row mb-15">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Payment Type:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Radio group-->*/}
                                            <div className="d-flex flex-column">
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="1"
                                                        checked="checked" name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">All</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="2"
                                                        checked="checked" name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">Visa</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="3"
                                                        name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid">
                                                    <input className="form-check-input" type="checkbox" value="4"
                                                        name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">American
                                                        Express</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                        </div>
                                        {/*<!--end::Row-->*/}
                                        {/*<!--begin::Actions-->*/}
                                        <div className="text-center">
                                            <button type="reset" id="kt_customers_export_cancel"
                                                className="btn btn-light me-3">Discard</button>
                                            <button type="submit" id="kt_customers_export_submit"
                                                className="btn btn-primary">
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>
                                        {/*<!--end::Actions-->*/}
                                    </form>
                                    {/*<!--end::Form-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - New Card-->*/}
                    {/*<!--end::Modals-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
            {/*<!--begin::Modal - Create Campaign-->*/}
            <div className="modal fade" id="kt_modal_create_campaign" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-rounded">
                        <div className="modal-header d-flex ">
                            <h2>Create Brand</h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <i className="ki-duotone ki-cross fs-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <form className="mx-auto w-100 mw-800px" novalidate="novalidate" id="kt_modal_create_campaign_stepper_form">
                                <div className="current" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="row">
                                            <div className="mb-3 fv-row col-6">
                                                <label className="required form-label mb-3 float-start">Brand Name</label>
                                                <input type="text"
                                                    id="textInput"
                                                    value={brandName}
                                                    onChange={handleBrandChange}
                                                    className="form-control form-control-solid"
                                                    name="brandName" />
                                            </div>
                                            <div className="mb-3 fv-row col-6 row">
                                                <label className="form-label fs-5 fw-semibold mb-3 col-2">Category:</label>
                                               
                                              <div className='col-12'>
                                                    <Select
                                                        placeholder="Select a subcategory"
                                                        onChange={onChange}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : 'white', // Border color when focused and not focused
                                                                boxShadow: state.isFocused ? '0 0 0 1px grey' : 'none', // Shadow effect when focused
                                                                backgroundColor: '#f9f9f9', // Background color of the select control
                                                            }),
                                                            valueContainer: (baseStyles) => ({
                                                                ...baseStyles,
                                                                height: '40px', // Match the height of the value container
                                                                padding: '0 6px', // Adjust padding if necessary
                                                            }),
                                                            menu: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderRadius: '5px', // Border radius of the dropdown menu
                                                            }),
                                                        }}
                                                        options={subcats.map((subcat) => ({
                                                            value: subcat,
                                                            label: subcat,
                                                        }))}
                                                    />
                                              </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="fv-row mb-3 col-12 col-md-4">
                                                <label className="d-block fw-semibold fs-6 mb-5">
                                                    <span className="required">Category Thumbnail</span>
                                                    <span className="ms-1" data-bs-toggle="tooltip"
                                                        title="E.g. Select a logo to represent the company that's running the campaign.">
                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                        </i>
                                                    </span>
                                                </label>
                                                <div className="image-input image-input-empty image-input-outline image-input-placeholder"
                                                    data-kt-image-input="true">
                                                    <div className="image-input-wrapper w-125px h-125px"></div>
                                                    <label
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                                        title="Change avatar">
                                                        <i className="ki-duotone ki-pencil fs-7">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                        <input type="hidden" name="avatar_remove" />
                                                    </label>
                                                    <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                                        title="Cancel avatar">
                                                        <i className="ki-duotone ki-cross fs-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </span>
                                                    <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="remove" data-bs-toggle="tooltip"
                                                        title="Remove avatar">
                                                        <i className="ki-duotone ki-cross fs-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </span>
                                                </div>
                                                <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                            </div>
                                            <div className="col-12 col-md-8 row">
                                                <div className="mb-3  fv-row col-12">
                                                    <label className="required form-label mb-5 float-start">Description</label>
                                                    <textarea
                                                        name="Description"
                                                        value={Description}
                                                        onChange={handleDescriptionChange}
                                                        className="form-control form-control-solid"
                                                        rows="3"
                                                        cols="50"
                                                    />
                                                </div>
                                                <div className="mb-3 row col-5">
                                                    <label className="required fw-semibold fs-6 mb-5 col-12 text-start">Status</label>
                                                    <div className="d-flex fv-row col-6">
                                                        <div className="form-check form-check-custom form-check-solid">
                                                            <input className="form-check-input me-3"
                                                                id="kt_modal_update_role_option_0"
                                                                type="radio"
                                                                value="Active"
                                                                checked={status === 'Active'}
                                                                onChange={handleStatusChange} />
                                                            <label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
                                                                <div className="fw-bold text-gray-800">Active</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex fv-row col-6">
                                                        <div className="form-check form-check-custom form-check-solid">
                                                            <input className="form-check-input me-3"
                                                                id="kt_modal_update_role_option_1"
                                                                type="radio"
                                                                value="Inactive"
                                                                checked={status === 'Inactive'}
                                                                onChange={handleStatusChange} />
                                                            <label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
                                                                <div className="fw-bold text-gray-800">Inactive</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )
}

export default BrandsList