import React from 'react'
import Base from '../../Components/Base/Base'
import { Link } from 'react-router-dom'

const BuyerDetails = () => {
    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div class="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" class="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div class="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li class="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <a href="../../index.html" class="text-gray-600 text-hover-primary">
                                    <i class="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </a>
                            </li>
                            {/*<!--end::Item-->*/}

                            {/*<!--begin::Item-->*/}
                            <li class="breadcrumb-item">
                                <i class="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}

                            {/*<!--begin::Item-->*/}
                            <li class="breadcrumb-item text-gray-700 fw-bold lh-1"><Link to="/buyers"
                                class="text-gray-600 text-hover-primary">Buyer List</Link></li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li class="breadcrumb-item">
                                <i class="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li class="breadcrumb-item text-gray-500">Buyer Details</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Buyer Details</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}

                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div class="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Layout-->*/}
                    <div class="d-flex flex-column flex-xl-row">
                        {/*<!--begin::Sidebar-->*/}
                        <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                            {/*<!--begin::Card-->*/}
                            <div class="card mb-5 mb-xl-8">
                                {/*<!--begin::Card body-->*/}
                                <div class="card-body pt-15">
                                    {/*<!--begin::Summary-->*/}
                                    <div class="d-flex flex-center flex-column mb-5">
                                        {/*<!--begin::Avatar-->*/}
                                        <div class="symbol symbol-150px symbol-circle mb-7">
                                            <img src="../../assets/media/avatars/300-1.jpg" alt="image" />
                                        </div>
                                        {/*<!--end::Avatar-->*/}
                                        {/*<!--begin::Name-->*/}
                                        <p class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">Max
                                            Smith</p>
                                        {/*<!--end::Name-->*/}
                                        {/*<!--begin::Email-->*/}
                                        <p
                                            class="fs-5 fw-semibold text-muted text-hover-primary mb-6">max@kt.com</p>
                                        {/*<!--end::Email-->*/}
                                    </div>
                                    {/*<!--end::Summary-->*/}
                                    {/*<!--begin::Details toggle-->*/}
                                    <div class="d-flex flex-stack fs-4 py-3">
                                        <div class="fw-bold">Details</div>
                                        {/*<!--begin::Badge-->*/}
                                        <div class="badge badge-light-info d-inline">Premium user</div>
                                        {/*<!--begin::Badge-->*/}
                                    </div>
                                    {/*<!--end::Details toggle-->*/}
                                    <div class="separator separator-dashed my-3"></div>
                                    {/*<!--begin::Details content-->*/}
                                    <div class="pb-5 fs-6 text-start">
                                        {/*<!--begin::Details item-->*/}
                                        <div class="fw-bold mt-5">Account ID</div>
                                        <div class="text-gray-600">ID-45453423</div>
                                        {/*<!--begin::Details item-->*/}
                                        {/*<!--begin::Details item-->*/}
                                        <div class="fw-bold mt-5">Billing Email</div>
                                        <div class="text-gray-600">
                                            <p 
                                                class="text-gray-600 text-hover-primary">info@max.com</p>
                                        </div>
                                        {/*<!--begin::Details item-->*/}
                                        {/*<!--begin::Details item-->*/}
                                        <div class="fw-bold mt-5">Delivery Address</div>
                                        <div class="text-gray-600">101 Collin Street,
                                            <br />Melbourne 3000 VIC
                                            <br />Australia
                                        </div>
                                        {/*<!--begin::Details item-->*/}
                                        {/*<!--begin::Details item-->*/}
                                        <div class="fw-bold mt-5">Language</div>
                                        <div class="text-gray-600">English</div>
                                        {/*<!--begin::Details item-->*/}
                                        {/*<!--begin::Details item-->*/}
                                        <div class="fw-bold mt-5">Latest Transaction</div>
                                        <div class="text-gray-600">
                                            <a href="apps/ecommerce/sales/details.html"
                                                class="text-gray-600 text-hover-primary">#14534</a>
                                        </div>
                                        {/*<!--begin::Details item-->*/}
                                    </div>
                                    {/*<!--end::Details content-->*/}
                                </div>
                                {/*<!--end::Card body-->*/}
                            </div>
                            {/*<!--end::Card-->*/}
                        </div>
                        {/*<!--end::Sidebar-->*/}
                        {/*<!--begin::Content-->*/}
                        <div class="flex-lg-row-fluid ms-lg-15">
                            {/*<!--begin:::Tabs-->*/}
                            <ul
                                class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                                {/*<!--begin:::Tab item-->*/}
                                <li class="nav-item">
                                    <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                        href="#kt_ecommerce_customer_overview">Overview</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                                {/*<!--begin:::Tab item-->*/}
                                <li class="nav-item">
                                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                        href="#kt_ecommerce_customer_general">General Settings</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                               
                            </ul>
                            {/*<!--end:::Tabs-->*/}
                            {/*<!--begin:::Tab content-->*/}
                            <div class="tab-content" id="myTabContent">
                                {/*<!--begin:::Tab pane-->*/}
                                <div class="tab-pane fade show active" id="kt_ecommerce_customer_overview"
                                    role="tabpanel">
                                    <div class="row mb-6">
                                        <div class="col-md-4 col-12">
                                            {/*<!--begin::Card-->*/}
                                            <div class="card  h-md-100">
                                                {/*<!--begin::Card header-->*/}
                                                
                                                <div className="card-body d-flex">
                                                    <div className="row">
                                                        <div className="fs-1 fw-bold text-muted text-start">
                                                            04/23/2024
                                                        </div>
                                                        <div className="text-info fw-bold fs-4 text-start">
                                                            Subscription date
                                                        </div>
                                                    </div>
                                                   
                                                    <i class="ki-duotone ki-calendar text-info fs-3x pt-3">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                    </i>
                                                </div>
                                                {/*<!--end::Card body-->*/}
                                            </div>
                                            {/*<!--end::Card-->*/}
                                        </div>
                                        <div class="col-md-4 col-12">
                                            {/*<!--begin::Reward Tier-->*/}
                                            <div class="card bg-info  h-md-100">
                                                {/*<!--begin::Body-->*/}
                                                <div className="card-body d-flex">
                                                    <div className="row">
                                                        <div className="fs-1 fw-bold text-white text-start">
                                                            $3,262
                                                        </div>
                                                        <div className="text-white fw-bold fs-4 text-start">
                                                            Total Expense
                                                        </div>
                                                    </div>
                                                    <i className="bi bi-cash-coin text-white fs-3x pt-3">
                                                    </i>
                                                </div>
                                                {/*<!--end::Body-->*/}
                                            </div>
                                            {/*<!--end::Reward Tier-->*/}
                                        </div>
                                        <div class="col-md-4 col-12">
                                            {/*<!--begin::Reward Tier-->*/}
                                            <a href="#" class="card bg-success hoverable h-md-100">
                                                {/*<!--begin::Body-->*/}
                                                <div className="card-body d-flex">
                                                    <div className="row">
                                                        <div className="fs-1 fw-bold text-white text-start">
                                                            21
                                                        </div>
                                                        <div className="text-white fw-bold fs-4 text-start">
                                                            Purchase Count
                                                        </div>
                                                    </div>
                                                    <i className="bi bi-cart-check text-white fs-3x pt-3">
                                                    </i>
                                                </div>
                                                {/*<!--end::Body-->*/}
                                            </a>
                                            {/*<!--end::Reward Tier-->*/}
                                        </div>
                                    </div>
                                    {/*<!--begin::Card-->*/}
                                    <div class="card pt-4 mb-6 mb-xl-9">
                                        {/*<!--begin::Card header-->*/}
                                        <div class="card-header border-0">
                                            {/*<!--begin::Card title-->*/}
                                            <div class="card-title">
                                                <h2>Transaction History</h2>
                                            </div>
                                            {/*<!--end::Card title-->*/}
                                        </div>
                                        {/*<!--end::Card header-->*/}
                                        {/*<!--begin::Card body-->*/}
                                        <div class="card-body pt-0 pb-5">
                                            {/*<!--begin::Table-->*/}
                                            <table class="table align-middle table-row-dashed gy-5"
                                                id="kt_table_customers_payment">
                                                <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                                                    <tr class="text-start text-muted text-uppercase gs-0">
                                                        <th class="text-center">Listing Id.</th>
                                                        <th class="text-center">Status</th>
                                                        <th class="text-center">Amount</th>
                                                        <th class="text-center">Seller Id</th>
                                                        <th class="text-center">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="fs-6 fw-semibold text-gray-600">
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#14688</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$1,200.00</td>
                                                        <td>120</td>
                                                        <td>14 Dec 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#15075</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$79.00</td>
                                                        <td>7</td>
                                                        <td>01 Dec 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#14623</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$5,500.00</td>
                                                        <td>550</td>
                                                        <td>12 Nov 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#15136</a>
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-light-warning">Pending</span>
                                                        </td>
                                                        <td>$880.00</td>
                                                        <td>88</td>
                                                        <td>21 Oct 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#15458</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$7,650.00</td>
                                                        <td>765</td>
                                                        <td>19 Oct 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#14523</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$375.00</td>
                                                        <td>37</td>
                                                        <td>23 Sep 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#14570</a>
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="badge badge-light-success">Successful</span>
                                                        </td>
                                                        <td>$129.00</td>
                                                        <td>12</td>
                                                        <td>11 Sep 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#15163</a>
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-light-danger">Rejected</span>
                                                        </td>
                                                        <td>$450.00</td>
                                                        <td>45</td>
                                                        <td>03 Sep 2020</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a href="apps/ecommerce/sales/details.html"
                                                                class="text-gray-600 text-hover-primary mb-1">#14654</a>
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-light-warning">Pending</span>
                                                        </td>
                                                        <td>$8,700.00</td>
                                                        <td>870</td>
                                                        <td>01 Sep 2020</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/*<!--end::Table-->*/}
                                        </div>
                                        {/*<!--end::Card body-->*/}
                                    </div>
                                    {/*<!--end::Card-->*/}
                                </div>
                                {/*<!--end:::Tab pane-->*/}
                                {/*<!--begin:::Tab pane-->*/}
                                <div class="tab-pane fade" id="kt_ecommerce_customer_general" role="tabpanel">
                                  
                                    {/*<!--begin::Card-->*/}
                                    <div className="card pt-4 mb-6 mb-xl-9">
                                        {/*<!--begin::Card header-->*/}
                                        <div className="card-header border-0">
                                            {/*<!--begin::Card title-->*/}
                                            <div className="card-title flex-column">
                                                <h2>Email Notifications</h2>
                                                <div className="fs-6 fw-semibold text-muted">Choose what messages you’d
                                                    like to receive for each of your
                                                    accounts.</div>
                                            </div>
                                            {/*<!--end::Card title-->*/}
                                        </div>
                                        {/*<!--end::Card header-->*/}
                                        {/*<!--begin::Card body-->*/}
                                        <div className="card-body">
                                            {/*<!--begin::Form-->*/}
                                            <form className="form" id="kt_users_email_notification_form">
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_0" type="checkbox" value="0"
                                                            id="kt_modal_update_email_notification_0"
                                                            checked='checked' />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_0">
                                                            <div className="fw-bold">Successful Payments</div>
                                                            <div className="text-gray-600">Receive a notification for
                                                                every successful payment.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_1" type="checkbox" value="1"
                                                            id="kt_modal_update_email_notification_1" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_1">
                                                            <div className="fw-bold">Ad Listing</div>
                                                            <div className="text-gray-600">Receive a notification for
                                                                new Ads.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_2" type="checkbox" value="2"
                                                            id="kt_modal_update_email_notification_2" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_2">
                                                            <div className="fw-bold">Ad Interaction</div>
                                                            <div className="text-gray-600">Receive a notification each
                                                                when a seller responds to an inquiry.
                                                            </div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_3" type="checkbox" value="3"
                                                            id="kt_modal_update_email_notification_3"
                                                            checked='checked' />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_3">
                                                            <div className="fw-bold">Disputes</div>
                                                            <div className="text-gray-600">Receive a notification if a
                                                                payment is disputed by a customer and for
                                                                dispute resolutions.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_4" type="checkbox" value="4"
                                                            id="kt_modal_update_email_notification_4"
                                                            checked='checked' />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_4">
                                                            <div className="fw-bold">Payment reviews</div>
                                                            <div className="text-gray-600">Receive a notification if a
                                                                payment is marked as an elevated risk.
                                                            </div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_5" type="checkbox" value="5"
                                                            id="kt_modal_update_email_notification_5" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_5">
                                                            <div className="fw-bold">Mentions</div>
                                                            <div className="text-gray-600">Receive a notification if a
                                                                teammate mentions you in a note.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_6" type="checkbox" value="6"
                                                            id="kt_modal_update_email_notification_6" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_6">
                                                            <div className="fw-bold">Invoice Mispayments</div>
                                                            <div className="text-gray-600">Receive a notification if a
                                                                customer sends an incorrect amount to pay
                                                                their invoice.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                <div className='separator separator-dashed my-5'></div>
                                                {/*<!--begin::Item-->*/}
                                                <div className="d-flex text-start">
                                                    {/*<!--begin::Checkbox-->*/}
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-check-input me-3"
                                                            name="email_notification_8" type="checkbox" value="8"
                                                            id="kt_modal_update_email_notification_8" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-check-label"
                                                            for="kt_modal_update_email_notification_8">
                                                            <div className="fw-bold">Trial</div>
                                                            <div className="text-gray-600">Receive helpful tips when you
                                                                try out our products.</div>
                                                        </label>
                                                        {/*<!--end::Label-->*/}
                                                    </div>
                                                    {/*<!--end::Checkbox-->*/}
                                                </div>
                                                {/*<!--end::Item-->*/}
                                                {/*<!--begin::Action buttons-->*/}
                                                <div className="d-flex justify-content-end align-items-center mt-12">
                                                    {/*<!--begin::Button-->*/}
                                                    <button type="button" className="btn btn-light me-5"
                                                        id="kt_users_email_notification_cancel">Cancel</button>
                                                    {/*<!--end::Button-->*/}
                                                    {/*<!--begin::Button-->*/}
                                                    <button type="button" className="btn btn-primary"
                                                        id="kt_users_email_notification_submit">
                                                        <span className="indicator-label">Save</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                    {/*<!--end::Button-->*/}
                                                </div>
                                                {/*<!--begin::Action buttons-->*/}
                                            </form>
                                            {/*<!--end::Form-->*/}
                                        </div>
                                        {/*<!--end::Card body-->*/}
                                        {/*<!--begin::Card footer-->*/}
                                        {/*<!--end::Card footer-->*/}
                                    </div>
                                    {/*<!--end::Card-->*/}
                                 
                                </div>
                                {/*<!--end:::Tab pane-->*/}
                            
                            </div>
                            {/*<!--end:::Tab content-->*/}
                        </div>
                        {/*<!--end::Content-->*/}
                    </div>``
                    {/*<!--end::Layout-->*/}
                    {/*<!--begin::Modals-->*/}
                    {/*<!--begin::Modal - New Address-->*/}
                    <div class="modal fade" id="kt_modal_update_address" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Form-->*/}
                                <form class="form" action="#" id="kt_modal_update_address_form">
                                    {/*<!--begin::Modal header-->*/}
                                    <div class="modal-header" id="kt_modal_update_address_header">
                                        {/*<!--begin::Modal title-->*/}
                                        <h2 class="fw-bold">Update Address</h2>
                                        {/*<!--end::Modal title-->*/}
                                        {/*<!--begin::Close-->*/}
                                        <div id="kt_modal_update_address_close"
                                            class="btn btn-icon btn-sm btn-active-icon-primary">
                                            <i class="ki-duotone ki-cross fs-1">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                        </div>
                                        {/*<!--end::Close-->*/}
                                    </div>
                                    {/*<!--end::Modal header-->*/}
                                    {/*<!--begin::Modal body-->*/}
                                    <div class="modal-body py-10 px-lg-17">
                                        {/*<!--begin::Scroll-->*/}
                                        <div class="d-flex flex-column scroll-y me-n7 pe-7"
                                            id="kt_modal_update_address_scroll" data-kt-scroll="true"
                                            data-kt-scroll-activate="{default: false, lg: true}"
                                            data-kt-scroll-max-height="auto"
                                            data-kt-scroll-dependencies="#kt_modal_update_address_header"
                                            data-kt-scroll-wrappers="#kt_modal_update_address_scroll"
                                            data-kt-scroll-offset="300px">
                                            {/*<!--begin::Billing toggle-->*/}
                                            <div class="fw-bold fs-3 rotate collapsible collapsed mb-7"
                                                data-bs-toggle="collapse"
                                                href="#kt_modal_update_address_billing_info" role="button"
                                                aria-expanded="false"
                                                aria-controls="kt_modal_update_address_billing_info">Shipping
                                                Information
                                                <span class="ms-2 rotate-180">
                                                    <i class="ki-duotone ki-down fs-3"></i>
                                                </span>
                                            </div>
                                            {/*<!--end::Billing toggle-->*/}
                                            {/*<!--begin::Billing form-->*/}
                                            <div id="kt_modal_update_address_billing_info" class="collapse show">
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2 required">Address Line
                                                        1</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="address1" value="101, Collins Street" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2">Address Line 2</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="address2" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2 required">City /
                                                        Town</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="city" value="Melbourne" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row g-9 mb-7">
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label class="fs-6 fw-semibold mb-2 required">State /
                                                            Province</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input class="form-control form-control-solid"
                                                            placeholder="" name="state" value="Victoria" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label class="fs-6 fw-semibold mb-2 required">Post
                                                            Code</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input class="form-control form-control-solid"
                                                            placeholder="" name="postcode" value="3000" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2">
                                                        <span class="required">Country</span>
                                                        <span class="ms-1" data-bs-toggle="tooltip"
                                                            title="Country of origination">
                                                            <i
                                                                class="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                <span class="path1"></span>
                                                                <span class="path2"></span>
                                                                <span class="path3"></span>
                                                            </i>
                                                        </span>
                                                    </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <select name="country" aria-label="Select a Country"
                                                        data-control="select2"
                                                        data-placeholder="Select a Country..."
                                                        data-dropdown-parent="#kt_modal_update_address"
                                                        class="form-select form-select-solid fw-bold">
                                                        <option value="">Select a Country...</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Aland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU" selected="selected">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia, Plurinational State of</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">British Indian Ocean Territory</option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoros</option>
                                                        <option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Côte d'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curaçao</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">Korea, Democratic People's Republic of
                                                        </option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesia, Federated States of</option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthélemy</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin (French part)</option>
                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="KR">South Korea</option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US">United States</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela, Bolivarian Republic of
                                                        </option>
                                                        <option value="VN">Vietnam</option>
                                                        <option value="VI">Virgin Islands</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="fv-row mb-7">
                                                    {/*<!--begin::Wrapper-->*/}
                                                    <div class="d-flex flex-stack">
                                                        {/*<!--begin::Label-->*/}
                                                        <div class="me-5">
                                                            {/*<!--begin::Label-->*/}
                                                            <label class="fs-6 fw-semibold">Use as a billing
                                                                address?</label>
                                                            {/*<!--end::Label-->*/}
                                                            {/*<!--begin::Input-->*/}
                                                            <div class="fs-7 fw-semibold text-muted">If you need
                                                                more info, please check budget planning</div>
                                                            {/*<!--end::Input-->*/}
                                                        </div>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Switch-->*/}
                                                        <label
                                                            class="form-check form-switch form-check-custom form-check-solid">
                                                            {/*<!--begin::Input-->*/}
                                                            <input class="form-check-input" name="billing"
                                                                type="checkbox" value="1"
                                                                id="kt_modal_update_address_billing"
                                                                checked="checked" />
                                                            {/*<!--end::Input-->*/}
                                                            {/*<!--begin::Label-->*/}
                                                            <span class="form-check-label fw-semibold text-muted"
                                                                for="kt_modal_update_address_billing">Yes</span>
                                                            {/*<!--end::Label-->*/}
                                                        </label>
                                                        {/*<!--end::Switch-->*/}
                                                    </div>
                                                    {/*<!--begin::Wrapper-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Billing form-->*/}
                                        </div>
                                        {/*<!--end::Scroll-->*/}
                                    </div>
                                    {/*<!--end::Modal body-->*/}
                                    {/*<!--begin::Modal footer-->*/}
                                    <div class="modal-footer flex-center">
                                        {/*<!--begin::Button-->*/}
                                        <button type="reset" id="kt_modal_update_address_cancel"
                                            class="btn btn-light me-3">Discard</button>
                                        {/*<!--end::Button-->*/}
                                        {/*<!--begin::Button-->*/}
                                        <button type="submit" id="kt_modal_update_address_submit"
                                            class="btn btn-primary">
                                            <span class="indicator-label">Submit</span>
                                            <span class="indicator-progress">Please wait...
                                                <span
                                                    class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                        {/*<!--end::Button-->*/}
                                    </div>
                                    {/*<!--end::Modal footer-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                        </div>
                    </div>
                    {/*<!--end::Modal - New Address-->*/}
                    {/*<!--begin::Modal - Update password-->*/}
                    <div class="modal fade" id="kt_modal_update_password" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div class="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 class="fw-bold">Update Password</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-kt-users-modal-action="close">
                                        <i class="ki-duotone ki-cross fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Form-->*/}
                                    <form id="kt_modal_update_password_form" class="form" action="#">
                                        {/*<!--begin::Input group=-->*/}
                                        <div class="fv-row mb-10">
                                            <label class="required form-label fs-6 mb-2">Current Password</label>
                                            <input class="form-control form-control-lg form-control-solid"
                                                type="password" placeholder="" name="current_password"
                                                autocomplete="off" />
                                        </div>
                                        {/*<!--end::Input group=-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div class="mb-10 fv-row" data-kt-password-meter="true">
                                            {/*<!--begin::Wrapper-->*/}
                                            <div class="mb-1">
                                                {/*<!--begin::Label-->*/}
                                                <label class="form-label fw-semibold fs-6 mb-2">New Password</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input wrapper-->*/}
                                                <div class="position-relative mb-3">
                                                    <input class="form-control form-control-lg form-control-solid"
                                                        type="password" placeholder="" name="new_password"
                                                        autocomplete="off" />
                                                    <span
                                                        class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                                        data-kt-password-meter-control="visibility">
                                                        <i class="ki-duotone ki-eye-slash fs-1">
                                                            <span class="path1"></span>
                                                            <span class="path2"></span>
                                                            <span class="path3"></span>
                                                            <span class="path4"></span>
                                                        </i>
                                                        <i class="ki-duotone ki-eye d-none fs-1">
                                                            <span class="path1"></span>
                                                            <span class="path2"></span>
                                                            <span class="path3"></span>
                                                        </i>
                                                    </span>
                                                </div>
                                                {/*<!--end::Input wrapper-->*/}
                                                {/*<!--begin::Meter-->*/}
                                                <div class="d-flex align-items-center mb-3"
                                                    data-kt-password-meter-control="highlight">
                                                    <div
                                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2">
                                                    </div>
                                                    <div
                                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2">
                                                    </div>
                                                    <div
                                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2">
                                                    </div>
                                                    <div
                                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px">
                                                    </div>
                                                </div>
                                                {/*<!--end::Meter-->*/}
                                            </div>
                                            {/*<!--end::Wrapper-->*/}
                                            {/*<!--begin::Hint-->*/}
                                            <div class="text-muted">Use 8 or more characters with a mix of letters,
                                                numbers & symbols.</div>
                                            {/*<!--end::Hint-->*/}
                                        </div>
                                        {/*<!--end::Input group=-->*/}
                                        {/*<!--begin::Input group=-->*/}
                                        <div class="fv-row mb-10">
                                            <label class="form-label fw-semibold fs-6 mb-2">Confirm New
                                                Password</label>
                                            <input class="form-control form-control-lg form-control-solid"
                                                type="password" placeholder="" name="confirm_password"
                                                autocomplete="off" />
                                        </div>
                                        {/*<!--end::Input group=-->*/}
                                        {/*<!--begin::Actions-->*/}
                                        <div class="text-center pt-15">
                                            <button type="reset" class="btn btn-light me-3"
                                                data-kt-users-modal-action="cancel">Discard</button>
                                            <button type="submit" class="btn btn-primary"
                                                data-kt-users-modal-action="submit">
                                                <span class="indicator-label">Submit</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>
                                        {/*<!--end::Actions-->*/}
                                    </form>
                                    {/*<!--end::Form-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - Update password-->*/}
                    {/*<!--begin::Modal - Update email-->*/}
                    <div class="modal fade" id="kt_modal_update_phone" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div class="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 class="fw-bold">Update Phone Number</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-kt-users-modal-action="close">
                                        <i class="ki-duotone ki-cross fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Form-->*/}
                                    <form id="kt_modal_update_phone_form" class="form" action="#">
                                        {/*<!--begin::Notice-->*/}
                                        {/*<!--begin::Notice-->*/}
                                        <div
                                            class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                            {/*<!--begin::Icon-->*/}
                                            <i class="ki-duotone ki-information fs-2tx text-primary me-4">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                                <span class="path3"></span>
                                            </i>
                                            {/*<!--end::Icon-->*/}
                                            {/*<!--begin::Wrapper-->*/}
                                            <div class="d-flex flex-stack flex-grow-1">
                                                {/*<!--begin::Content-->*/}
                                                <div class="fw-semibold">
                                                    <div class="fs-6 text-gray-700">Please note that a valid phone
                                                        number may be required for order or shipping rescheduling.
                                                    </div>
                                                </div>
                                                {/*<!--end::Content-->*/}
                                            </div>
                                            {/*<!--end::Wrapper-->*/}
                                        </div>
                                        {/*<!--end::Notice-->*/}
                                        {/*<!--end::Notice-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div class="fv-row mb-7">
                                            {/*<!--begin::Label-->*/}
                                            <label class="fs-6 fw-semibold form-label mb-2">
                                                <span class="required">Phone</span>
                                            </label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input class="form-control form-control-solid" placeholder=""
                                                name="profile_phone" value="+6141 234 567" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Actions-->*/}
                                        <div class="text-center pt-15">
                                            <button type="reset" class="btn btn-light me-3"
                                                data-kt-users-modal-action="cancel">Discard</button>
                                            <button type="submit" class="btn btn-primary"
                                                data-kt-users-modal-action="submit">
                                                <span class="indicator-label">Submit</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>
                                        {/*<!--end::Actions-->*/}
                                    </form>
                                    {/*<!--end::Form-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - Update email-->*/}
                    {/*<!--begin::Modal - New Address-->*/}
                    <div class="modal fade" id="kt_modal_add_address" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Form-->*/}
                                <form class="form" action="#" id="kt_modal_add_address_form">
                                    {/*<!--begin::Modal header-->*/}
                                    <div class="modal-header" id="kt_modal_add_address_header">
                                        {/*<!--begin::Modal title-->*/}
                                        <h2 class="fw-bold">Add New Address</h2>
                                        {/*<!--end::Modal title-->*/}
                                        {/*<!--begin::Close-->*/}
                                        <div id="kt_modal_add_address_close"
                                            class="btn btn-icon btn-sm btn-active-icon-primary">
                                            <i class="ki-duotone ki-cross fs-1">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                        </div>
                                        {/*<!--end::Close-->*/}
                                    </div>
                                    {/*<!--end::Modal header-->*/}
                                    {/*<!--begin::Modal body-->*/}
                                    <div class="modal-body py-10 px-lg-17">
                                        {/*<!--begin::Scroll-->*/}
                                        <div class="d-flex flex-column scroll-y me-n7 pe-7"
                                            id="kt_modal_add_address_scroll" data-kt-scroll="true"
                                            data-kt-scroll-activate="{default: false, lg: true}"
                                            data-kt-scroll-max-height="auto"
                                            data-kt-scroll-dependencies="#kt_modal_add_address_header"
                                            data-kt-scroll-wrappers="#kt_modal_add_address_scroll"
                                            data-kt-scroll-offset="300px">
                                            {/*<!--begin::Billing toggle-->*/}
                                            <div class="fw-bold fs-3 rotate collapsible collapsed mb-7"
                                                data-bs-toggle="collapse" href="#kt_modal_add_address_billing_info"
                                                role="button" aria-expanded="false"
                                                aria-controls="kt_modal_add_address_billing_info">Shipping
                                                Information
                                                <span class="ms-2 rotate-180">
                                                    <i class="ki-duotone ki-down fs-3"></i>
                                                </span>
                                            </div>
                                            {/*<!--end::Billing toggle-->*/}
                                            {/*<!--begin::Billing form-->*/}
                                            <div id="kt_modal_add_address_billing_info" class="collapse show">
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2 required">Address
                                                        Name</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="name" value="" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2 required">Address Line
                                                        1</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="address1" value="" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2">Address Line 2</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="address2" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2 required">City /
                                                        Town</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input class="form-control form-control-solid" placeholder=""
                                                        name="city" value="" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="row g-9 mb-7">
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label class="fs-6 fw-semibold mb-2 required">State /
                                                            Province</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input class="form-control form-control-solid"
                                                            placeholder="" name="state" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div class="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label class="fs-6 fw-semibold mb-2 required">Post
                                                            Code</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input class="form-control form-control-solid"
                                                            placeholder="" name="postcode" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label class="fs-6 fw-semibold mb-2">
                                                        <span class="required">Country</span>
                                                        <span class="ms-1" data-bs-toggle="tooltip"
                                                            title="Country of origination">
                                                            <i class="ki-duotone ki-information fs-7">
                                                                <span class="path1"></span>
                                                                <span class="path2"></span>
                                                                <span class="path3"></span>
                                                            </i>
                                                        </span>
                                                    </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <select name="country" aria-label="Select a Country"
                                                        data-control="select2"
                                                        data-placeholder="Select a Country..."
                                                        data-dropdown-parent="#kt_modal_add_address"
                                                        class="form-select form-select-solid fw-bold">
                                                        <option value="">Select a Country...</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Aland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia, Plurinational State of</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">British Indian Ocean Territory</option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoros</option>
                                                        <option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Côte d'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curaçao</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">Korea, Democratic People's Republic of
                                                        </option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesia, Federated States of</option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthélemy</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin (French part)</option>
                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="KR">South Korea</option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US">United States</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela, Bolivarian Republic of
                                                        </option>
                                                        <option value="VN">Vietnam</option>
                                                        <option value="VI">Virgin Islands</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div class="fv-row mb-7">
                                                    {/*<!--begin::Wrapper-->*/}
                                                    <div class="d-flex flex-stack">
                                                        {/*<!--begin::Label-->*/}
                                                        <div class="me-5">
                                                            {/*<!--begin::Label-->*/}
                                                            <label class="fs-6 fw-semibold">Use as a billing
                                                                address?</label>
                                                            {/*<!--end::Label-->*/}
                                                            {/*<!--begin::Input-->*/}
                                                            <div class="fs-7 fw-semibold text-muted">If you need
                                                                more info, please check budget planning</div>
                                                            {/*<!--end::Input-->*/}
                                                        </div>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Switch-->*/}
                                                        <label
                                                            class="form-check form-switch form-check-custom form-check-solid">
                                                            {/*<!--begin::Input-->*/}
                                                            <input class="form-check-input" name="billing"
                                                                type="checkbox" value="1"
                                                                id="kt_modal_add_address_billing"
                                                                checked="checked" />
                                                            {/*<!--end::Input-->*/}
                                                            {/*<!--begin::Label-->*/}
                                                            <span class="form-check-label fw-semibold text-muted"
                                                                for="kt_modal_add_address_billing">Yes</span>
                                                            {/*<!--end::Label-->*/}
                                                        </label>
                                                        {/*<!--end::Switch-->*/}
                                                    </div>
                                                    {/*<!--begin::Wrapper-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Billing form-->*/}
                                        </div>
                                        {/*<!--end::Scroll-->*/}
                                    </div>
                                    {/*<!--end::Modal body-->*/}
                                    {/*<!--begin::Modal footer-->*/}
                                    <div class="modal-footer flex-center">
                                        {/*<!--begin::Button-->*/}
                                        <button type="reset" id="kt_modal_add_address_cancel"
                                            class="btn btn-light me-3">Discard</button>
                                        {/*<!--end::Button-->*/}
                                        {/*<!--begin::Button-->*/}
                                        <button type="submit" id="kt_modal_add_address_submit"
                                            class="btn btn-primary">
                                            <span class="indicator-label">Submit</span>
                                            <span class="indicator-progress">Please wait...
                                                <span
                                                    class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                        {/*<!--end::Button-->*/}
                                    </div>
                                    {/*<!--end::Modal footer-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                        </div>
                    </div>
                    {/*<!--end::Modal - New Address-->*/}
                    {/*<!--begin::Modal - Add task-->*/}
                    <div class="modal fade" id="kt_modal_add_auth_app" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div class="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 class="fw-bold">Add Authenticator App</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-kt-users-modal-action="close">
                                        <i class="ki-duotone ki-cross fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Content-->*/}
                                    <div class="fw-bold d-flex flex-column justify-content-center mb-5">
                                        {/*<!--begin::Label-->*/}
                                        <div class="text-center mb-5" data-kt-add-auth-action="qr-code-label">
                                            Download the
                                            <a href="#">Authenticator app</a>, add a new account, then scan this
                                            barcode to set up your account.
                                        </div>
                                        <div class="text-center mb-5 d-none"
                                            data-kt-add-auth-action="text-code-label">Download the
                                            <a href="#">Authenticator app</a>, add a new account, then enter this
                                            code to set up your account.
                                        </div>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::QR code-->*/}
                                        <div class="d-flex flex-center" data-kt-add-auth-action="qr-code">
                                            <img src="../../assets/media/misc/qr.png" alt="Scan this QR code" />
                                        </div>
                                        {/*<!--end::QR code-->*/}
                                        {/*<!--begin::Text code-->*/}
                                        <div class="border rounded p-5 d-flex flex-center d-none"
                                            data-kt-add-auth-action="text-code">
                                            <div class="fs-1">gi2kdnb54is709j</div>
                                        </div>
                                        {/*<!--end::Text code-->*/}
                                    </div>
                                    {/*<!--end::Content-->*/}
                                    {/*<!--begin::Action-->*/}
                                    <div class="d-flex flex-center">
                                        <div class="btn btn-light-primary"
                                            data-kt-add-auth-action="text-code-button">Enter code manually</div>
                                        <div class="btn btn-light-primary d-none"
                                            data-kt-add-auth-action="qr-code-button">Scan barcode instead</div>
                                    </div>
                                    {/*<!--end::Action-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - Add task-->*/}
                    {/*<!--begin::Modal - Add task-->*/}
                    <div class="modal fade" id="kt_modal_add_one_time_password" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div class="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div class="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div class="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 class="fw-bold">Enable One Time Password</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-kt-users-modal-action="close">
                                        <i class="ki-duotone ki-cross fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Form-->*/}
                                    <form class="form" id="kt_modal_add_one_time_password_form">
                                        {/*<!--begin::Label-->*/}
                                        <div class="fw-bold mb-9">Enter the new phone number to receive an SMS to
                                            when you log in.</div>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div class="fv-row mb-7">
                                            {/*<!--begin::Label-->*/}
                                            <label class="fs-6 fw-semibold form-label mb-2">
                                                <span class="required">Mobile number</span>
                                                <span class="ms-1" data-bs-toggle="tooltip"
                                                    title="A valid mobile number is required to receive the one-time password to validate your account login.">
                                                    <i class="ki-duotone ki-information fs-7">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                        <span class="path3"></span>
                                                    </i>
                                                </span>
                                            </label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input type="text" class="form-control form-control-solid"
                                                name="otp_mobile_number" placeholder="+6123 456 789" value="" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Separator-->*/}
                                        <div class="separator saperator-dashed my-5"></div>
                                        {/*<!--end::Separator-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div class="fv-row mb-7">
                                            {/*<!--begin::Label-->*/}
                                            <label class="fs-6 fw-semibold form-label mb-2">
                                                <span class="required">Email</span>
                                            </label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input type="email" class="form-control form-control-solid"
                                                name="otp_email" value="smith@kpmg.com" readonly="readonly" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div class="fv-row mb-7">
                                            {/*<!--begin::Label-->*/}
                                            <label class="fs-6 fw-semibold form-label mb-2">
                                                <span class="required">Confirm password</span>
                                            </label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input type="password" class="form-control form-control-solid"
                                                name="otp_confirm_password" value="" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Actions-->*/}
                                        <div class="text-center pt-15">
                                            <button type="reset" class="btn btn-light me-3"
                                                data-kt-users-modal-action="cancel">Cancel</button>
                                            <button type="submit" class="btn btn-primary"
                                                data-kt-users-modal-action="submit">
                                                <span class="indicator-label">Submit</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>
                                        {/*<!--end::Actions-->*/}
                                    </form>
                                    {/*<!--end::Form-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - Add task-->*/}
                    {/*<!--end::Modals-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
        </Base>
    )
}

export default BuyerDetails