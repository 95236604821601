import React from 'react'
import Base from '../../Components/Base/Base'
import backgroundSvg from '../../custom assets/svg/blank-image.svg' // Adjust the path to your SVG file
import { Select, Space } from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Link } from 'react-router-dom';
const { Dragger } = Upload;


const props = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',//API LINK 
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
const NewAd = () => {
    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <Link to="/" className="text-gray-600 text-hover-primary">
                                    <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </Link>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Seller</li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Seller Ads</li>
                            {/*<!--end::Item-->*/}

                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-500">New Ad</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Product Form</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}

                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Form-->*/}
                    <form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row"
                        data-kt-redirect="apps/ecommerce/catalog/products.html">
                        {/*<!--begin::Aside column-->*/}
                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                            {/*<!--begin::Thumbnail settings-->*/}
                            <div className="card card-flush py-4">
                                {/*<!--begin::Card header-->*/}
                                <div className="card-header">
                                    {/*<!--begin::Card title-->*/}
                                    <div className="card-title">
                                        <h2>Thumbnail</h2>
                                    </div>
                                    {/*<!--end::Card title-->*/}
                                </div>
                                {/*<!--end::Card header-->*/}
                                {/*<!--begin::Card body-->*/}
                                <div className="card-body text-center pt-0">
                                    {/*<!--begin::Image input-->*/}
                                    {/*<!--begin::Image input placeholder-->*/}

                                    {/*<!--end::Image input placeholder-->*/}
                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                                        data-kt-image-input="true" style={{
                                            backgroundImage: `url(${backgroundSvg})`,
                                            backgroundSize: 'cover', // Ensures the background image covers the entire div
                                            backgroundPosition: 'center', // Centers the background image
                                        }}>
                                        {/*<!--begin::Preview existing avatar-->*/}
                                        <div className="image-input-wrapper w-150px h-150px"></div>
                                        {/*<!--end::Preview existing avatar-->*/}
                                        {/*<!--begin::Label-->*/}
                                        <label
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                            title="Change avatar">
                                            <i className="ki-duotone ki-pencil fs-7">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                            {/*<!--begin::Inputs-->*/}
                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                            <input type="hidden" name="avatar_remove" />
                                            {/*<!--end::Inputs-->*/}
                                        </label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Cancel-->*/}
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                            title="Cancel avatar">
                                            <i className="ki-duotone ki-cross fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                        </span>
                                        {/*<!--end::Cancel-->*/}
                                        {/*<!--begin::Remove-->*/}
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="remove" data-bs-toggle="tooltip"
                                            title="Remove avatar">
                                            <i className="ki-duotone ki-cross fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                        </span>
                                        {/*<!--end::Remove-->*/}
                                    </div>
                                    {/*<!--end::Image input-->*/}
                                    {/*<!--begin::Description-->*/}
                                    <div className="text-muted fs-7">Set the product thumbnail image. Only *.png, *.jpg
                                        and *.jpeg image files are accepted</div>
                                    {/*<!--end::Description-->*/}
                                </div>
                                {/*<!--end::Card body-->*/}
                            </div>
                            {/*<!--end::Thumbnail settings-->*/}

                        </div>
                        {/*<!--end::Aside column-->*/}
                        {/*<!--begin::Main column-->*/}
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            {/*<!--begin:::Tabs-->*/}
                            <ul
                                className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                {/*<!--begin:::Tab item-->*/}
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                        href="#kt_ecommerce_add_product_general">General</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                                {/*<!--begin:::Tab item-->*/}
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                        href="#kt_ecommerce_add_product_advanced">Additional Details</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                            </ul>
                            {/*<!--end:::Tabs-->*/}
                            {/*<!--begin::Tab content-->*/}
                            <div className="tab-content">
                                {/*<!--begin::Tab pane-->*/}
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general"
                                    role="tab-panel">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        {/*<!--begin::General options-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="mb-5 fv-row col-5  text-start">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Ad Title</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="product_name"
                                                            className="form-control mb-2" placeholder="Title"
                                                            value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">An Ad Title is required and
                                                            recommended to be unique.</div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    {/*<!-- begin::category select -->*/}
                                                    <div className="mb-5 fv-row col-3  text-start">
                                                        {/*<!--begin::Label-->*/}

                                                        <label className="form-label d-block">Categories</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        {/* <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select a Category">
                                                            <option></option>
                                                            <option value="RealEstate">Real Estate</option>
                                                            <option value="Education">Education</option>
                                                            <option value="Jobs">Jobs</option>
                                                            <option value="BusinessDirectory">Business Directory
                                                            </option>
                                                            <option value="Bikes">Bikes</option>
                                                            <option value="Cars">Cars</option>
                                                            <option value="Furniture">Furniture</option>
                                                            <option value="Appliances">Appliances</option>
                                                            <option value="Tours">Tours & Travels</option>
                                                            <option value="Cleaning">Cleaning</option>
                                                            <option value="Wellness">Wellness</option>
                                                            <option value="Beauty">Beauty</option>
                                                            <option value="HomeServices">Home Services</option>
                                                            <option value="Movers &Packers">Movers &Packers</option>
                                                            <option value="Security">Security</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Rental">Rental</option>
                                                            <option value="Repairs">Repairs</option>
                                                            <option value="Plumbing">Plumbing</option>
                                                            <option value="Healthcare">Healthcare</option>
                                                            <option value="Commercial">Commercial</option>
                                                        </select> */}
                                                        <Select className='rounded bg-danger'
                                                            style={{
                                                                width: "100%",
                                                                height: "45px"
                                                            }}
                                                            showSearch
                                                            placeholder={<b>Select Category</b>}
                                                            options={[
                                                                { value: "RealEstate", label: "Real Estate" },
                                                                { value: "Education", label: "Education" },
                                                                { value: "Jobs", label: "Jobs" },
                                                                { value: "BusinessDirectory", label: "Business Directory" },
                                                                { value: "Bikes", label: "Bikes" },
                                                                { value: "Cars", label: "Cars" },
                                                                { value: "Furniture", label: "Furniture" },
                                                                { value: "Appliances", label: "Appliances" },
                                                                { value: "Tours", label: "Tours & Travels" },
                                                                { value: "Cleaning", label: "Cleaning" },
                                                                { value: "Wellness", label: "Wellness" },
                                                                { value: "Beauty", label: "Beauty" },
                                                                { value: "HomeServices", label: "Home Services" },
                                                                { value: "Movers &Packers", label: "Movers & Packers" },
                                                                { value: "Security", label: "Security" },
                                                                { value: "Legal", label: "Legal" },
                                                                { value: "Rental", label: "Rental" },
                                                                { value: "Repairs", label: "Repairs" },
                                                                { value: "Plumbing", label: "Plumbing" },
                                                                { value: "Healthcare", label: "Healthcare" },
                                                                { value: "Commercial", label: "Commercial" }
                                                            ]}
                                                        />
                                                        {/*<!--end::Select2-->*/}
                                                    </div>
                                                    {/*<!-- end::category select -->*/}
                                                    {/*<!-- begin::SubCategory select -->*/}
                                                    <div className="mb-5 fv-row col-3 text-start">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label d-block">Subcategories</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        {/* <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select a Subcategory">
                                                            <option></option>
                                                            <option value="home">Home</option>
                                                            <option value="apartments">apartments</option>
                                                            <option value="lands">lands</option>
                                                            <option value="plots">plots
                                                            </option>
                                                            <option value="offices">offices</option>
                                                            <option value="shops">shops</option>
                                                            <option value="villas">villas</option>
                                                            <option value="commercial">commercial</option>
                                                            <option value="IT Courses">IT Courses</option>
                                                            <option value="Science">Science</option>
                                                            <option value="Health">Health</option>
                                                            <option value="Law">Law</option>
                                                            <option value="Nursing">Nursing</option>
                                                            <option value="Movers &Packers">Movers &Packers</option>
                                                            <option value="Business">Business</option>
                                                        </select> */}
                                                        <Select className='rounded bg-danger'
                                                            style={{
                                                                width: "100%",
                                                                height: "45px"
                                                            }}
                                                            showSearch
                                                            placeholder={<b>Select Subcategory</b>}
                                                            options={[
                                                                { value: "home", label: "Home" },
                                                                { value: "apartments", label: "Apartments" },
                                                                { value: "lands", label: "Lands" },
                                                                { value: "plots", label: "Plots" },
                                                                { value: "offices", label: "Offices" },
                                                                { value: "shops", label: "Shops" },
                                                                { value: "villas", label: "Villas" },
                                                                { value: "commercial", label: "Commercial" },
                                                                { value: "IT Courses", label: "IT Courses" },
                                                                { value: "Science", label: "Science" },
                                                                { value: "Health", label: "Health" },
                                                                { value: "Law", label: "Law" },
                                                                { value: "Nursing", label: "Nursing" },
                                                                { value: "Movers &Packers", label: "Movers & Packers" },
                                                            ]}
                                                        />
                                                        {/*<!--end::Select2-->*/}
                                                    </div>
                                                    {/*<!-- end::subCategory select -->*/}


                                                    {/*<!--end::Input group-->*/}
                                                </div>
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-5  text-start">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Description</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Editor-->*/}
                                                    <textarea
                                                        className="form-control form-control-lg form-control-solid"
                                                        name="campaign_name" placeholder="" value=""></textarea>
                                                    {/*<!--end::Editor-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set a description to the product
                                                        for better visibility.</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>

                                            </div>
                                            {/*<!--end::Card header-->*/}

                                        </div>
                                        {/*<!--begin::Pricing-->*/}
                                        <div className="card card-flush py-4  text-start">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Pricing</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="required form-label">Base Price</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input type="text" name="price" className="form-control mb-2"
                                                        placeholder="Product price" value="" />
                                                    {/*<!--end::Input-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set the product price.</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row mb-10">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="fs-6 fw-semibold mb-2">Discount Type
                                                        <span className="ms-1" data-bs-toggle="tooltip"
                                                            title="Select a discount type that will be applied to this product">
                                                            <i
                                                                className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span></label>
                                                    {/*<!--End::Label-->*/}
                                                    {/*<!--begin::Row-->*/}
                                                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9"
                                                        data-kt-buttons="true"
                                                        data-kt-buttons-target="[data-kt-button='true']">
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="1"
                                                                        checked="checked" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">No
                                                                        Discount</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="2" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">Percentage
                                                                        %</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="3" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">Fixed
                                                                        Price</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                    </div>
                                                    {/*<!--end::Row-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-none mb-10 fv-row"
                                                    id="kt_ecommerce_add_product_discount_percentage">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Set Discount Percentage</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Slider-->*/}
                                                    <div className="d-flex flex-column text-center mb-5">
                                                        <div
                                                            className="d-flex align-items-start justify-content-center mb-7">
                                                            <span className="fw-bold fs-3x"
                                                                id="kt_ecommerce_add_product_discount_label">0</span>
                                                            <span className="fw-bold fs-4 mt-1 ms-2">%</span>
                                                        </div>
                                                        <div id="kt_ecommerce_add_product_discount_slider"
                                                            className="noUi-sm"></div>
                                                    </div>
                                                    {/*<!--end::Slider-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set a percentage discount to be
                                                        applied on this product.</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-none mb-10 fv-row"
                                                    id="kt_ecommerce_add_product_discount_fixed">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Fixed Discounted Price</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input type="text" name="dicsounted_price"
                                                        className="form-control mb-2" placeholder="Discounted price" />
                                                    {/*<!--end::Input-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set the discounted product price.
                                                        The product will be reduced at the determined
                                                        fixed price</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Tax-->*/}
                                                <div className="d-flex flex-wrap gap-5">
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="fv-row w-100 flex-md-root">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Tax Class</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" name="tax"
                                                            data-control="select2" data-hide-search="true"
                                                            data-placeholder="Select an option">
                                                            <option></option>
                                                            <option value="0">Tax Free</option>
                                                            <option value="1">Taxable Goods</option>
                                                            <option value="2">Downloadable Product</option>
                                                        </select>
                                                        {/*<!--end::Select2-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Set the product tax class.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    {/*<!--end::Input group-->*/}
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="fv-row w-100 flex-md-root">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">VAT Amount (%)</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" className="form-control mb-2" value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Set the product VAT about.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    {/*<!--end::Input group-->*/}
                                                </div>
                                                {/*<!--end:Tax-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Pricing-->*/}
                                        {/*<!--end::General options-->*/}
                                    </div>
                                </div>
                                {/*<!--end::Tab pane-->*/}
                                {/*<!--begin::Tab pane-->*/}
                                <div className="tab-pane fade" id="kt_ecommerce_add_product_advanced" role="tab-panel">
                                    <div className="d-flex flex-column gap-7 gap-lg-10  text-start">
                                        {/*<!--begin::Media-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Media</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row mb-2">
                                                    {/* <div className="dropzone" id="kt_ecommerce_add_product_media">
                                                        <div className="dz-message needsclick">
                                                            <i className="ki-duotone ki-file-up text-primary fs-3x">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                            <div className="ms-4">
                                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop
                                                                    files here or click to upload.</h3>
                                                                <span className="fs-7 fw-semibold text-gray-500">Upload
                                                                    up to 10 files</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                        <p className="ant-upload-hint">
                                                            Support for a single or bulk upload.
                                                        </p>
                                                    </Dragger>
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Description-->*/}
                                                <div className="text-muted fs-7">Set the product media gallery.</div>
                                                {/*<!--end::Description-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Media-->*/}
                                        {/*<!--begin::Inventory-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Inventory</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0 row">
                                                {/*<!--begin::Input group-->*/}
                                                {/*<!-- begin::category select -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Brand</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Select2-->*/}
                                                    {/* <select className="form-select mb-2" data-control="select2"
                                                        data-placeholder="Select a Brand">
                                                        <option></option>
                                                        <option value="Harley-Davidson">Harley-Davidson</option>
                                                        <option value="Honda">Honda</option>
                                                        <option value="Yamaha">Yamaha</option>
                                                        <option value="Kawasaki">Kawasaki</option>
                                                        <option value="Toyota">Toyota</option>
                                                        <option value="Honda">Honda</option>
                                                        <option value="Ford">Ford</option>
                                                        <option value="BMW">BMW</option>
                                                        <option value="Mercedes-Benz">Mercedes-Benz</option>
                                                    </select> */}
                                                    <Select className='rounded bg-danger'
                                                        style={{
                                                            width: "100%",
                                                            height: "45px"
                                                        }}
                                                        showSearch
                                                        placeholder={<b>Select Brand</b>}
                                                        options={[
                                                            { value: "BMW", label: "BMW" },
                                                            { value: "Ford", label: "Ford" },
                                                            { value: "Harley-Davidson", label: "Harley-Davidson" },
                                                            { value: "Honda", label: "Honda" },
                                                            { value: "Kawasaki", label: "Kawasaki" },
                                                            { value: "Mercedes-Benz", label: "Mercedes-Benz" },
                                                            { value: "Toyota", label: "Toyota" },
                                                            { value: "Yamaha", label: "Yamaha" }
                                                        ]}
                                                    />
                                                    {/*<!--end::Select2-->*/}
                                                </div>
                                                {/*<!-- end::category select -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    <label className="form-label">For:</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Select2-->*/}
                                                    {/* <select className="form-select mb-2" data-control="select2"
                                                        data-placeholder="Select Ad for: ">
                                                        <option></option>
                                                        <option value="Sale" selected>Sale</option>
                                                        <option value="Rent">Rent</option>
                                                        <option value="Lease">Lease</option>

                                                    </select> */}
                                                    <Select className='rounded bg-danger'
                                                        style={{
                                                            width: "100%",
                                                            height: "45px"
                                                        }}
                                                        showSearch
                                                        defaultValue='Sale'
                                                        options={[
                                                            { value: "Sale", label: "Sale" },
                                                            { value: "Rent", label: "Rent" },
                                                            { value: "Lease", label: "Lease" },
                                                        ]}
                                                    />
                                                </div>
                                                {/*<!--end::Select2-->*/}
                                                {/*<!-- begiin::custom slider -->*/}
                                                {/*<!-- SCRIPT IS AT THE BOTTOM -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    <div className="mb-0">
                                                        <label className="form-label">Min Price</label>
                                                        {/* <div id="kt_slider_basic"></div>

                                                        <div className="pt-5 row">
                                                            <div className="fw-semibold mb-2 col-6 text-muted">Min:
                                                                <span id="kt_slider_basic_min"></span>
                                                            </div>
                                                            <div className="fw-semibold mb-2 col-6 text-muted">Max:
                                                                <span id="kt_slider_basic_max"></span>
                                                            </div>
                                                        </div> */}
                                                        {/* <PriceSlider></PriceSlider> */}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Price" value="" />
                                                    </div>
                                                </div>
                                                {/*<!-- end::custom slider -->*/}
                                                <div className='mb-5 fv-row col-3'>
                                                    <div className="mb-0">
                                                        <label className="form-label">Max Price</label>
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Price" value="" />
                                                    </div>
                                                </div>


                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row row">
                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Quantity</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Quantity" value="" />
                                                        {/*<!--end::Input-->*/}

                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Listing Type</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select Type">
                                                            <option></option>
                                                            <option value="New" selected>Job roles</option>
                                                            <option value="Looks New">Fuel Type</option>
                                                            <option value="Used">Education list</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Tenure</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Tenure" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Experience</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter your Experience in years" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>


                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Current Condition</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select condition">
                                                            <option></option>
                                                            <option value="New" selected>New</option>
                                                            <option value="Looks New">Looks New</option>
                                                            <option value="Used">Used</option>
                                                            <option value="Salvage">Salvage</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Style</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select condition">
                                                            <option></option>
                                                            <option value="Front" selected>Top Load</option>
                                                            <option value="Top">Front Load</option>
                                                            <option value="Single Door">Single Door</option>
                                                            <option value="Multi Doors">Multi Doors</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Measurement</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Measurements" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Serial Number</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Serial Number" value="" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        <label className="form-label">Mode of Delivery</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" data-control="select2"
                                                            data-placeholder="Select condition">
                                                            <option></option>
                                                            <option value="Online" selected>Online</option>
                                                            <option value="Offline">Offline</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row">
                                                    <div className="mb-5 fv-row col-4">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label d-block">Keywords</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        {/* <input id="kt_ecommerce_add_product_tags"
                                                            name="kt_ecommerce_add_product_tags"
                                                            className="form-control mb-2" value="" /> */}
                                                        <Select
                                                            mode="tags"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Tags Mode"
                                                            // onChange={handleChange}
                                                        />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Add keywords to your products
                                                            so they can be found in search results</div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Inventory-->*/}
                                        {/*<!--begin::Variations-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Address</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row row">
                                                    <div className="mb-5 fv-row col-3">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">City</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter City" value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Enter the city</div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    <div className="mb-5 fv-row col-4">
                                                        <label className="form-label">Country</label>
                                                        {/* <select className="form-select form-select" placeholder="..."
                                                            id="kt_docs_select2_country">
                                                            <option value="AF"
                                                                data-kt-select2-country="../../assets/media/flags/afghanistan.svg"
                                                                data-select2-id="select2-data-89-0sk9">Afghanistan
                                                            </option>
                                                            <option value="AX"
                                                                data-kt-select2-country="../../assets/media/flags/aland-islands.svg"
                                                                data-select2-id="select2-data-90-a4k5">Aland Islands
                                                            </option>
                                                            <option value="AL"
                                                                data-kt-select2-country="../../assets/media/flags/albania.svg"
                                                                data-select2-id="select2-data-91-bfq4">Albania
                                                            </option>
                                                            <option value="DZ"
                                                                data-kt-select2-country="../../assets/media/flags/algeria.svg"
                                                                data-select2-id="select2-data-92-bx82">Algeria
                                                            </option>
                                                            <option value="AS"
                                                                data-kt-select2-country="../../assets/media/flags/american-samoa.svg"
                                                                data-select2-id="select2-data-93-16g0">American
                                                                Samoa</option>
                                                            <option value="AD"
                                                                data-kt-select2-country="../../assets/media/flags/andorra.svg"
                                                                data-select2-id="select2-data-94-h0dn">Andorra
                                                            </option>
                                                            <option value="AO"
                                                                data-kt-select2-country="../../assets/media/flags/angola.svg"
                                                                data-select2-id="select2-data-95-jbmi">Angola
                                                            </option>
                                                            <option value="AI"
                                                                data-kt-select2-country="../../assets/media/flags/anguilla.svg"
                                                                data-select2-id="select2-data-96-kjuu">Anguilla
                                                            </option>
                                                            <option value="AG"
                                                                data-kt-select2-country="../../assets/media/flags/antigua-and-barbuda.svg"
                                                                data-select2-id="select2-data-97-0gmq">Antigua and
                                                                Barbuda</option>
                                                            <option value="AR"
                                                                data-kt-select2-country="../../assets/media/flags/argentina.svg"
                                                                data-select2-id="select2-data-98-3g6g">Argentina
                                                            </option>
                                                            <option value="AM"
                                                                data-kt-select2-country="../../assets/media/flags/armenia.svg"
                                                                data-select2-id="select2-data-99-kufq">Armenia
                                                            </option>
                                                            <option value="AW"
                                                                data-kt-select2-country="../../assets/media/flags/aruba.svg"
                                                                data-select2-id="select2-data-100-1315">Aruba
                                                            </option>
                                                            <option value="AU"
                                                                data-kt-select2-country="../../assets/media/flags/australia.svg"
                                                                data-select2-id="select2-data-101-abgc">Australia
                                                            </option>
                                                            <option value="AT"
                                                                data-kt-select2-country="../../assets/media/flags/austria.svg"
                                                                data-select2-id="select2-data-102-r53s">Austria
                                                            </option>
                                                            <option value="AZ"
                                                                data-kt-select2-country="../../assets/media/flags/azerbaijan.svg"
                                                                data-select2-id="select2-data-103-30o5">Azerbaijan
                                                            </option>
                                                            <option value="BS"
                                                                data-kt-select2-country="../../assets/media/flags/bahamas.svg"
                                                                data-select2-id="select2-data-104-dim6">Bahamas
                                                            </option>
                                                            <option value="BH"
                                                                data-kt-select2-country="../../assets/media/flags/bahrain.svg"
                                                                data-select2-id="select2-data-105-fxao">Bahrain
                                                            </option>
                                                            <option value="BD"
                                                                data-kt-select2-country="../../assets/media/flags/bangladesh.svg"
                                                                data-select2-id="select2-data-106-uvue">Bangladesh
                                                            </option>
                                                            <option value="BB"
                                                                data-kt-select2-country="../../assets/media/flags/barbados.svg"
                                                                data-select2-id="select2-data-107-hhzu">Barbados
                                                            </option>
                                                            <option value="BY"
                                                                data-kt-select2-country="../../assets/media/flags/belarus.svg"
                                                                data-select2-id="select2-data-108-2h2q">Belarus
                                                            </option>
                                                            <option value="BE"
                                                                data-kt-select2-country="../../assets/media/flags/belgium.svg"
                                                                data-select2-id="select2-data-109-mnmy">Belgium
                                                            </option>
                                                            <option value="BZ"
                                                                data-kt-select2-country="../../assets/media/flags/belize.svg"
                                                                data-select2-id="select2-data-110-z5fz">Belize
                                                            </option>
                                                            <option value="BJ"
                                                                data-kt-select2-country="../../assets/media/flags/benin.svg"
                                                                data-select2-id="select2-data-111-nwwp">Benin
                                                            </option>
                                                            <option value="BM"
                                                                data-kt-select2-country="../../assets/media/flags/bermuda.svg"
                                                                data-select2-id="select2-data-112-u0fx">Bermuda
                                                            </option>
                                                            <option value="BT"
                                                                data-kt-select2-country="../../assets/media/flags/bhutan.svg"
                                                                data-select2-id="select2-data-113-x5n4">Bhutan
                                                            </option>
                                                            <option value="BO"
                                                                data-kt-select2-country="../../assets/media/flags/bolivia.svg"
                                                                data-select2-id="select2-data-114-74gz">Bolivia,
                                                                Plurinational State of</option>
                                                            <option value="BQ"
                                                                data-kt-select2-country="../../assets/media/flags/bonaire.svg"
                                                                data-select2-id="select2-data-115-4shp">Bonaire,
                                                                Sint Eustatius and Saba</option>
                                                            <option value="BA"
                                                                data-kt-select2-country="../../assets/media/flags/bosnia-and-herzegovina.svg"
                                                                data-select2-id="select2-data-116-dexj">Bosnia and
                                                                Herzegovina</option>
                                                            <option value="BW"
                                                                data-kt-select2-country="../../assets/media/flags/botswana.svg"
                                                                data-select2-id="select2-data-117-3xq6">Botswana
                                                            </option>
                                                            <option value="BR"
                                                                data-kt-select2-country="../../assets/media/flags/brazil.svg"
                                                                data-select2-id="select2-data-118-nlht">Brazil
                                                            </option>
                                                            <option value="IO"
                                                                data-kt-select2-country="../../assets/media/flags/british-indian-ocean-territory.svg"
                                                                data-select2-id="select2-data-119-m01z">British
                                                                Indian Ocean Territory</option>
                                                            <option value="BN"
                                                                data-kt-select2-country="../../assets/media/flags/brunei.svg"
                                                                data-select2-id="select2-data-120-isbc">Brunei
                                                                Darussalam</option>
                                                            <option value="BG"
                                                                data-kt-select2-country="../../assets/media/flags/bulgaria.svg"
                                                                data-select2-id="select2-data-121-9qel">Bulgaria
                                                            </option>
                                                            <option value="BF"
                                                                data-kt-select2-country="../../assets/media/flags/burkina-faso.svg"
                                                                data-select2-id="select2-data-122-tqso">Burkina Faso
                                                            </option>
                                                            <option value="BI"
                                                                data-kt-select2-country="../../assets/media/flags/burundi.svg"
                                                                data-select2-id="select2-data-123-grbl">Burundi
                                                            </option>
                                                            <option value="KH"
                                                                data-kt-select2-country="../../assets/media/flags/cambodia.svg"
                                                                data-select2-id="select2-data-124-4uwp">Cambodia
                                                            </option>
                                                            <option value="CM"
                                                                data-kt-select2-country="../../assets/media/flags/cameroon.svg"
                                                                data-select2-id="select2-data-125-v1js">Cameroon
                                                            </option>
                                                            <option value="CA"
                                                                data-kt-select2-country="../../assets/media/flags/canada.svg"
                                                                data-select2-id="select2-data-126-ntjs">Canada
                                                            </option>
                                                            <option value="CV"
                                                                data-kt-select2-country="../../assets/media/flags/cape-verde.svg"
                                                                data-select2-id="select2-data-127-nzg9">Cape Verde
                                                            </option>
                                                            <option value="KY"
                                                                data-kt-select2-country="../../assets/media/flags/cayman-islands.svg"
                                                                data-select2-id="select2-data-128-1rhe">Cayman
                                                                Islands</option>
                                                            <option value="CF"
                                                                data-kt-select2-country="../../assets/media/flags/central-african-republic.svg"
                                                                data-select2-id="select2-data-129-bbv8">Central
                                                                African Republic</option>
                                                            <option value="TD"
                                                                data-kt-select2-country="../../assets/media/flags/chad.svg"
                                                                data-select2-id="select2-data-130-8d5c">Chad
                                                            </option>
                                                            <option value="CL"
                                                                data-kt-select2-country="../../assets/media/flags/chile.svg"
                                                                data-select2-id="select2-data-131-ljv8">Chile
                                                            </option>
                                                            <option value="CN"
                                                                data-kt-select2-country="../../assets/media/flags/china.svg"
                                                                data-select2-id="select2-data-132-2zht">China
                                                            </option>
                                                            <option value="CX"
                                                                data-kt-select2-country="../../assets/media/flags/christmas-island.svg"
                                                                data-select2-id="select2-data-133-7owg">Christmas
                                                                Island</option>
                                                            <option value="CC"
                                                                data-kt-select2-country="../../assets/media/flags/cocos-island.svg"
                                                                data-select2-id="select2-data-134-jfan">Cocos
                                                                (Keeling) Islands</option>
                                                            <option value="CO"
                                                                data-kt-select2-country="../../assets/media/flags/colombia.svg"
                                                                data-select2-id="select2-data-135-tyde">Colombia
                                                            </option>
                                                            <option value="KM"
                                                                data-kt-select2-country="../../assets/media/flags/comoros.svg"
                                                                data-select2-id="select2-data-136-mpkv">Comoros
                                                            </option>
                                                            <option value="CK"
                                                                data-kt-select2-country="../../assets/media/flags/cook-islands.svg"
                                                                data-select2-id="select2-data-137-5gcs">Cook Islands
                                                            </option>
                                                            <option value="CR"
                                                                data-kt-select2-country="../../assets/media/flags/costa-rica.svg"
                                                                data-select2-id="select2-data-138-64th">Costa Rica
                                                            </option>
                                                            <option value="CI"
                                                                data-kt-select2-country="../../assets/media/flags/ivory-coast.svg"
                                                                data-select2-id="select2-data-139-giyd">Côte
                                                                d'Ivoire</option>
                                                            <option value="HR"
                                                                data-kt-select2-country="../../assets/media/flags/croatia.svg"
                                                                data-select2-id="select2-data-140-cl0m">Croatia
                                                            </option>
                                                            <option value="CU"
                                                                data-kt-select2-country="../../assets/media/flags/cuba.svg"
                                                                data-select2-id="select2-data-141-yirq">Cuba
                                                            </option>
                                                            <option value="CW"
                                                                data-kt-select2-country="../../assets/media/flags/curacao.svg"
                                                                data-select2-id="select2-data-142-y1tx">Curaçao
                                                            </option>
                                                            <option value="CZ"
                                                                data-kt-select2-country="../../assets/media/flags/czech-republic.svg"
                                                                data-select2-id="select2-data-143-28xo">Czech
                                                                Republic</option>
                                                            <option value="DK"
                                                                data-kt-select2-country="../../assets/media/flags/denmark.svg"
                                                                data-select2-id="select2-data-144-sd0f">Denmark
                                                            </option>
                                                            <option value="DJ"
                                                                data-kt-select2-country="../../assets/media/flags/djibouti.svg"
                                                                data-select2-id="select2-data-145-ynbf">Djibouti
                                                            </option>
                                                            <option value="DM"
                                                                data-kt-select2-country="../../assets/media/flags/dominica.svg"
                                                                data-select2-id="select2-data-146-3mkv">Dominica
                                                            </option>
                                                            <option value="DO"
                                                                data-kt-select2-country="../../assets/media/flags/dominican-republic.svg"
                                                                data-select2-id="select2-data-147-s05e">Dominican
                                                                Republic</option>
                                                            <option value="EC"
                                                                data-kt-select2-country="../../assets/media/flags/ecuador.svg"
                                                                data-select2-id="select2-data-148-qssr">Ecuador
                                                            </option>
                                                            <option value="EG"
                                                                data-kt-select2-country="../../assets/media/flags/egypt.svg"
                                                                data-select2-id="select2-data-149-polz">Egypt
                                                            </option>
                                                            <option value="SV"
                                                                data-kt-select2-country="../../assets/media/flags/el-salvador.svg"
                                                                data-select2-id="select2-data-150-10s5">El Salvador
                                                            </option>
                                                            <option value="GQ"
                                                                data-kt-select2-country="../../assets/media/flags/equatorial-guinea.svg"
                                                                data-select2-id="select2-data-151-nil4">Equatorial
                                                                Guinea</option>
                                                            <option value="ER"
                                                                data-kt-select2-country="../../assets/media/flags/eritrea.svg"
                                                                data-select2-id="select2-data-152-jf1f">Eritrea
                                                            </option>
                                                            <option value="EE"
                                                                data-kt-select2-country="../../assets/media/flags/estonia.svg"
                                                                data-select2-id="select2-data-153-8avg">Estonia
                                                            </option>
                                                            <option value="ET"
                                                                data-kt-select2-country="../../assets/media/flags/ethiopia.svg"
                                                                data-select2-id="select2-data-154-6nu1">Ethiopia
                                                            </option>
                                                            <option value="FK"
                                                                data-kt-select2-country="../../assets/media/flags/falkland-islands.svg"
                                                                data-select2-id="select2-data-155-ujiy">Falkland
                                                                Islands (Malvinas)</option>
                                                            <option value="FJ"
                                                                data-kt-select2-country="../../assets/media/flags/fiji.svg"
                                                                data-select2-id="select2-data-156-bzzn">Fiji
                                                            </option>
                                                            <option value="FI"
                                                                data-kt-select2-country="../../assets/media/flags/finland.svg"
                                                                data-select2-id="select2-data-157-kmvl">Finland
                                                            </option>
                                                            <option value="FR"
                                                                data-kt-select2-country="../../assets/media/flags/france.svg"
                                                                data-select2-id="select2-data-158-8krl">France
                                                            </option>
                                                            <option value="PF"
                                                                data-kt-select2-country="../../assets/media/flags/french-polynesia.svg"
                                                                data-select2-id="select2-data-159-x7x2">French
                                                                Polynesia</option>
                                                            <option value="GA"
                                                                data-kt-select2-country="../../assets/media/flags/gabon.svg"
                                                                data-select2-id="select2-data-160-jxoo">Gabon
                                                            </option>
                                                            <option value="GM"
                                                                data-kt-select2-country="../../assets/media/flags/gambia.svg"
                                                                data-select2-id="select2-data-161-b0bi">Gambia
                                                            </option>
                                                            <option value="GE"
                                                                data-kt-select2-country="../../assets/media/flags/georgia.svg"
                                                                data-select2-id="select2-data-162-gb5w">Georgia
                                                            </option>
                                                            <option value="DE"
                                                                data-kt-select2-country="../../assets/media/flags/germany.svg"
                                                                data-select2-id="select2-data-163-6fyl">Germany
                                                            </option>
                                                            <option value="GH"
                                                                data-kt-select2-country="../../assets/media/flags/ghana.svg"
                                                                data-select2-id="select2-data-164-jkzp">Ghana
                                                            </option>
                                                            <option value="GI"
                                                                data-kt-select2-country="../../assets/media/flags/gibraltar.svg"
                                                                data-select2-id="select2-data-165-j42i">Gibraltar
                                                            </option>
                                                            <option value="GR"
                                                                data-kt-select2-country="../../assets/media/flags/greece.svg"
                                                                data-select2-id="select2-data-166-8fph">Greece
                                                            </option>
                                                            <option value="GL"
                                                                data-kt-select2-country="../../assets/media/flags/greenland.svg"
                                                                data-select2-id="select2-data-167-pqmi">Greenland
                                                            </option>
                                                            <option value="GD"
                                                                data-kt-select2-country="../../assets/media/flags/grenada.svg"
                                                                data-select2-id="select2-data-168-tcol">Grenada
                                                            </option>
                                                            <option value="GU"
                                                                data-kt-select2-country="../../assets/media/flags/guam.svg"
                                                                data-select2-id="select2-data-169-g8ib">Guam
                                                            </option>
                                                            <option value="GT"
                                                                data-kt-select2-country="../../assets/media/flags/guatemala.svg"
                                                                data-select2-id="select2-data-170-s224">Guatemala
                                                            </option>
                                                            <option value="GG"
                                                                data-kt-select2-country="../../assets/media/flags/guernsey.svg"
                                                                data-select2-id="select2-data-171-30al">Guernsey
                                                            </option>
                                                            <option value="GN"
                                                                data-kt-select2-country="../../assets/media/flags/guinea.svg"
                                                                data-select2-id="select2-data-172-fglk">Guinea
                                                            </option>
                                                            <option value="GW"
                                                                data-kt-select2-country="../../assets/media/flags/guinea-bissau.svg"
                                                                data-select2-id="select2-data-173-v57h">
                                                                Guinea-Bissau</option>
                                                            <option value="HT"
                                                                data-kt-select2-country="../../assets/media/flags/haiti.svg"
                                                                data-select2-id="select2-data-174-qc4u">Haiti
                                                            </option>
                                                            <option value="VA"
                                                                data-kt-select2-country="../../assets/media/flags/vatican-city.svg"
                                                                data-select2-id="select2-data-175-d6s8">Holy See
                                                                (Vatican City State)</option>
                                                            <option value="HN"
                                                                data-kt-select2-country="../../assets/media/flags/honduras.svg"
                                                                data-select2-id="select2-data-176-nqx2">Honduras
                                                            </option>
                                                            <option value="HK"
                                                                data-kt-select2-country="../../assets/media/flags/hong-kong.svg"
                                                                data-select2-id="select2-data-177-cf29">Hong Kong
                                                            </option>
                                                            <option value="HU"
                                                                data-kt-select2-country="../../assets/media/flags/hungary.svg"
                                                                data-select2-id="select2-data-178-t3iu">Hungary
                                                            </option>
                                                            <option value="IS"
                                                                data-kt-select2-country="../../assets/media/flags/iceland.svg"
                                                                data-select2-id="select2-data-179-mx17">Iceland
                                                            </option>
                                                            <option value="IN"
                                                                data-kt-select2-country="../../assets/media/flags/india.svg"
                                                                data-select2-id="select2-data-180-zdu7">India
                                                            </option>
                                                            <option value="ID"
                                                                data-kt-select2-country="../../assets/media/flags/indonesia.svg"
                                                                data-select2-id="select2-data-181-rely">Indonesia
                                                            </option>
                                                            <option value="IR"
                                                                data-kt-select2-country="../../assets/media/flags/iran.svg"
                                                                data-select2-id="select2-data-182-pert">Iran,
                                                                Islamic Republic of</option>
                                                            <option value="IQ"
                                                                data-kt-select2-country="../../assets/media/flags/iraq.svg"
                                                                data-select2-id="select2-data-183-ipzn">Iraq
                                                            </option>
                                                            <option value="IE"
                                                                data-kt-select2-country="../../assets/media/flags/ireland.svg"
                                                                data-select2-id="select2-data-184-8v44">Ireland
                                                            </option>
                                                            <option value="IM"
                                                                data-kt-select2-country="../../assets/media/flags/isle-of-man.svg"
                                                                data-select2-id="select2-data-185-5q53">Isle of Man
                                                            </option>
                                                            <option value="IL"
                                                                data-kt-select2-country="../../assets/media/flags/israel.svg"
                                                                data-select2-id="select2-data-186-48bb">Israel
                                                            </option>
                                                            <option value="IT"
                                                                data-kt-select2-country="../../assets/media/flags/italy.svg"
                                                                data-select2-id="select2-data-187-fvna">Italy
                                                            </option>
                                                            <option value="JM"
                                                                data-kt-select2-country="../../assets/media/flags/jamaica.svg"
                                                                data-select2-id="select2-data-188-ganj">Jamaica
                                                            </option>
                                                            <option value="JP"
                                                                data-kt-select2-country="../../assets/media/flags/japan.svg"
                                                                data-select2-id="select2-data-189-m2f7">Japan
                                                            </option>
                                                            <option value="JE"
                                                                data-kt-select2-country="../../assets/media/flags/jersey.svg"
                                                                data-select2-id="select2-data-190-xpc7">Jersey
                                                            </option>
                                                            <option value="JO"
                                                                data-kt-select2-country="../../assets/media/flags/jordan.svg"
                                                                data-select2-id="select2-data-191-axoj">Jordan
                                                            </option>
                                                            <option value="KZ"
                                                                data-kt-select2-country="../../assets/media/flags/kazakhstan.svg"
                                                                data-select2-id="select2-data-192-2ewj">Kazakhstan
                                                            </option>
                                                            <option value="KE"
                                                                data-kt-select2-country="../../assets/media/flags/kenya.svg"
                                                                data-select2-id="select2-data-193-pa97">Kenya
                                                            </option>
                                                            <option value="KI"
                                                                data-kt-select2-country="../../assets/media/flags/kiribati.svg"
                                                                data-select2-id="select2-data-194-zf32">Kiribati
                                                            </option>
                                                            <option value="KP"
                                                                data-kt-select2-country="../../assets/media/flags/north-korea.svg"
                                                                data-select2-id="select2-data-195-n5mc">Korea,
                                                                Democratic People's Republic of</option>
                                                            <option value="KW"
                                                                data-kt-select2-country="../../assets/media/flags/kuwait.svg"
                                                                data-select2-id="select2-data-196-shu4">Kuwait
                                                            </option>
                                                            <option value="KG"
                                                                data-kt-select2-country="../../assets/media/flags/kyrgyzstan.svg"
                                                                data-select2-id="select2-data-197-6ez0">Kyrgyzstan
                                                            </option>
                                                            <option value="LA"
                                                                data-kt-select2-country="../../assets/media/flags/laos.svg"
                                                                data-select2-id="select2-data-198-7snf">Lao People's
                                                                Democratic Republic</option>
                                                            <option value="LV"
                                                                data-kt-select2-country="../../assets/media/flags/latvia.svg"
                                                                data-select2-id="select2-data-199-w8eo">Latvia
                                                            </option>
                                                            <option value="LB"
                                                                data-kt-select2-country="../../assets/media/flags/lebanon.svg"
                                                                data-select2-id="select2-data-200-fytg">Lebanon
                                                            </option>
                                                            <option value="LS"
                                                                data-kt-select2-country="../../assets/media/flags/lesotho.svg"
                                                                data-select2-id="select2-data-201-k0fc">Lesotho
                                                            </option>
                                                            <option value="LR"
                                                                data-kt-select2-country="../../assets/media/flags/liberia.svg"
                                                                data-select2-id="select2-data-202-lvdq">Liberia
                                                            </option>
                                                            <option value="LY"
                                                                data-kt-select2-country="../../assets/media/flags/libya.svg"
                                                                data-select2-id="select2-data-203-3zj4">Libya
                                                            </option>
                                                            <option value="LI"
                                                                data-kt-select2-country="../../assets/media/flags/liechtenstein.svg"
                                                                data-select2-id="select2-data-204-yxio">
                                                                Liechtenstein</option>
                                                            <option value="LT"
                                                                data-kt-select2-country="../../assets/media/flags/lithuania.svg"
                                                                data-select2-id="select2-data-205-cfqp">Lithuania
                                                            </option>
                                                            <option value="LU"
                                                                data-kt-select2-country="../../assets/media/flags/luxembourg.svg"
                                                                data-select2-id="select2-data-206-4r0b">Luxembourg
                                                            </option>
                                                            <option value="MO"
                                                                data-kt-select2-country="../../assets/media/flags/macao.svg"
                                                                data-select2-id="select2-data-207-s0lk">Macao
                                                            </option>
                                                            <option value="MG"
                                                                data-kt-select2-country="../../assets/media/flags/madagascar.svg"
                                                                data-select2-id="select2-data-208-xuhl">Madagascar
                                                            </option>
                                                            <option value="MW"
                                                                data-kt-select2-country="../../assets/media/flags/malawi.svg"
                                                                data-select2-id="select2-data-209-3xal">Malawi
                                                            </option>
                                                            <option value="MY"
                                                                data-kt-select2-country="../../assets/media/flags/malaysia.svg"
                                                                data-select2-id="select2-data-210-x64i">Malaysia
                                                            </option>
                                                            <option value="MV"
                                                                data-kt-select2-country="../../assets/media/flags/maldives.svg"
                                                                data-select2-id="select2-data-211-w4kc">Maldives
                                                            </option>
                                                            <option value="ML"
                                                                data-kt-select2-country="../../assets/media/flags/mali.svg"
                                                                data-select2-id="select2-data-212-y5dr">Mali
                                                            </option>
                                                            <option value="MT"
                                                                data-kt-select2-country="../../assets/media/flags/malta.svg"
                                                                data-select2-id="select2-data-213-8ovo">Malta
                                                            </option>
                                                            <option value="MH"
                                                                data-kt-select2-country="../../assets/media/flags/marshall-island.svg"
                                                                data-select2-id="select2-data-214-dpe9">Marshall
                                                                Islands</option>
                                                            <option value="MQ"
                                                                data-kt-select2-country="../../assets/media/flags/martinique.svg"
                                                                data-select2-id="select2-data-215-k725">Martinique
                                                            </option>
                                                            <option value="MR"
                                                                data-kt-select2-country="../../assets/media/flags/mauritania.svg"
                                                                data-select2-id="select2-data-216-lxiq">Mauritania
                                                            </option>
                                                            <option value="MU"
                                                                data-kt-select2-country="../../assets/media/flags/mauritius.svg"
                                                                data-select2-id="select2-data-217-lpfv">Mauritius
                                                            </option>
                                                            <option value="MX"
                                                                data-kt-select2-country="../../assets/media/flags/mexico.svg"
                                                                data-select2-id="select2-data-218-7ikr">Mexico
                                                            </option>
                                                            <option value="FM"
                                                                data-kt-select2-country="../../assets/media/flags/micronesia.svg"
                                                                data-select2-id="select2-data-219-kj59">Micronesia,
                                                                Federated States of</option>
                                                            <option value="MD"
                                                                data-kt-select2-country="../../assets/media/flags/moldova.svg"
                                                                data-select2-id="select2-data-220-daxt">Moldova,
                                                                Republic of</option>
                                                            <option value="MC"
                                                                data-kt-select2-country="../../assets/media/flags/monaco.svg"
                                                                data-select2-id="select2-data-221-zupz">Monaco
                                                            </option>
                                                            <option value="MN"
                                                                data-kt-select2-country="../../assets/media/flags/mongolia.svg"
                                                                data-select2-id="select2-data-222-r3yy">Mongolia
                                                            </option>
                                                            <option value="ME"
                                                                data-kt-select2-country="../../assets/media/flags/montenegro.svg"
                                                                data-select2-id="select2-data-223-p24f">Montenegro
                                                            </option>
                                                            <option value="MS"
                                                                data-kt-select2-country="../../assets/media/flags/montserrat.svg"
                                                                data-select2-id="select2-data-224-y9ja">Montserrat
                                                            </option>
                                                            <option value="MA"
                                                                data-kt-select2-country="../../assets/media/flags/morocco.svg"
                                                                data-select2-id="select2-data-225-qif0">Morocco
                                                            </option>
                                                            <option value="MZ"
                                                                data-kt-select2-country="../../assets/media/flags/mozambique.svg"
                                                                data-select2-id="select2-data-226-akzg">Mozambique
                                                            </option>
                                                            <option value="MM"
                                                                data-kt-select2-country="../../assets/media/flags/myanmar.svg"
                                                                data-select2-id="select2-data-227-kmpm">Myanmar
                                                            </option>
                                                            <option value="NA"
                                                                data-kt-select2-country="../../assets/media/flags/namibia.svg"
                                                                data-select2-id="select2-data-228-i630">Namibia
                                                            </option>
                                                            <option value="NR"
                                                                data-kt-select2-country="../../assets/media/flags/nauru.svg"
                                                                data-select2-id="select2-data-229-q7pq">Nauru
                                                            </option>
                                                            <option value="NP"
                                                                data-kt-select2-country="../../assets/media/flags/nepal.svg"
                                                                data-select2-id="select2-data-230-2u3t">Nepal
                                                            </option>
                                                            <option value="NL"
                                                                data-kt-select2-country="../../assets/media/flags/netherlands.svg"
                                                                data-select2-id="select2-data-231-ffb1">Netherlands
                                                            </option>
                                                            <option value="NZ"
                                                                data-kt-select2-country="../../assets/media/flags/new-zealand.svg"
                                                                data-select2-id="select2-data-232-g442">New Zealand
                                                            </option>
                                                            <option value="NI"
                                                                data-kt-select2-country="../../assets/media/flags/nicaragua.svg"
                                                                data-select2-id="select2-data-233-615a">Nicaragua
                                                            </option>
                                                            <option value="NE"
                                                                data-kt-select2-country="../../assets/media/flags/niger.svg"
                                                                data-select2-id="select2-data-234-n010">Niger
                                                            </option>
                                                            <option value="NG"
                                                                data-kt-select2-country="../../assets/media/flags/nigeria.svg"
                                                                data-select2-id="select2-data-235-lo0x">Nigeria
                                                            </option>
                                                            <option value="NU"
                                                                data-kt-select2-country="../../assets/media/flags/niue.svg"
                                                                data-select2-id="select2-data-236-wzke">Niue
                                                            </option>
                                                            <option value="NF"
                                                                data-kt-select2-country="../../assets/media/flags/norfolk-island.svg"
                                                                data-select2-id="select2-data-237-qhp9">Norfolk
                                                                Island</option>
                                                            <option value="MP"
                                                                data-kt-select2-country="../../assets/media/flags/northern-mariana-islands.svg"
                                                                data-select2-id="select2-data-238-fo9o">Northern
                                                                Mariana Islands</option>
                                                            <option value="NO"
                                                                data-kt-select2-country="../../assets/media/flags/norway.svg"
                                                                data-select2-id="select2-data-239-3w3k">Norway
                                                            </option>
                                                            <option value="OM"
                                                                data-kt-select2-country="../../assets/media/flags/oman.svg"
                                                                data-select2-id="select2-data-240-z4kj">Oman
                                                            </option>
                                                            <option value="PK"
                                                                data-kt-select2-country="../../assets/media/flags/pakistan.svg"
                                                                data-select2-id="select2-data-241-b72f">Pakistan
                                                            </option>
                                                            <option value="PW"
                                                                data-kt-select2-country="../../assets/media/flags/palau.svg"
                                                                data-select2-id="select2-data-242-oaia">Palau
                                                            </option>
                                                            <option value="PS"
                                                                data-kt-select2-country="../../assets/media/flags/palestine.svg"
                                                                data-select2-id="select2-data-243-pnm7">Palestinian
                                                                Territory, Occupied</option>
                                                            <option value="PA"
                                                                data-kt-select2-country="../../assets/media/flags/panama.svg"
                                                                data-select2-id="select2-data-244-kcsj">Panama
                                                            </option>
                                                            <option value="PG"
                                                                data-kt-select2-country="../../assets/media/flags/papua-new-guinea.svg"
                                                                data-select2-id="select2-data-245-0hm9">Papua New
                                                                Guinea</option>
                                                            <option value="PY"
                                                                data-kt-select2-country="../../assets/media/flags/paraguay.svg"
                                                                data-select2-id="select2-data-246-j21v">Paraguay
                                                            </option>
                                                            <option value="PE"
                                                                data-kt-select2-country="../../assets/media/flags/peru.svg"
                                                                data-select2-id="select2-data-247-vmxk">Peru
                                                            </option>
                                                            <option value="PH"
                                                                data-kt-select2-country="../../assets/media/flags/philippines.svg"
                                                                data-select2-id="select2-data-248-cdg1">Philippines
                                                            </option>
                                                            <option value="PL"
                                                                data-kt-select2-country="../../assets/media/flags/poland.svg"
                                                                data-select2-id="select2-data-249-thnw">Poland
                                                            </option>
                                                            <option value="PT"
                                                                data-kt-select2-country="../../assets/media/flags/portugal.svg"
                                                                data-select2-id="select2-data-250-xu4c">Portugal
                                                            </option>
                                                            <option value="PR"
                                                                data-kt-select2-country="../../assets/media/flags/puerto-rico.svg"
                                                                data-select2-id="select2-data-251-zwti">Puerto Rico
                                                            </option>
                                                            <option value="QA"
                                                                data-kt-select2-country="../../assets/media/flags/qatar.svg"
                                                                data-select2-id="select2-data-252-1sb7">Qatar
                                                            </option>
                                                            <option value="RO"
                                                                data-kt-select2-country="../../assets/media/flags/romania.svg"
                                                                data-select2-id="select2-data-253-r8fa">Romania
                                                            </option>
                                                            <option value="RU"
                                                                data-kt-select2-country="../../assets/media/flags/russia.svg"
                                                                data-select2-id="select2-data-254-jhmm">Russian
                                                                Federation</option>
                                                            <option value="RW"
                                                                data-kt-select2-country="../../assets/media/flags/rwanda.svg"
                                                                data-select2-id="select2-data-255-c6rn">Rwanda
                                                            </option>
                                                            <option value="BL"
                                                                data-kt-select2-country="../../assets/media/flags/st-barts.svg"
                                                                data-select2-id="select2-data-256-ahd4">Saint
                                                                Barthélemy</option>
                                                            <option value="KN"
                                                                data-kt-select2-country="../../assets/media/flags/saint-kitts-and-nevis.svg"
                                                                data-select2-id="select2-data-257-dut9">Saint Kitts
                                                                and Nevis</option>
                                                            <option value="LC"
                                                                data-kt-select2-country="../../assets/media/flags/st-lucia.svg"
                                                                data-select2-id="select2-data-258-l5oi">Saint Lucia
                                                            </option>
                                                            <option value="MF"
                                                                data-kt-select2-country="../../assets/media/flags/sint-maarten.svg"
                                                                data-select2-id="select2-data-259-loqo">Saint Martin
                                                                (French part)</option>
                                                            <option value="VC"
                                                                data-kt-select2-country="../../assets/media/flags/st-vincent-and-the-grenadines.svg"
                                                                data-select2-id="select2-data-260-wqtp">Saint
                                                                Vincent and the Grenadines</option>
                                                            <option value="WS"
                                                                data-kt-select2-country="../../assets/media/flags/samoa.svg"
                                                                data-select2-id="select2-data-261-dgv3">Samoa
                                                            </option>
                                                            <option value="SM"
                                                                data-kt-select2-country="../../assets/media/flags/san-marino.svg"
                                                                data-select2-id="select2-data-262-lk5a">San Marino
                                                            </option>
                                                            <option value="ST"
                                                                data-kt-select2-country="../../assets/media/flags/sao-tome-and-prince.svg"
                                                                data-select2-id="select2-data-263-58cm">Sao Tome and
                                                                Principe</option>
                                                            <option value="SA"
                                                                data-kt-select2-country="../../assets/media/flags/saudi-arabia.svg"
                                                                data-select2-id="select2-data-264-czu9">Saudi Arabia
                                                            </option>
                                                            <option value="SN"
                                                                data-kt-select2-country="../../assets/media/flags/senegal.svg"
                                                                data-select2-id="select2-data-265-pebd">Senegal
                                                            </option>
                                                            <option value="RS"
                                                                data-kt-select2-country="../../assets/media/flags/serbia.svg"
                                                                data-select2-id="select2-data-266-jdmh">Serbia
                                                            </option>
                                                            <option value="SC"
                                                                data-kt-select2-country="../../assets/media/flags/seychelles.svg"
                                                                data-select2-id="select2-data-267-kprl">Seychelles
                                                            </option>
                                                            <option value="SL"
                                                                data-kt-select2-country="../../assets/media/flags/sierra-leone.svg"
                                                                data-select2-id="select2-data-268-loop">Sierra Leone
                                                            </option>
                                                            <option value="SG"
                                                                data-kt-select2-country="../../assets/media/flags/singapore.svg"
                                                                data-select2-id="select2-data-269-5uyh">Singapore
                                                            </option>
                                                            <option value="SX"
                                                                data-kt-select2-country="../../assets/media/flags/sint-maarten.svg"
                                                                data-select2-id="select2-data-270-o6j7">Sint Maarten
                                                                (Dutch part)</option>
                                                            <option value="SK"
                                                                data-kt-select2-country="../../assets/media/flags/slovakia.svg"
                                                                data-select2-id="select2-data-271-c8bf">Slovakia
                                                            </option>
                                                            <option value="SI"
                                                                data-kt-select2-country="../../assets/media/flags/slovenia.svg"
                                                                data-select2-id="select2-data-272-exiq">Slovenia
                                                            </option>
                                                            <option value="SB"
                                                                data-kt-select2-country="../../assets/media/flags/solomon-islands.svg"
                                                                data-select2-id="select2-data-273-sxs3">Solomon
                                                                Islands</option>
                                                            <option value="SO"
                                                                data-kt-select2-country="../../assets/media/flags/somalia.svg"
                                                                data-select2-id="select2-data-274-lzwi">Somalia
                                                            </option>
                                                            <option value="ZA"
                                                                data-kt-select2-country="../../assets/media/flags/south-africa.svg"
                                                                data-select2-id="select2-data-275-1a9v">South Africa
                                                            </option>
                                                            <option value="KR"
                                                                data-kt-select2-country="../../assets/media/flags/south-korea.svg"
                                                                data-select2-id="select2-data-276-v1l5">South Korea
                                                            </option>
                                                            <option value="SS"
                                                                data-kt-select2-country="../../assets/media/flags/south-sudan.svg"
                                                                data-select2-id="select2-data-277-cpop">South Sudan
                                                            </option>
                                                            <option value="ES"
                                                                data-kt-select2-country="../../assets/media/flags/spain.svg"
                                                                data-select2-id="select2-data-278-u092">Spain
                                                            </option>
                                                            <option value="LK"
                                                                data-kt-select2-country="../../assets/media/flags/sri-lanka.svg"
                                                                data-select2-id="select2-data-279-4jnk">Sri Lanka
                                                            </option>
                                                            <option value="SD"
                                                                data-kt-select2-country="../../assets/media/flags/sudan.svg"
                                                                data-select2-id="select2-data-280-z8cw">Sudan
                                                            </option>
                                                            <option value="SR"
                                                                data-kt-select2-country="../../assets/media/flags/suriname.svg"
                                                                data-select2-id="select2-data-281-oarv">Suriname
                                                            </option>
                                                            <option value="SZ"
                                                                data-kt-select2-country="../../assets/media/flags/swaziland.svg"
                                                                data-select2-id="select2-data-282-lm1f">Swaziland
                                                            </option>
                                                            <option value="SE"
                                                                data-kt-select2-country="../../assets/media/flags/sweden.svg"
                                                                data-select2-id="select2-data-283-sqys">Sweden
                                                            </option>
                                                            <option value="CH"
                                                                data-kt-select2-country="../../assets/media/flags/switzerland.svg"
                                                                data-select2-id="select2-data-284-77x8">Switzerland
                                                            </option>
                                                            <option value="SY"
                                                                data-kt-select2-country="../../assets/media/flags/syria.svg"
                                                                data-select2-id="select2-data-285-y76t">Syrian Arab
                                                                Republic</option>
                                                            <option value="TW"
                                                                data-kt-select2-country="../../assets/media/flags/taiwan.svg"
                                                                data-select2-id="select2-data-286-rng6">Taiwan,
                                                                Province of China</option>
                                                            <option value="TJ"
                                                                data-kt-select2-country="../../assets/media/flags/tajikistan.svg"
                                                                data-select2-id="select2-data-287-c913">Tajikistan
                                                            </option>
                                                            <option value="TZ"
                                                                data-kt-select2-country="../../assets/media/flags/tanzania.svg"
                                                                data-select2-id="select2-data-288-oyhq">Tanzania,
                                                                United Republic of</option>
                                                            <option value="TH"
                                                                data-kt-select2-country="../../assets/media/flags/thailand.svg"
                                                                data-select2-id="select2-data-289-jjkd">Thailand
                                                            </option>
                                                            <option value="TG"
                                                                data-kt-select2-country="../../assets/media/flags/togo.svg"
                                                                data-select2-id="select2-data-290-emq5">Togo
                                                            </option>
                                                            <option value="TK"
                                                                data-kt-select2-country="../../assets/media/flags/tokelau.svg"
                                                                data-select2-id="select2-data-291-dj6d">Tokelau
                                                            </option>
                                                            <option value="TO"
                                                                data-kt-select2-country="../../assets/media/flags/tonga.svg"
                                                                data-select2-id="select2-data-292-fzea">Tonga
                                                            </option>
                                                            <option value="TT"
                                                                data-kt-select2-country="../../assets/media/flags/trinidad-and-tobago.svg"
                                                                data-select2-id="select2-data-293-u267">Trinidad and
                                                                Tobago</option>
                                                            <option value="TN"
                                                                data-kt-select2-country="../../assets/media/flags/tunisia.svg"
                                                                data-select2-id="select2-data-294-8e1s">Tunisia
                                                            </option>
                                                            <option value="TR"
                                                                data-kt-select2-country="../../assets/media/flags/turkey.svg"
                                                                data-select2-id="select2-data-295-3o3y">Turkey
                                                            </option>
                                                            <option value="TM"
                                                                data-kt-select2-country="../../assets/media/flags/turkmenistan.svg"
                                                                data-select2-id="select2-data-296-2pvg">Turkmenistan
                                                            </option>
                                                            <option value="TC"
                                                                data-kt-select2-country="../../assets/media/flags/turks-and-caicos.svg"
                                                                data-select2-id="select2-data-297-jiaz">Turks and
                                                                Caicos Islands</option>
                                                            <option value="TV"
                                                                data-kt-select2-country="../../assets/media/flags/tuvalu.svg"
                                                                data-select2-id="select2-data-298-ldqn">Tuvalu
                                                            </option>
                                                            <option value="UG"
                                                                data-kt-select2-country="../../assets/media/flags/uganda.svg"
                                                                data-select2-id="select2-data-299-utie">Uganda
                                                            </option>
                                                            <option value="UA"
                                                                data-kt-select2-country="../../assets/media/flags/ukraine.svg"
                                                                data-select2-id="select2-data-300-jm55">Ukraine
                                                            </option>
                                                            <option value="AE"
                                                                data-kt-select2-country="../../assets/media/flags/united-arab-emirates.svg"
                                                                data-select2-id="select2-data-301-4cfp">United Arab
                                                                Emirates</option>
                                                            <option value="GB"
                                                                data-kt-select2-country="../../assets/media/flags/united-kingdom.svg"
                                                                data-select2-id="select2-data-302-rg8f">United
                                                                Kingdom</option>
                                                            <option value="US"
                                                                data-kt-select2-country="../../assets/media/flags/united-states.svg"
                                                                selected="" data-select2-id="select2-data-70-0u6b">
                                                                United States</option>
                                                            <option value="UY"
                                                                data-kt-select2-country="../../assets/media/flags/uruguay.svg"
                                                                data-select2-id="select2-data-303-23ih">Uruguay
                                                            </option>
                                                            <option value="UZ"
                                                                data-kt-select2-country="../../assets/media/flags/uzbekistan.svg"
                                                                data-select2-id="select2-data-304-6rqj">Uzbekistan
                                                            </option>
                                                            <option value="VU"
                                                                data-kt-select2-country="../../assets/media/flags/vanuatu.svg"
                                                                data-select2-id="select2-data-305-3xwd">Vanuatu
                                                            </option>
                                                            <option value="VE"
                                                                data-kt-select2-country="../../assets/media/flags/venezuela.svg"
                                                                data-select2-id="select2-data-306-7pp3">Venezuela,
                                                                Bolivarian Republic of</option>
                                                            <option value="VN"
                                                                data-kt-select2-country="../../assets/media/flags/vietnam.svg"
                                                                data-select2-id="select2-data-307-kxov">Vietnam
                                                            </option>
                                                            <option value="VI"
                                                                data-kt-select2-country="../../assets/media/flags/virgin-islands.svg"
                                                                data-select2-id="select2-data-308-ax5s">Virgin
                                                                Islands</option>
                                                            <option value="YE"
                                                                data-kt-select2-country="../../assets/media/flags/yemen.svg"
                                                                data-select2-id="select2-data-309-5x82">Yemen
                                                            </option>
                                                            <option value="ZM"
                                                                data-kt-select2-country="../../assets/media/flags/zambia.svg"
                                                                data-select2-id="select2-data-310-u09g">Zambia
                                                            </option>
                                                            <option value="ZW"
                                                                data-kt-select2-country="../../assets/media/flags/zimbabwe.svg"
                                                                data-select2-id="select2-data-311-pi6e">Zimbabwe
                                                            </option>
                                                        </select> */}
                                                        <Select className='rounded bg-danger'
                                                            style={{
                                                                width: "100%",
                                                                height: "45px"
                                                            }}
                                                            showSearch
                                                            placeholder={<b>Select Country</b>}
                                                            options={[
                                                                { "value": "Afghanistan", "label": "Afghanistan" },
                                                                { "value": "Albania", "label": "Albania" },
                                                                { "value": "Algeria", "label": "Algeria" },
                                                                { "value": "Andorra", "label": "Andorra" },
                                                                { "value": "Angola", "label": "Angola" },
                                                                { "value": "Antigua and Barbuda", "label": "Antigua and Barbuda" },
                                                                { "value": "Argentina", "label": "Argentina" },
                                                                { "value": "Armenia", "label": "Armenia" },
                                                                { "value": "Australia", "label": "Australia" },
                                                                { "value": "Austria", "label": "Austria" },
                                                                { "value": "Azerbaijan", "label": "Azerbaijan" },
                                                                { "value": "Bahamas", "label": "Bahamas" },
                                                                { "value": "Bahrain", "label": "Bahrain" },
                                                                { "value": "Bangladesh", "label": "Bangladesh" },
                                                                { "value": "Barbados", "label": "Barbados" },
                                                                { "value": "Belarus", "label": "Belarus" },
                                                                { "value": "Belgium", "label": "Belgium" },
                                                                { "value": "Belize", "label": "Belize" },
                                                                { "value": "Benin", "label": "Benin" },
                                                                { "value": "Bhutan", "label": "Bhutan" },
                                                                { "value": "Bolivia", "label": "Bolivia" },
                                                                { "value": "Bosnia and Herzegovina", "label": "Bosnia and Herzegovina" },
                                                                { "value": "Botswana", "label": "Botswana" },
                                                                { "value": "Brazil", "label": "Brazil" },
                                                                { "value": "Brunei", "label": "Brunei" },
                                                                { "value": "Bulgaria", "label": "Bulgaria" },
                                                                { "value": "Burkina Faso", "label": "Burkina Faso" },
                                                                { "value": "Burundi", "label": "Burundi" },
                                                                { "value": "Cabo Verde", "label": "Cabo Verde" },
                                                                { "value": "Cambodia", "label": "Cambodia" },
                                                                { "value": "Cameroon", "label": "Cameroon" },
                                                                { "value": "Canada", "label": "Canada" },
                                                                { "value": "Central African Republic", "label": "Central African Republic" },
                                                                { "value": "Chad", "label": "Chad" },
                                                                { "value": "Chile", "label": "Chile" },
                                                                { "value": "China", "label": "China" },
                                                                { "value": "Colombia", "label": "Colombia" },
                                                                { "value": "Comoros", "label": "Comoros" },
                                                                { "value": "Congo, Democratic Republic of the", "label": "Congo, Democratic Republic of the" },
                                                                { "value": "Congo, Republic of the", "label": "Congo, Republic of the" },
                                                                { "value": "Costa Rica", "label": "Costa Rica" },
                                                                { "value": "Croatia", "label": "Croatia" },
                                                                { "value": "Cuba", "label": "Cuba" },
                                                                { "value": "Cyprus", "label": "Cyprus" },
                                                                { "value": "Czech Republic", "label": "Czech Republic" },
                                                                { "value": "Denmark", "label": "Denmark" },
                                                                { "value": "Djibouti", "label": "Djibouti" },
                                                                { "value": "Dominica", "label": "Dominica" },
                                                                { "value": "Dominican Republic", "label": "Dominican Republic" },
                                                                { "value": "Ecuador", "label": "Ecuador" },
                                                                { "value": "Egypt", "label": "Egypt" },
                                                                { "value": "El Salvador", "label": "El Salvador" },
                                                                { "value": "Equatorial Guinea", "label": "Equatorial Guinea" },
                                                                { "value": "Eritrea", "label": "Eritrea" },
                                                                { "value": "Estonia", "label": "Estonia" },
                                                                { "value": "Eswatini", "label": "Eswatini" },
                                                                { "value": "Ethiopia", "label": "Ethiopia" },
                                                                { "value": "Fiji", "label": "Fiji" },
                                                                { "value": "Finland", "label": "Finland" },
                                                                { "value": "France", "label": "France" },
                                                                { "value": "Gabon", "label": "Gabon" },
                                                                { "value": "Gambia", "label": "Gambia" },
                                                                { "value": "Georgia", "label": "Georgia" },
                                                                { "value": "Germany", "label": "Germany" },
                                                                { "value": "Ghana", "label": "Ghana" },
                                                                { "value": "Greece", "label": "Greece" },
                                                                { "value": "Grenada", "label": "Grenada" },
                                                                { "value": "Guatemala", "label": "Guatemala" },
                                                                { "value": "Guinea", "label": "Guinea" },
                                                                { "value": "Guinea-Bissau", "label": "Guinea-Bissau" },
                                                                { "value": "Guyana", "label": "Guyana" },
                                                                { "value": "Haiti", "label": "Haiti" },
                                                                { "value": "Honduras", "label": "Honduras" },
                                                                { "value": "Hungary", "label": "Hungary" },
                                                                { "value": "Iceland", "label": "Iceland" },
                                                                { "value": "India", "label": "India" },
                                                                { "value": "Indonesia", "label": "Indonesia" },
                                                                { "value": "Iran", "label": "Iran" },
                                                                { "value": "Iraq", "label": "Iraq" },
                                                                { "value": "Ireland", "label": "Ireland" },
                                                                { "value": "Israel", "label": "Israel" },
                                                                { "value": "Italy", "label": "Italy" },
                                                                { "value": "Jamaica", "label": "Jamaica" },
                                                                { "value": "Japan", "label": "Japan" },
                                                                { "value": "Jordan", "label": "Jordan" },
                                                                { "value": "Kazakhstan", "label": "Kazakhstan" },
                                                                { "value": "Kenya", "label": "Kenya" },
                                                                { "value": "Kiribati", "label": "Kiribati" },
                                                                { "value": "Kuwait", "label": "Kuwait" },
                                                                { "value": "Kyrgyzstan", "label": "Kyrgyzstan" },
                                                                { "value": "Laos", "label": "Laos" },
                                                                { "value": "Latvia", "label": "Latvia" },
                                                                { "value": "Lebanon", "label": "Lebanon" },
                                                                { "value": "Lesotho", "label": "Lesotho" },
                                                                { "value": "Liberia", "label": "Liberia" },
                                                                { "value": "Libya", "label": "Libya" },
                                                                { "value": "Liechtenstein", "label": "Liechtenstein" },
                                                                { "value": "Lithuania", "label": "Lithuania" },
                                                                { "value": "Luxembourg", "label": "Luxembourg" },
                                                                { "value": "Madagascar", "label": "Madagascar" },
                                                                { "value": "Malawi", "label": "Malawi" },
                                                                { "value": "Malaysia", "label": "Malaysia" },
                                                                { "value": "Maldives", "label": "Maldives" },
                                                                { "value": "Mali", "label": "Mali" },
                                                                { "value": "Malta", "label": "Malta" },
                                                                { "value": "Marshall Islands", "label": "Marshall Islands" },
                                                                { "value": "Mauritania", "label": "Mauritania" },
                                                                { "value": "Mauritius", "label": "Mauritius" },
                                                                { "value": "Mexico", "label": "Mexico" },
                                                                { "value": "Micronesia", "label": "Micronesia" },
                                                                { "value": "Moldova", "label": "Moldova" },
                                                                { "value": "Monaco", "label": "Monaco" },
                                                                { "value": "Mongolia", "label": "Mongolia" },
                                                                { "value": "Montenegro", "label": "Montenegro" },
                                                                { "value": "Morocco", "label": "Morocco" },
                                                                { "value": "Mozambique", "label": "Mozambique" },
                                                                { "value": "Myanmar", "label": "Myanmar" },
                                                                { "value": "Namibia", "label": "Namibia" },
                                                                { "value": "Nauru", "label": "Nauru" },
                                                                { "value": "Nepal", "label": "Nepal" },
                                                                { "value": "Netherlands", "label": "Netherlands" },
                                                                { "value": "New Zealand", "label": "New Zealand" },
                                                                { "value": "Nicaragua", "label": "Nicaragua" },
                                                                { "value": "Niger", "label": "Niger" },
                                                                { "value": "Nigeria", "label": "Nigeria" },
                                                                { "value": "North Korea", "label": "North Korea" },
                                                                { "value": "North Macedonia", "label": "North Macedonia" },
                                                                { "value": "Norway", "label": "Norway" },
                                                                { "value": "Oman", "label": "Oman" },
                                                                { "value": "Pakistan", "label": "Pakistan" },
                                                                { "value": "Palau", "label": "Palau" },
                                                                { "value": "Palestine", "label": "Palestine" },
                                                                { "value": "Panama", "label": "Panama" },
                                                                { "value": "Papua New Guinea", "label": "Papua New Guinea" },
                                                                { "value": "Paraguay", "label": "Paraguay" },
                                                                { "value": "Peru", "label": "Peru" },
                                                                { "value": "Philippines", "label": "Philippines" },
                                                                { "value": "Poland", "label": "Poland" },
                                                                { "value": "Portugal", "label": "Portugal" },
                                                                { "value": "Qatar", "label": "Qatar" },
                                                                { "value": "Romania", "label": "Romania" },
                                                                { "value": "Russia", "label": "Russia" },
                                                                { "value": "Rwanda", "label": "Rwanda" },
                                                                { "value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis" },
                                                                { "value": "Saint Lucia", "label": "Saint Lucia" },
                                                                { "value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines" },
                                                                { "value": "Samoa", "label": "Samoa" },
                                                                { "value": "San Marino", "label": "San Marino" },
                                                                { "value": "Sao Tome and Principe", "label": "Sao Tome and Principe" },
                                                                { "value": "Saudi Arabia", "label": "Saudi Arabia" },
                                                                { "value": "Senegal", "label": "Senegal" },
                                                                { "value": "Serbia", "label": "Serbia" },
                                                                { "value": "Seychelles", "label": "Seychelles" },
                                                                { "value": "Sierra Leone", "label": "Sierra Leone" },
                                                                { "value": "Singapore", "label": "Singapore" },
                                                                { "value": "Slovakia", "label": "Slovakia" },
                                                                { "value": "Slovenia", "label": "Slovenia" },
                                                                { "value": "Solomon Islands", "label": "Solomon Islands" },
                                                                { "value": "Somalia", "label": "Somalia" },
                                                                { "value": "South Africa", "label": "South Africa" },
                                                                { "value": "South Korea", "label": "South Korea" },
                                                                { "value": "South Sudan", "label": "South Sudan" },
                                                                { "value": "Spain", "label": "Spain" },
                                                                { "value": "Sri Lanka", "label": "Sri Lanka" },
                                                                { "value": "Sudan", "label": "Sudan" },
                                                                { "value": "Suriname", "label": "Suriname" },
                                                                { "value": "Sweden", "label": "Sweden" },
                                                                { "value": "Switzerland", "label": "Switzerland" },
                                                                { "value": "Syria", "label": "Syria" },
                                                                { "value": "Taiwan", "label": "Taiwan" },
                                                                { "value": "Tajikistan", "label": "Tajikistan" },
                                                                { "value": "Tanzania", "label": "Tanzania" },
                                                                { "value": "Thailand", "label": "Thailand" },
                                                                { "value": "Timor-Leste", "label": "Timor-Leste" },
                                                                { "value": "Togo", "label": "Togo" },
                                                                { "value": "Tonga", "label": "Tonga" },
                                                                { "value": "Trinidad and Tobago", "label": "Trinidad and Tobago" },
                                                                { "value": "Tunisia", "label": "Tunisia" },
                                                                { "value": "Turkey", "label": "Turkey" },
                                                                { "value": "Turkmenistan", "label": "Turkmenistan" },
                                                                { "value": "Tuvalu", "label": "Tuvalu" },
                                                                { "value": "Uganda", "label": "Uganda" },
                                                                { "value": "Ukraine", "label": "Ukraine" },
                                                                { "value": "United Arab Emirates", "label": "United Arab Emirates" },
                                                                { "value": "United Kingdom", "label": "United Kingdom" },
                                                                { "value": "United States", "label": "United States" },
                                                                { "value": "Uruguay", "label": "Uruguay" },
                                                                { "value": "Uzbekistan", "label": "Uzbekistan" },
                                                                { "value": "Vanuatu", "label": "Vanuatu" },
                                                                { "value": "Vatican City", "label": "Vatican City" },
                                                                { "value": "Venezuela", "label": "Venezuela" },
                                                                { "value": "Vietnam", "label": "Vietnam" },
                                                                { "value": "Yemen", "label": "Yemen" },
                                                                { "value": "Zambia", "label": "Zambia" },
                                                                { "value": "Zimbabwe", "label": "Zimbabwe" }
                                                            ]}
                                                        />

                                                    </div>
                                                    <div className="col-6">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Latitude</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="Barcode Number" value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Enter the latitude 
                                                            number.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    <div className="col-6">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">longitude</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="Barcode Number" value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Enter the product barcode
                                                            number.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>

                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Variations-->*/}
                                        <div className="d-flex justify-content-end">
                                            {/*<!--begin::Button-->*/}
                                            <a href="apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel"
                                                className="btn btn-danger me-5">Cancel</a>
                                            {/*<!--end::Button-->*/}
                                            {/*<!-- begin::preview -->*/}
                                            <button type="button" className="btn btn-warning me-5" data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_1">
                                                Launch Preview
                                            </button>

                                            <div className="modal fade" tabindex="-1" id="kt_modal_1">
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content" style={{ height: "550px" }}>
                                                        {/*<!-- <div className="modal-header">Preview
                                                          <button type="button" className="btn btn-lg" data-bs-dismiss="modal"><i className="bi bi-x-lg fs-1"></i></button>
                                                      </div> -->*/}
                                                        {/* <iframe src="./Preview.html" frameborder="0"
                                                            className="w-100 h-100 border-0" allowfullscreen></iframe> */}
                                                        <p>preview will be shown here</p>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-danger"
                                                                data-bs-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<!-- end::preview -->*/}

                                            {/*<!--begin::Button-->*/}
                                            <button type="submit" id="kt_ecommerce_add_product_submit"
                                                className="btn btn-primary">
                                                <span className="indicator-label">Save</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            {/*<!--end::Button-->*/}
                                        </div>
                                    </div>
                                </div>
                                {/*<!--end::Tab pane-->*/}
                            </div>
                            {/*<!--end::Tab content-->*/}





                        </div>
                        {/*<!--end::Main column-->*/}
                    </form>
                    {/*<!--end::Form-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
        </Base>
    )
}

export default NewAd