import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Base from './Components/Base/Base';
import AdListings from './Pages/listings/AdListings';
import SellersList from './Pages/seller/SellersList';
import CategoriesList from './Pages/categories/CategoriesList';
import SubcategoriesList from './Pages/subcategories/SubcategoriesList';
import BuyersList from './Pages/buyer/BuyersList';
import BrandsList from './Pages/brands/BrandsList';
import SellerDetails from './Pages/seller/SellerDetails';
import SellerAds from './Pages/seller/SellerAds';
import BuyerDetails from './Pages/buyer/BuyerDetails';
import NewAd from './Pages/seller/NewAd';
import Preview from './Pages/Preview/Preview';
import AdDetails from './Pages/listings/AdDetails';
import Signin from './Pages/Authentication/Signin';
import Signup from './Pages/Authentication/Signup';
import NewPassword from './Pages/Authentication/NewPassword';
import ResetPassword from './Pages/Authentication/ResetPassword';
import Pricing from './Pages/Authentication/Pricing';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='base' element={<Base />} />

          <Route path='listings' element={<AdListings />} />
          <Route path='addetails' element={<AdDetails />} />


          <Route path='categories' element={<CategoriesList />} />
          <Route path='subcat' element={<SubcategoriesList />} />
          <Route path='brands' element={<BrandsList />} />

          <Route path='sellers' element={<SellersList />} />
          <Route path='sellerdetails' element={<SellerDetails />} />
          <Route path='sellerads' element={<SellerAds />} />

          <Route path='buyers' element={<BuyersList />} />
          <Route path='buyerdetails' element={<BuyerDetails />} />

          <Route path='newad' element={<NewAd />} />
          <Route path='preview' element={<Preview />} />


          <Route path='signin' element={<Signin />} />
          <Route path='signup' element={<Signup />} />
          <Route path='newpass' element={<NewPassword />} />
          <Route path='resetpass' element={<ResetPassword />} />


          <Route path='pricing' element={<Pricing />} />



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
