import React from 'react'
import Base from '../../Components/Base/Base'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



const initialData = [
    { id: 1, BuyerName: 'Francis Mitcham', Email: 'f.mit@kpmg.com', Phone: '1234567890', LastPurchased: 'House for sale', Price: '$123.45', BoughtOn: '01/15/2022' },
    { id: 2, BuyerName: 'Olivia Wild	', Email: 'olivia@corpmail.com', Phone: '0987654321', LastPurchased: 'House for rent...', Price: '$234.56', BoughtOn: '02/20/2022' },
    { id: 3, BuyerName: 'Neil Owen', Email: 'owen.neil@gmail.com', Phone: '5678901234', LastPurchased: 'Car for sale...', Price: '$345.67', BoughtOn: '03/25/2022' },
    { id: 4, BuyerName: 'Dan Wilson', Email: 'dam@consilting.com', Phone: '4321098765', LastPurchased: 'Appartment for...', Price: '$456.78', BoughtOn: '04/30/2022' },
    { id: 5, BuyerName: 'Emma Bold', Email: 'emma@intenso.com', Phone: '6789012345', LastPurchased: 'Cleaning servi...', Price: '$567.89', BoughtOn: '05/05/2022' },
    { id: 6, BuyerName: 'Ana Crown', Email: 'ana.cf@limtel.com', Phone: '3456789012', LastPurchased: 'Bike for sale', Price: '$678.90', BoughtOn: '06/10/2022' },
    { id: 7, BuyerName: 'Emma Smith', Email: 'smith@kpmg.com', Phone: '9012345678', LastPurchased: 'Job at google...', Price: '$789.01', BoughtOn: '07/15/2022' },
    { id: 8, BuyerName: 'Melody Macy', Email: 'melody@altbox.com', Phone: '2345678901', LastPurchased: 'Python classes at...', Price: '$890.12', BoughtOn: '08/20/2022' },
    { id: 9, BuyerName: 'Max Smith', Email: 'max@kt.com', Phone: '7890123456', LastPurchased: 'king size bed...', Price: '$901.23', BoughtOn: '09/25/2022' },
    { id: 10, BuyerName: 'Sean Bean', Email: 'sean@dellito.com', Phone: '5678901234', LastPurchased: 'Used TV for..', Price: '$234.56', BoughtOn: '10/30/2022' },
    { id: 11, BuyerName: 'Brian Cox', Email: 'brian@exchange.com', Phone: '0987654321', LastPurchased: 'House for sale...', Price: '$345.67', BoughtOn: '11/05/2022' },
    { id: 12, BuyerName: 'Mikaela Collins', Email: 'mik@pex.com', Phone: '1234567890', LastPurchased: 'Legal service at...', Price: '$456.78', BoughtOn: '12/10/2022' },
    { id: 13, BuyerName: 'Francis Mitcham', Email: 'f.mit@kpmg.com', Phone: '6789012345', LastPurchased: 'Snow removal for...', Price: '$567.89', BoughtOn: '01/15/2023' },
    { id: 14, BuyerName: 'Olivia Wild', Email: 'olivia@corpmail.com', Phone: '4321098765', LastPurchased: 'House Cleaning...', Price: '$678.90', BoughtOn: '02/20/2023' }
]

const BuyersList = () => {

    const [data, setData] = useState(initialData);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed


    // Reset current page to 1 when searchQuery or statusFilter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Filter data based on search query for all columns
    const filteredData = data.filter(item => {
        const itemString = Object.values(item).join(' ').toLowerCase();
        const matchesSearch = itemString.includes(searchQuery.toLowerCase());
        return matchesSearch;
    });

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span key="ellipsis">...</span>;

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'

                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    disabled={currentPage === 1}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-outline-primary'

                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(ellipsis);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-primary'

                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    // Function to get the color based on status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return 'badge badge-light-primary';
            case 'Inactive':
                return 'badge badge-light-danger';
            case 'Sold':
                return 'badge badge-light-warning';
            default:
                return 'black';
        }
    };

    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <a href="../../index.html" className="text-gray-600 text-hover-primary">
                                    <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </a>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Apps</li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Buyers</li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-500">Buyer listing</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Buyer list</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}

                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Card-->*/}
                    <div className="card">
                        {/*<!--begin::Card header-->*/}
                        <div className="card-header border-0 pt-6">
                            {/*<!--begin::Card title-->*/}
                            <div className="card-title">
                                {/*<!--begin::Search-->*/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search"
                                        className="form-control form-control-solid w-250px ps-12"
                                        placeholder="Search Subcategories"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                </div>
                                {/*<!--end::Search-->*/}
                            </div>
                            {/*<!--begin::Card title-->*/}
                            {/*<!--begin::Card toolbar-->*/}
                            <div className="card-toolbar">
                                {/*<!--begin::Toolbar-->*/}
                                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                    {/*<!--begin::Filter-->*/}
                                    <button type="button" className="btn btn-light-primary me-3"
                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i className="ki-duotone ki-filter fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>Filter</button>
                                    {/*<!--begin::Menu 1-->*/}
                                    <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                        data-kt-menu="true" id="kt-toolbar-filter">
                                        {/*<!--begin::Header-->*/}
                                        <div className="px-7 py-5">
                                            <div className="fs-4 text-gray-900 fw-bold">Filter Options</div>
                                        </div>
                                        {/*<!--end::Header-->*/}
                                        {/*<!--begin::Separator-->*/}
                                        <div className="separator border-gray-200"></div>
                                        {/*<!--end::Separator-->*/}
                                        {/*<!--begin::Content-->*/}
                                        <div className="px-7 py-5">
                                            {/*<!--begin::Input group-->*/}
                                            <div className="mb-10">
                                                {/*<!--begin::Label-->*/}
                                                <label className="form-label fs-5 fw-semibold mb-3">Month:</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input-->*/}
                                                <select className="form-select form-select-solid fw-bold"
                                                    data-kt-select2="true" data-placeholder="Select option"
                                                    data-allow-clear="true" data-kt-customer-table-filter="month"
                                                    data-dropdown-parent="#kt-toolbar-filter">
                                                    <option></option>
                                                    <option value="aug">August</option>
                                                    <option value="sep">September</option>
                                                    <option value="oct">October</option>
                                                    <option value="nov">November</option>
                                                    <option value="dec">December</option>
                                                </select>
                                                {/*<!--end::Input-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Input group-->*/}
                                            <div className="mb-10">
                                                {/*<!--begin::Label-->*/}
                                                <label className="form-label fs-5 fw-semibold mb-3">Payment
                                                    Type:</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Options-->*/}
                                                <div className="d-flex flex-column flex-wrap fw-semibold"
                                                    data-kt-customer-table-filter="payment_type">
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="all" checked="checked" />
                                                        <span className="form-check-label text-gray-600">All</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="visa" />
                                                        <span className="form-check-label text-gray-600">Visa</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="mastercard" />
                                                        <span
                                                            className="form-check-label text-gray-600">Mastercard</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                    {/*<!--begin::Option-->*/}
                                                    <label
                                                        className="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio"
                                                            name="payment_type" value="american_express" />
                                                        <span className="form-check-label text-gray-600">American
                                                            Express</span>
                                                    </label>
                                                    {/*<!--end::Option-->*/}
                                                </div>
                                                {/*<!--end::Options-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Actions-->*/}
                                            <div className="d-flex justify-content-end">
                                                <button type="reset"
                                                    className="btn btn-light btn-active-light-primary me-2"
                                                    data-kt-menu-dismiss="true"
                                                    data-kt-customer-table-filter="reset">Reset</button>
                                                <button type="submit" className="btn btn-primary"
                                                    data-kt-menu-dismiss="true"
                                                    data-kt-customer-table-filter="filter">Apply</button>
                                            </div>
                                            {/*<!--end::Actions-->*/}
                                        </div>
                                        {/*<!--end::Content-->*/}
                                    </div>
                                    {/*<!--end::Menu 1-->*/}
                                    {/*<!--end::Filter-->*/}
                                    {/*<!--begin::Export-->*/}
                                    <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal"
                                        data-bs-target="#kt_customers_export_modal">
                                        <i className="ki-duotone ki-exit-up fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>Export</button>
                                    {/*<!--end::Export-->*/}
                                    {/* <div className="dropdown">
                                        <button type="button" className="btn btn-primary dropdown-toggle m-0 no-caret" data-bs-toggle="dropdown">
                                            <i className="bi bi-person-lines-fill fs-1"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Contact Adminstrator</a></li>
                                            <li><a className="dropdown-item" href="#">Link 2</a></li>
                                            <li><a className="dropdown-item" href="#">Link 3</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                                {/*<!--end::Toolbar-->*/}
                                {/*<!--begin::Group actions-->*/}
                                <div className="d-flex justify-content-end align-items-center d-none"
                                    data-kt-customer-table-toolbar="selected">
                                    <div className="fw-bold me-5">
                                        <span className="me-2"
                                            data-kt-customer-table-select="selected_count"></span>Selected
                                    </div>
                                    <button type="button" className="btn btn-danger"
                                        data-kt-customer-table-select="delete_selected">Delete Selected</button>
                                </div>
                                {/*<!--end::Group actions-->*/}
                            </div>
                            {/*<!--end::Card toolbar-->*/}
                        </div>
                        {/*<!--end::Card header-->*/}
                        {/*<!--begin::Card body-->*/}
                        <div className="card-body pt-0">
                            {/*<!--begin::Table-->*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                                <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="text-center">
                                            Id
                                        </th>
                                        <th className="text-center">Buyer Name</th>
                                        <th className="text-center">Email</th>
                                        <th className="text-center">Phone</th>
                                        <th className="text-center">Last Purchased</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">Bought On</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {currentData.map(item => (
                                        <tr key={item.id}>
                                            <td className='text-center'>{item.id}</td>
                                            <td className=''>
                                                <Link to='/buyerdetails' className='text-muted'> {item.BuyerName}
                                                </Link>
                                            </td>
                                            <td className='text-center'>{item.Email}</td>
                                            <td>{item.Phone}</td>
                                            <td className='text-center'>
                                                {item.LastPurchased}
                                            </td>
                                            <td>{item.Price}</td>
                                            <td>{item.BoughtOn}</td>

                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">View</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}




                                </tbody>
                            </table>
                            <div style={{ marginTop: '10px' }} className='float-end'>
                                <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                {renderPageNumbers()}
                                <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    Next
                                </button>
                            </div>
                            {/*<!--end::Table-->*/}
                        </div>
                        {/*<!--end::Card body-->*/}
                    </div>
                    {/*<!--end::Card-->*/}
                    {/*<!--begin::Modals-->*/}
                    {/*<!--begin::Modal - Buyers - Add-->*/}
                    <div className="modal fade" id="kt_modal_add_customer" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div className="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div className="modal-content">
                                {/*<!--begin::Form-->*/}
                                <form className="form" action="#" id="kt_modal_add_customer_form"
                                    data-kt-redirect="apps/customers/list.html">
                                    {/*<!--begin::Modal header-->*/}
                                    <div className="modal-header" id="kt_modal_add_customer_header">
                                        {/*<!--begin::Modal title-->*/}
                                        <h2 className="fw-bold">Add a Buyer</h2>
                                        {/*<!--end::Modal title-->*/}
                                        {/*<!--begin::Close-->*/}
                                        <div id="kt_modal_add_customer_close"
                                            className="btn btn-icon btn-sm btn-active-icon-primary">
                                            <i className="ki-duotone ki-cross fs-1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                        </div>
                                        {/*<!--end::Close-->*/}
                                    </div>
                                    {/*<!--end::Modal header-->*/}
                                    {/*<!--begin::Modal body-->*/}
                                    <div className="modal-body py-10 px-lg-17">
                                        {/*<!--begin::Scroll-->*/}
                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll"
                                            data-kt-scroll="true"
                                            data-kt-scroll-activate="{default: false, lg: true}"
                                            data-kt-scroll-max-height="auto"
                                            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                            data-kt-scroll-offset="300px">
                                            {/*<!--begin::Input group-->*/}
                                            <div className="fv-row mb-7">
                                                {/*<!--begin::Label-->*/}
                                                <label className="required fs-6 fw-semibold mb-2">Name</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input-->*/}
                                                <input type="text" className="form-control form-control-solid"
                                                    placeholder="" name="name" value="Sean Bean" />
                                                {/*<!--end::Input-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Input group-->*/}
                                            <div className="fv-row mb-7">
                                                {/*<!--begin::Label-->*/}
                                                <label className="fs-6 fw-semibold mb-2">
                                                    <span className="required">Email</span>
                                                    <span className="ms-1" data-bs-toggle="tooltip"
                                                        title="Email address must be active">
                                                        <i className="ki-duotone ki-information fs-7">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                        </i>
                                                    </span>
                                                </label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input-->*/}
                                                <input type="email" className="form-control form-control-solid"
                                                    placeholder="" name="email" value="sean@dellito.com" />
                                                {/*<!--end::Input-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Input group-->*/}
                                            <div className="fv-row mb-15">
                                                {/*<!--begin::Label-->*/}
                                                <label className="fs-6 fw-semibold mb-2">Description</label>
                                                {/*<!--end::Label-->*/}
                                                {/*<!--begin::Input-->*/}
                                                <input type="text" className="form-control form-control-solid"
                                                    placeholder="" name="description" />
                                                {/*<!--end::Input-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                            {/*<!--begin::Billing toggle-->*/}
                                            <div className="fw-bold fs-3 rotate collapsible mb-7"
                                                data-bs-toggle="collapse" href="#kt_modal_add_customer_billing_info"
                                                role="button" aria-expanded="false"
                                                aria-controls="kt_customer_view_details">Shipping Information
                                                <span className="ms-2 rotate-180">
                                                    <i className="ki-duotone ki-down fs-3"></i>
                                                </span>
                                            </div>
                                            {/*<!--end::Billing toggle-->*/}
                                            {/*<!--begin::Billing form-->*/}
                                            <div id="kt_modal_add_customer_billing_info" className="collapse show">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="required fs-6 fw-semibold mb-2">Address Line
                                                        1</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input className="form-control form-control-solid" placeholder=""
                                                        name="address1" value="101, Collins Street" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="fs-6 fw-semibold mb-2">Address Line 2</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input className="form-control form-control-solid" placeholder=""
                                                        name="address2" value="" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="required fs-6 fw-semibold mb-2">Town</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input className="form-control form-control-solid" placeholder=""
                                                        name="city" value="Melbourne" />
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="row g-9 mb-7">
                                                    {/*<!--begin::Col-->*/}
                                                    <div className="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required fs-6 fw-semibold mb-2">State /
                                                            Province</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-control form-control-solid"
                                                            placeholder="" name="state" value="Victoria" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                    {/*<!--begin::Col-->*/}
                                                    <div className="col-md-6 fv-row">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required fs-6 fw-semibold mb-2">Post
                                                            Code</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input className="form-control form-control-solid"
                                                            placeholder="" name="postcode" value="3000" />
                                                        {/*<!--end::Input-->*/}
                                                    </div>
                                                    {/*<!--end::Col-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-flex flex-column mb-7 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="fs-6 fw-semibold mb-2">
                                                        <span className="required">Country</span>
                                                        <span className="ms-1" data-bs-toggle="tooltip"
                                                            title="Country of origination">
                                                            <i className="ki-duotone ki-information fs-7">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span>
                                                    </label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <select name="country" aria-label="Select a Country"
                                                        data-control="select2"
                                                        data-placeholder="Select a Country..."
                                                        data-dropdown-parent="#kt_modal_add_customer"
                                                        className="form-select form-select-solid fw-bold">
                                                        <option value="">Select a Country...</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Aland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia, Plurinational State of</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">British Indian Ocean Territory</option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoros</option>
                                                        <option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Côte d'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curaçao</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">Korea, Democratic People's Republic of
                                                        </option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesia, Federated States of</option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthélemy</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin (French part)</option>
                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="KR">South Korea</option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US" selected="selected">United States
                                                        </option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela, Bolivarian Republic of
                                                        </option>
                                                        <option value="VN">Vietnam</option>
                                                        <option value="VI">Virgin Islands</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                    {/*<!--end::Input-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row mb-7">
                                                    {/*<!--begin::Wrapper-->*/}
                                                    <div className="d-flex flex-stack">
                                                        {/*<!--begin::Label-->*/}
                                                        <div className="me-5">
                                                            {/*<!--begin::Label-->*/}
                                                            <label className="fs-6 fw-semibold">Use as a billing
                                                                adderess?</label>
                                                            {/*<!--end::Label-->*/}
                                                            {/*<!--begin::Input-->*/}
                                                            <div className="fs-7 fw-semibold text-muted">If you need
                                                                more info, please check budget planning</div>
                                                            {/*<!--end::Input-->*/}
                                                        </div>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Switch-->*/}
                                                        <label
                                                            className="form-check form-switch form-check-custom form-check-solid">
                                                            {/*<!--begin::Input-->*/}
                                                            <input className="form-check-input" name="billing"
                                                                type="checkbox" value="1"
                                                                id="kt_modal_add_customer_billing"
                                                                checked="checked" />
                                                            {/*<!--end::Input-->*/}
                                                            {/*<!--begin::Label-->*/}
                                                            <span className="form-check-label fw-semibold text-muted"
                                                                for="kt_modal_add_customer_billing">Yes</span>
                                                            {/*<!--end::Label-->*/}
                                                        </label>
                                                        {/*<!--end::Switch-->*/}
                                                    </div>
                                                    {/*<!--begin::Wrapper-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Billing form-->*/}
                                        </div>
                                        {/*<!--end::Scroll-->*/}
                                    </div>
                                    {/*<!--end::Modal body-->*/}
                                    {/*<!--begin::Modal footer-->*/}
                                    <div className="modal-footer flex-center">
                                        {/*<!--begin::Button-->*/}
                                        <button type="reset" id="kt_modal_add_customer_cancel"
                                            className="btn btn-light me-3">Discard</button>
                                        {/*<!--end::Button-->*/}
                                        {/*<!--begin::Button-->*/}
                                        <button type="submit" id="kt_modal_add_customer_submit"
                                            className="btn btn-primary">
                                            <span className="indicator-label">Submit</span>
                                            <span className="indicator-progress">Please wait...
                                                <span
                                                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                        {/*<!--end::Button-->*/}
                                    </div>
                                    {/*<!--end::Modal footer-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                        </div>
                    </div>
                    {/*<!--end::Modal - Buyers - Add-->*/}
                    {/*<!--begin::Modal - Adjust Balance-->*/}
                    <div className="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
                        {/*<!--begin::Modal dialog-->*/}
                        <div className="modal-dialog modal-dialog-centered mw-650px">
                            {/*<!--begin::Modal content-->*/}
                            <div className="modal-content">
                                {/*<!--begin::Modal header-->*/}
                                <div className="modal-header">
                                    {/*<!--begin::Modal title-->*/}
                                    <h2 className="fw-bold">Export Buyers</h2>
                                    {/*<!--end::Modal title-->*/}
                                    {/*<!--begin::Close-->*/}
                                    <div id="kt_customers_export_close"
                                        className="btn btn-icon btn-sm btn-active-icon-primary">
                                        <i className="ki-duotone ki-cross fs-1">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </div>
                                    {/*<!--end::Close-->*/}
                                </div>
                                {/*<!--end::Modal header-->*/}
                                {/*<!--begin::Modal body-->*/}
                                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                    {/*<!--begin::Form-->*/}
                                    <form id="kt_customers_export_form" className="form" action="#">
                                        {/*<!--begin::Input group-->*/}
                                        <div className="fv-row mb-10">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Select Export
                                                Format:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <select data-control="select2" data-placeholder="Select a format"
                                                data-hide-search="true" name="format"
                                                className="form-select form-select-solid">
                                                <option value="excell">Excel</option>
                                                <option value="pdf">PDF</option>
                                                <option value="cvs">CVS</option>
                                                <option value="zip">ZIP</option>
                                            </select>
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Input group-->*/}
                                        <div className="fv-row mb-10">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Select Date
                                                Range:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Input-->*/}
                                            <input className="form-control form-control-solid" placeholder="Pick a date"
                                                name="date" />
                                            {/*<!--end::Input-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                        {/*<!--begin::Row-->*/}
                                        <div className="row fv-row mb-15">
                                            {/*<!--begin::Label-->*/}
                                            <label className="fs-5 fw-semibold form-label mb-5">Payment Type:</label>
                                            {/*<!--end::Label-->*/}
                                            {/*<!--begin::Radio group-->*/}
                                            <div className="d-flex flex-column">
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="1"
                                                        checked="checked" name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">All</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="2"
                                                        checked="checked" name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">Visa</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                    <input className="form-check-input" type="checkbox" value="3"
                                                        name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                                {/*<!--begin::Radio button-->*/}
                                                <label
                                                    className="form-check form-check-custom form-check-sm form-check-solid">
                                                    <input className="form-check-input" type="checkbox" value="4"
                                                        name="payment_type" />
                                                    <span
                                                        className="form-check-label text-gray-600 fw-semibold">American
                                                        Express</span>
                                                </label>
                                                {/*<!--end::Radio button-->*/}
                                            </div>
                                            {/*<!--end::Input group-->*/}
                                        </div>
                                        {/*<!--end::Row-->*/}
                                        {/*<!--begin::Actions-->*/}
                                        <div className="text-center">
                                            <button type="reset" id="kt_customers_export_cancel"
                                                className="btn btn-light me-3">Discard</button>
                                            <button type="submit" id="kt_customers_export_submit"
                                                className="btn btn-primary">
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        </div>
                                        {/*<!--end::Actions-->*/}
                                    </form>
                                    {/*<!--end::Form-->*/}
                                </div>
                                {/*<!--end::Modal body-->*/}
                            </div>
                            {/*<!--end::Modal content-->*/}
                        </div>
                        {/*<!--end::Modal dialog-->*/}
                    </div>
                    {/*<!--end::Modal - New Card-->*/}
                    {/*<!--end::Modals-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
        </Base>
    )
}

export default BuyersList