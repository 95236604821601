import React from 'react'
import { Button, Popover, Space } from 'antd';
import { Link } from 'react-router-dom';

// content for menu 
const homeContent = (
    <div className="row p-5">
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to='/categories' className="menu-link active">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-element-11 text-primary fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Categories</span>
                        <span className="fs-7 fw-semibold text-muted">list of categories</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to='/subcat' className="menu-link">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-basket text-danger fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Subcategories</span>
                        <span className="fs-7 fw-semibold text-muted">List of Subcategories</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to="/brands" className="menu-link">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-abstract-44 text-info fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Brands</span>
                        <span className="fs-7 fw-semibold text-muted">List Of Brands</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to='/sellers' className="menu-link">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-color-swatch text-success fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                            <span className="path9"></span>
                            <span className="path10"></span>
                            <span className="path11"></span>
                            <span className="path12"></span>
                            <span className="path13"></span>
                            <span className="path14"></span>
                            <span className="path15"></span>
                            <span className="path16"></span>
                            <span className="path17"></span>
                            <span className="path18"></span>
                            <span className="path19"></span>
                            <span className="path20"></span>
                            <span className="path21"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Sellers</span>
                        <span className="fs-7 fw-semibold text-muted">List Of Sellers</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to="/buyers" className="menu-link">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-chart-simple text-gray-900 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Buyers</span>
                        <span className="fs-7 fw-semibold text-muted">List Of Buyers</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}
        {/* <!--begin:Col--> */}
        <div className="col-lg-6 mb-3">
            {/* <!--begin:Menu item--> */}
            <div className="menu-item p-0 m-0">
                {/* <!--begin:Menu link--> */}
                <Link to='/listings' className="menu-link">
                    <span
                        className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-switch text-warning fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </span>
                    <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">Listings</span>
                        <span className="fs-7 fw-semibold text-muted">List Of Ads</span>
                    </span>
                </Link>
                {/* <!--end:Menu link--> */}
            </div>
            {/* <!--end:Menu item--> */}
        </div>
        {/* <!--end:Col--> */}

    </div>
);

const profile = (
    <div className="rounded  fw-semibold py-4 fs-6 w-275px ">
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
                {/* <!--begin::Avatar--> */}
                <div className="symbol symbol-50px me-5">
                    <img alt="Logo" src="assets/media/avatars/300-3.jpg" />
                </div>
                {/* <!--end::Avatar--> */}
                {/* <!--begin::Username--> */}
                <div className="d-flex flex-column">
                    <div className="fw-bold d-flex align-items-center fs-5">Max Smith
                        <span
                            className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span>
                    </div>
                    <a href="#"
                        className="fw-semibold text-muted text-hover-primary fs-7">max@kt.com</a>
                </div>
                {/* <!--end::Username--> */}
            </div>
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu separator--> */}
        <div className="separator my-2"></div>
        {/* <!--end::Menu separator--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5">
            <a href="account/overview.html" className="menu-link px-5 text-dark text-hover-primary">My Profile</a>
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5">
            <a href="apps/projects/list.html" className="menu-link px-5">
                <span className="menu-text  text-dark text-hover-primary">My Projects</span>
                <span className="menu-badge">
                    <span
                        className="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
                </span>
            </a>
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
            <a href="#" className="menu-link px-5">
                <span className="menu-title  text-dark text-hover-primary">My Subscription</span>
                <span className="menu-arrow"></span>
            </a>
            {/* <!--begin::Menu sub--> */}
            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/referrals.html" className="menu-link px-5">Referrals</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/billing.html" className="menu-link px-5">Billing</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/statements.html" className="menu-link px-5">Payments</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/statements.html"
                        className="menu-link d-flex flex-stack px-5">Statements
                        <span className="ms-2 lh-0" data-bs-toggle="tooltip"
                            title="View your statements">
                            <i className="ki-duotone ki-information-5 fs-5">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span></a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu separator--> */}
                <div className="separator my-2"></div>
                {/* <!--end::Menu separator--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <div className="menu-content px-3">
                        <label
                            className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-30px h-20px" type="checkbox"
                                value="1" name="notifications" />
                            <span
                                className="form-check-label text-muted fs-7">Notifications</span>
                        </label>
                    </div>
                </div>
                {/* <!--end::Menu item--> */}
            </div>
            {/* <!--end::Menu sub--> */}
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5">
            <a href="account/statements.html" className="menu-link px-5  text-dark text-hover-primary">My Statements</a>
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu separator--> */}
        <div className="separator my-2"></div>
        {/* <!--end::Menu separator--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
            <a href="#" className="menu-link px-5">
                <span className="menu-title position-relative  text-dark text-hover-primary">Mode
                    <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                        <i className="ki-duotone ki-night-day theme-light-show fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                            <span className="path9"></span>
                            <span className="path10"></span>
                        </i>
                        <i className="ki-duotone ki-moon theme-dark-show fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </span></span>
            </a>
            {/* <!--begin::Menu--> */}
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                data-kt-menu="true" data-kt-element="theme-mode-menu">
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3 my-0">
                    <a href="#" className="menu-link px-3 py-2" data-kt-element="mode"
                        data-kt-value="light">
                        <span className="menu-icon" data-kt-element="icon">
                            <i className="ki-duotone ki-night-day fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                                <span className="path7"></span>
                                <span className="path8"></span>
                                <span className="path9"></span>
                                <span className="path10"></span>
                            </i>
                        </span>
                        <span className="menu-title">Light</span>
                    </a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3 my-0">
                    <a href="#" className="menu-link px-3 py-2" data-kt-element="mode"
                        data-kt-value="dark">
                        <span className="menu-icon" data-kt-element="icon">
                            <i className="ki-duotone ki-moon fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                        </span>
                        <span className="menu-title">Dark</span>
                    </a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3 my-0">
                    <a href="#" className="menu-link px-3 py-2" data-kt-element="mode"
                        data-kt-value="system">
                        <span className="menu-icon" data-kt-element="icon">
                            <i className="ki-duotone ki-screen fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                            </i>
                        </span>
                        <span className="menu-title">System</span>
                    </a>
                </div>
                {/* <!--end::Menu item--> */}
            </div>
            {/* <!--end::Menu--> */}
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
            <a href="#" className="menu-link px-5">
                <span className="menu-title position-relative  text-dark text-hover-primary">Language
                    <span
                        className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                        <img className="w-15px h-15px rounded-1 ms-2"
                            src="assets/media/flags/united-states.svg"
                            alt="" /></span></span>
            </a>
            {/* <!--begin::Menu sub--> */}
            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/settings.html" className="menu-link d-flex px-5 active">
                        <span className="symbol symbol-20px me-4">
                            <img className="rounded-1"
                                src="assets/media/flags/united-states.svg" alt="" />
                        </span>English</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/settings.html" className="menu-link d-flex px-5">
                        <span className="symbol symbol-20px me-4">
                            <img className="rounded-1" src="assets/media/flags/spain.svg"
                                alt="" />
                        </span>Spanish</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/settings.html" className="menu-link d-flex px-5">
                        <span className="symbol symbol-20px me-4">
                            <img className="rounded-1" src="assets/media/flags/germany.svg"
                                alt="" />
                        </span>German</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/settings.html" className="menu-link d-flex px-5">
                        <span className="symbol symbol-20px me-4">
                            <img className="rounded-1" src="assets/media/flags/japan.svg"
                                alt="" />
                        </span>Japanese</a>
                </div>
                {/* <!--end::Menu item--> */}
                {/* <!--begin::Menu item--> */}
                <div className="menu-item px-3">
                    <a href="account/settings.html" className="menu-link d-flex px-5">
                        <span className="symbol symbol-20px me-4">
                            <img className="rounded-1" src="assets/media/flags/france.svg"
                                alt="" />
                        </span>French</a>
                </div>
                {/* <!--end::Menu item--> */}
            </div>
            {/* <!--end::Menu sub--> */}
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5 my-1">
            <a href="account/settings.html" className="menu-link px-5  text-dark text-hover-primary">Account Settings</a>
        </div>
        {/* <!--end::Menu item--> */}
        {/* <!--begin::Menu item--> */}
        <div className="menu-item px-5">
            <a href="authentication/layouts/corporate/sign-in.html"
                className="menu-link px-5  text-dark text-hover-primary">Sign Out</a>
        </div>
        {/* <!--end::Menu item--> */}
    </div>
)

const Base = ({ children }) => {
    return (
        <div className="d-flex flex-column flex-root">
            {/* <!--begin::Page--> */}
            <div className="page d-flex flex-row flex-column-fluid">
                {/* <!--begin::Wrapper--> */}
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    {/* <!--begin::Header--> */}
                    <div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header"
                        data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                        {/* <!--begin::Container--> */}
                        <div className="container-xxl d-flex flex-grow-1 flex-stack">
                            {/* <!--begin::Header Logo--> */}
                            <div className="d-flex align-items-center me-5">
                                {/* <!--begin::Heaeder menu toggle--> */}
                                <div className="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3"
                                    id="kt_header_menu_toggle">
                                    <i className="ki-duotone ki-abstract-14 fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                                {/* <!--end::Heaeder menu toggle--> */}
                                {/* <!--begin::Logo--> */}
                                <Link to='/'>
                                    <img alt="Logo" src="assets/media/logos/demo16.svg"
                                        className="h-25px h-lg-30px me-2 me-lg-9" />
                                </Link>
                                {/* <!--end::Logo--> */}
                                {/* <!--begin::Project--> */}
                                <Link>
                                    <div className="d-flex align-items-center">
                                        {/* <!--begin::Title--> */}
                                        <div className="d-none d-lg-inline text-white fw-bold fs-4">Suntek</div>
                                        {/* <!--end::Title--> */}
                                    </div>
                                </Link>
                                {/* <!--end::Project--> */}
                            </div>
                            {/* <!--end::Header Logo--> */}
                            {/* <!--begin::Toolbar wrapper--> */}
                            <div className="topbar d-flex align-items-stretch flex-shrink-0" id="kt_topbar">
                                {/* <!--begin::Activities--> */}
                                <div className="d-flex align-items-center ms-2 ms-lg-4">
                                    {/* <!--begin::Drawer toggle--> */}
                                    <div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-warning"
                                        id="kt_activities_toggle">
                                        <i className="ki-duotone ki-chart-line fs-1">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </div>
                                    {/* <!--end::Drawer toggle--> */}
                                </div>
                                {/* <!--end::Activities--> */}
                                {/* <!--begin::Chat--> */}
                                <div className="d-flex align-items-center ms-2 ms-lg-4">
                                    {/* <!--begin::Menu wrapper--> */}
                                    <div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative"
                                        id="kt_drawer_chat_toggle">
                                        <i className="ki-duotone ki-message-text fs-1">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                        </i>
                                        <span
                                            className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle ms-7 mb-3 animation-blink"></span>
                                    </div>
                                    {/* <!--end::Menu wrapper--> */}
                                </div>
                                {/* <!--end::Chat--> */}

                                {/* <!--begin::User menu--> */}
                                {/* <div className="d-flex align-items-center ms-2 ms-lg-4" id="kt_header_user_menu_toggle">
                                    <div className="cursor-pointer symbol symbol-30px symbol-lg-40px"
                                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                                        data-kt-menu-placement="bottom-end">
                                        <img className="symbol symbol-30px symbol-lg-40px" src="assets/media/avatars/300-3.jpg"
                                            alt="user" />
                                    </div>
                                </div> */}
                                <Popover
                                    
                                    placement="bottomLeft"
                                    content={profile}
                                    trigger="hover"
                                    arrow={false}
                                    align={{ offset: [0, 20] }}
                                    overlayStyle={{ width: 275 }} >
                                    <span className="menu-link py-3">

                                        <span className="menu-title">
                                            <img src="assets/media/avatars/300-3.jpg" className='rounded mx-2'
                                                alt="user" style={{width:"40px"}} />
                                        </span>
                                    </span>
                                </Popover>
                                {/* <!--end::User menu--> */}
                            </div>
                            {/* <!--end::Toolbar wrapper--> */}
                        </div>
                        {/* <!--end::Container--> */}
                        {/* <!--begin::Container--> */}
                        <div className="header-menu-container d-flex align-items-stretch flex-stack h-lg-75px w-100" id="kt_header_nav">
                            {/* <!--begin::Menu wrapper--> */}
                            <div className="header-menu container-xxl flex-column align-items-stretch flex-lg-row" data-kt-drawer="true"
                                data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_toggle" data-kt-swapper="true"
                                data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                                {/* <!--begin::Menu--> */}
                                <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-500 fw-semibold my-5 my-lg-0 align-items-stretch flex-grow-1 px-2 px-lg-0"
                                    id="#kt_header_menu" data-kt-menu="true">
                                    {/* <!--begin:Menu item--> */}
                                    <div data-kt-menu-placement="bottom-start"
                                        className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                        {/* <!--begin:Menu link--> */}
                                        <Popover placement="bottomLeft" content={homeContent} trigger="hover" arrow={false} align={{ offset: [0, 20] }} overlayStyle={{ width: 600 }} >
                                            <span className="menu-link py-3">

                                                <span className="menu-title">Home</span>
                                            </span>
                                        </Popover>

                                        {/* <!--end:Menu link--> */}

                                    </div>
                                    {/* <!--end:Menu item--> */}
                                    {/* <!--begin:Menu item--> */}
                                    <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
                                        className="menu-item menu-lg-down-accordion me-0 me-lg-2">
                                        {/* <!--begin:Menu link--> */}
                                        <span className="menu-link py-3">
                                            <span className="menu-title">Pages</span>
                                            <span className="menu-arrow d-lg-none"></span>
                                        </span>
                                        {/* <!--end:Menu link--> */}

                                    </div>
                                    {/* <!--end:Menu item--> */}
                                    {/* <!--begin:Menu item--> */}
                                    <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
                                        className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
                                        {/* <!--begin:Menu link--> */}
                                        <span className="menu-link py-3">
                                            <span className="menu-title">Apps</span>
                                            <span className="menu-arrow d-lg-none"></span>
                                        </span>
                                        {/* <!--end:Menu link--> */}

                                    </div>
                                    {/* <!--end:Menu item--> */}
                                </div>
                                {/* <!--end::Menu--> */}
                                {/* <!--begin::Actions--> */}



                            </div>
                            {/* <!--end::Menu wrapper--> */}
                        </div>


                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Header--> */}


                    {/* <!--end::Container--> */}

                </div>
                {/* <!--end::Wrapper--> */}
            </div>
            {/* <!--end::Page--> */}
            {children}

            {/*<!--begin::Footer-->*/}
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                {/*<!--begin::Container-->*/}
                <div
                    className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/*<!--begin::Copyright-->*/}
                    <div className="text-gray-900 order-2 order-md-1">
                        <span className="text-muted fw-semibold me-1">2024&copy;</span>
                        <a href="http://suntek.perennialcode.com/" target="_blank"
                            className="text-gray-800 text-hover-primary">Suntek</a>
                    </div>
                    {/*<!--end::Copyright-->*/}
                    {/*<!--begin::Menu-->*/}
                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                        <li className="menu-item">
                            <a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
                        </li>
                        <li className="menu-item">
                            <a href="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</a>
                        </li>
                        <li className="menu-item">
                            <a href="https://1.envato.market/EA4JP" target="_blank"
                                className="menu-link px-2">Purchase</a>
                        </li>
                    </ul>
                    {/*<!--end::Menu-->*/}
                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Footer-->*/}
        </div>

    )
}

export default Base