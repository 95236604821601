import React, { useState, useEffect } from 'react'
import { Select, Space } from 'antd';

import Base from '../../Components/Base/Base'
import img01 from '../../custom assets/images/avatars/300-1.jpg'
import img02 from '../../custom assets/images/avatars/300-2.jpg'
import img03 from '../../custom assets/images/avatars/300-3.jpg'
import img04 from '../../custom assets/images/avatars/300-4.jpg'
import img05 from '../../custom assets/images/avatars/300-5.jpg'
import img06 from '../../custom assets/images/avatars/300-6.jpg'
import img07 from '../../custom assets/images/avatars/300-7.jpg'
import img08 from '../../custom assets/images/avatars/300-8.jpg'
import img09 from '../../custom assets/images/avatars/300-9.jpg'
import img10 from '../../custom assets/images/avatars/300-10.jpg'
import img11 from '../../custom assets/images/avatars/300-11.jpg'
import img12 from '../../custom assets/images/avatars/300-12.jpg'
import { Link } from 'react-router-dom';
const initialData = [
    { id: 1, Seller: [img01, 'Ethan Wilder'], Status: 'Active', Category: 'Furniture', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 2, Seller: [img02, 'Sean Bean'], Status: 'Active', Category: 'Jobs', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },
    { id: 3, Seller: [img03, 'Lucy Kunic'], Status: 'Inactive', Category: 'Education	', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 4, Seller: [img04, 'Dan Wilson'], Status: 'Sold', Category: 'Plumbing	', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },
    { id: 5, Seller: [img05, 'Emma Smithr'], Status: 'Active', Category: 'Furniture', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 6, Seller: [img06, 'Brian Cox'], Status: 'Inactive', Category: 'Cleaning	', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },
    { id: 7, Seller: [img07, 'Francis Mitcham'], Status: 'Inactive', Category: 'Furniture', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 8, Seller: [img08, 'Ana Crown'], Status: 'Sold', Category: 'Furniture', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },
    { id: 9, Seller: [img09, 'Emma Smith'], Status: 'Active', Category: 'Legal', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 10, Seller: [img10, 'Olivia Wild'], Status: 'Inactive', Category: 'Realestate	', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },
    { id: 11, Seller: [img11, 'Lucy Kunic'], Status: 'Sold', Category: 'Furniture', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'act' },
    { id: 12, Seller: [img12, 'Francis Mitcham'], Status: 'Active', Category: 'Commercial', Price: '$296.00', Date_Added: '03/16/2024', Date_Modified: '03/23/2024', Actions: 'inactact' },

    // Add more data as needed
];

const AdListings = () => {
    const [data, setData] = useState(initialData);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed

    // Reset current page to 1 when searchQuery or statusFilter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery, statusFilter]);

    // Filter data based on search query for all columns
    const filteredData = data.filter(item => {
        const itemString = Object.values(item).join(' ').toLowerCase();
        const matchesSearch = itemString.includes(searchQuery.toLowerCase());
        const matchesStatus = statusFilter === 'All' || item.Status === statusFilter;
        return matchesSearch && matchesStatus;
    });

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span key="ellipsis">...</span>;

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'

                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    disabled={currentPage === 1}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-outline-primary'

                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(ellipsis);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-primary'

                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    // Function to get the color based on status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return 'badge badge-light-primary';
            case 'Inactive':
                return 'badge badge-light-danger';
            case 'Sold':
                return 'badge badge-light-warning';
            default:
                return 'black';
        }
    };
    const { Option } = Select;

    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <a href="../../index.html" className="text-gray-600 text-hover-primary">
                                    <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </a>
                            </li>
                            {/*<!--end::Item-->*/}


                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-500">Ads Listing</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Ads Listing</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}

                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Products-->*/}
                    <div className="card card-flush">
                        {/*<!--begin::Card header-->*/}
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            {/*<!--begin::Card title-->*/}
                            <div className="card-title">
                                {/*<!--begin::Search-->*/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search"
                                        className="form-control form-control-solid w-250px ps-12"
                                        placeholder="Search Ads"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                </div>
                                {/*<!--end::Search-->*/}
                            </div>
                            {/*<!--end::Card title-->*/}
                            {/*<!--begin::Card toolbar-->*/}
                            <div className="card-toolbar flex-row-fluid justify-content-end">
                                <div className=" d-flex me-4">
                                    {/* <label className='text-gray-500 fs-4 fw-semibold mx-3'>Status</label>
                                    <select className="form-select btn btn-outline-secondary btn-sm  mx-3" data-control="select2"
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Sold">Sold</option>
                                    </select> */}
                                    <label className='text-gray-500 fs-4 fw-semibold mx-3'>Status</label>
                                    <div>
                                        <Select className='rounded bg-danger'
                                            defaultValue={statusFilter}
                                            style={{
                                                width: 90,
                                            }}
                                            onChange={(value) => setStatusFilter(value)}
                                            options={[
                                                {
                                                    value: 'All',
                                                    label: 'All',
                                                },
                                                {
                                                    value: 'Active',
                                                    label: 'Active',
                                                },
                                                {
                                                    value: 'Inactive',
                                                    label: 'Inactive',
                                                },
                                                {
                                                    value: 'Sold',
                                                    label: 'Sold',
                                                },
                                            ]}
                                        />
                                    </div>
                                    {/*<!--end::Select2-->*/}
                                </div>
                            </div>
                            {/*<!--end::Card toolbar-->*/}
                        </div>
                        {/*<!--end::Card header-->*/}
                        {/*<!--begin::Card body-->*/}
                        <div className="card-body pt-0">
                            {/*<!--begin::Table-->*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-5"
                                id="kt_ecommerce_sales_table">
                                <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="text-center ">Listing ID</th>
                                        <th className="text-center ">Seller</th>
                                        <th className="text-center ">Status</th>
                                        <th className="text-center ">Category</th>
                                        <th className="text-center ">Price</th>
                                        <th className="text-center ">Date Added</th>
                                        <th className="text-center ">Date Modified</th>
                                        <th className="text-center ">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {currentData.map(item => (
                                        <tr key={item.id}>
                                            <td className='text-center'>{item.id}</td>
                                            <td className='row'>
                                                <div className='col-5 text-end'>
                                                    <img src={item.Seller[0]} width="40px" className='rounded-circle' />
                                                </div>
                                                <div className='col-7 text-start mt-2'>
                                                    {item.Seller[1]}
                                                </div>
                                            </td>
                                            <td><span className={getStatusColor(item.Status)}><b>{item.Status}</b></span></td>
                                            <td>{item.Category}</td>
                                            <td>{item.Price}</td>
                                            <td>{item.Date_Added}</td>
                                            <td>{item.Date_Modified}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        {/* <li><a className="dropdown-item" href="#">Edit</a></li> */}
                                                        <li><Link to="/addetails" className="dropdown-item" href="#">View</Link></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '10px' }} className='float-end'>
                                <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                {renderPageNumbers()}
                                <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    Next
                                </button>
                            </div>
                            {/*<!--end::Table-->*/}
                        </div>
                        {/*<!--end::Card body-->*/}
                    </div>
                    {/*<!--end::Products-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}

        </Base>
    )
}

export default AdListings