import React from 'react'
// import './pinlistAssets/Assets/css/style.css'
const Preview = () => {
    return (
        <div><section className="sptb">
            <div className="m-2">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="card overflow-hidden">
                            <div className="ribbon ribbon-top-right text-danger"><span className="bg-danger">featured</span></div>
                            <div className="card-body h-100 primo-slider">
                                <div className="item-det mb-4">
                                    <a href="#" className="text-dark">
                                        <h3 className="">Affordable 2 BHK Flats Available in Gated Community</h3>
                                    </a>
                                    <div className=" d-flex">
                                        <ul className="d-flex mb-0">
                                            <li className="me-5"><a href="#" className="icons"><i
                                                className="si si-briefcase text-muted me-1"></i> Real Estate</a></li>
                                            <li className="me-5"><a href="#" className="icons"><i
                                                className="si si-location-pin text-muted me-1"></i>USA</a></li>
                                            <li className="me-5"><a href="#" className="icons"><i
                                                className="si si-calendar text-muted me-1"></i> 5 hours ago</a></li>
                                            <li className="me-5"><a href="#" className="icons"><i
                                                className="si si-eye text-muted me-1"></i> 765</a></li>
                                        </ul>
                                        <div className="d-flex me-5">
                                            <div className="rating-star sm my-rating-5 me-2" data-stars="4s">
                                            </div>4.0
                                        </div>
                                        <div className="d-flex">
                                            <div className="me-2">
                                                <div className="">
                                                    <i className="fa fa-heart text-danger"></i>
                                                </div>
                                            </div> 135
                                        </div>
                                    </div>
                                </div>
                                <div className="product-slider carousel-slide-2">
                                    <div id="carouselFade" className="carousel slide carousel-fade" data-bs-ride="carousel"
                                        data-bs-loop="false" data-bs-thumb="true">
                                        <div className="arrow-ribbon2 bg-primary">$539</div>
                                        <div className="carousel-inner slide-show-image" id="full-gallery">
                                            <div className="carousel-item active"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h1.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h2.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h3.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h4.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h5.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h1.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h2.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h3.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h4.jpg"
                                                alt="img" /> </div>
                                            <div className="carousel-item"> <img
                                                src="./pinlistAssets/Assets/images/products/products/h5.jpg"
                                                alt="img" /> </div>

                                            <div className="thumbcarousel">
                                                <a className="carousel-control-prev" href="#carouselFade" role="button"
                                                    data-bs-slide="prev">
                                                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselFade" role="button"
                                                    data-bs-slide="next">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<!--Description-->*/}
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Description</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                                        voluptatum deleniti atcorrupti quos dolores et quas molestias excepturi sint
                                        occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                        mollitia animi, id est laborum et dolorum fuga.</p>
                                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so
                                        beguiled and demoraliz the charms of pleasure of the moment, so blinded by desire,
                                        that they cannot foresee the pain and trouble thena bound to ensue; and equal blame
                                        belongs to those who fail in their duty through weakness of will, which is the same
                                        as saying through shrinking from toil and pain.</p>
                                </div>
                                <h4 className="mb-4">Specifications</h4>
                                <div className="row">
                                    <div className="col-6">
                                        <ul className="list-unstyled widget-spec mb-0">
                                            <li className="">
                                                <i className="fa fa-bed text-muted w-5"></i> 2 BedRooms
                                            </li>
                                            <li className="">
                                                <i className="fa fa-bath text-muted w-5"></i> 2 BathRooms
                                            </li>
                                            <li className="">
                                                <i className="fa fa-life-ring text-muted w-5"></i> Unfurnished
                                            </li>
                                            <li className="">
                                                <i className="fa fa-car text-muted w-5"></i> 2 Car Parking
                                            </li>
                                            <li className="">
                                                <i className="fa fa-globe text-muted w-5"></i> East East face
                                            </li>
                                            <li className="mb-xl-0">
                                                <i className="fa fa-pagelines text-muted w-5"></i> Garden
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul className="list-unstyled widget-spec mb-0">
                                            <li className="">
                                                <i className="fa fa-lock text-muted w-5"></i> Security
                                            </li>
                                            <li className="">
                                                <i className="fa fa-building-o text-muted w-5"></i> Lift
                                            </li>
                                            <li className="">
                                                <i className="fa fa-check text-muted w-5"></i> Swimming fool
                                            </li>
                                            <li className="">
                                                <i className="fa fa-gamepad text-muted w-5"></i> Play Area
                                            </li>
                                            <li className="">
                                                <i className="fa fa-futbol-o text-muted w-5"></i> football Court
                                            </li>
                                            <li className="mb-0">
                                                <i className="fa fa-trophy text-muted w-5"></i> Cricket Court
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="card-body">
                                <div className="list-id">
                                    <div className="row">
                                        <div className="col">
                                            <a className="mb-0">Property ID : #6235986</a>
                                        </div>
                                        <div className="col col-auto">
                                            Posted By <a className="mb-0 font-weight-bold">Individual</a> / 21st Sep 2018
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="icons">
                                    <a href="#" className="btn btn-info icons"><i className="si si-share me-1"></i> Share Ad</a>
                                    <a href="#" className="btn btn-danger icons" data-bs-toggle="modal"
                                        data-bs-target="#report"><i className="si si-exclamation me-1"></i> Report Abuse</a>
                                    <a href="#" className="btn btn-primary icons"><i className="si si-heart  me-1"></i> 678</a>
                                    <a href="#" className="btn btn-secondary icons"><i className="si si-printer  me-1"></i>
                                        Print</a>
                                </div>
                            </div>
                        </div>
                        {/*<!--Description-->*/}




                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        {/*<!--Right Side Section-->*/}
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Posted By</h3>
                            </div>
                            <div className="card-body  item-user">
                                <div className="profile-pic mb-0">
                                    <img src="./pinlistAssets/Assets/images/faces/male/25.jpg"
                                        className="brround avatar-xxl" alt="user" />
                                    <div className="">
                                        <a href="userprofile.html" className="text-dark">
                                            <h4 className="mt-3 mb-1 font-weight-semibold">Robert McLean</h4>
                                        </a>
                                        <span className="text-gray">Property Holder</span><br />
                                        <span className="text-muted">Member Since November 2008</span>
                                        <h6 className="mt-2 mb-0"><a href="#" className="btn btn-primary btn-sm">See All Ads</a>
                                        </h6>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body item-user">
                                <h4 className="mb-4">Contact Info</h4>
                                <div>
                                    <h6><span className="font-weight-semibold"><i className="fa fa-envelope me-2 mb-2"></i></span><a
                                        href="#" className="text-body"> robert123@gmail.com</a></h6>
                                    <h6><span className="font-weight-semibold"><i className="fa fa-phone me-2  mb-2"></i></span><a
                                        href="#" className="text-primary"> 0-235-657-24587</a></h6>
                                    <h6><span className="font-weight-semibold"><i className="fa fa-link me-2 "></i></span><a
                                        href="#" className="text-primary">http://spruko.com/</a></h6>
                                </div>
                                <div className=" item-user-icons mt-4">
                                    <a href="#" className="facebook-bg mt-0"><i className="fa fa-facebook"></i></a>
                                    <a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a>
                                    <a href="#" className="google-bg"><i className="fa fa-google"></i></a>
                                    <a href="#" className="dribbble-bg"><i className="fa fa-dribbble"></i></a>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="text-start">
                                    <a href="#" className="btn  btn-info"><i className="fa fa-envelope"></i> Chat</a>
                                    <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#contact"><i
                                        className="fa fa-user"></i> Contact Me</a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Keywords</h3>
                            </div>
                            <div className="card-body product-filter-desc">
                                <div className="product-tags clearfix">
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#">House</a></li>
                                        <li><a href="#">Property</a></li>
                                        <li><a href="#">Land </a></li>
                                        <li><a href="#">Rent</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Shares</h3>
                            </div>
                            <div className="card-body product-filter-desc">
                                <div className="product-filter-icons text-center">
                                    <a href="#" className="facebook-bg"><i className="fa fa-facebook"></i></a>
                                    <a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a>
                                    <a href="#" className="google-bg"><i className="fa fa-google"></i></a>
                                    <a href="#" className="dribbble-bg"><i className="fa fa-dribbble"></i></a>
                                    <a href="#" className="pinterest-bg"><i className="fa fa-pinterest"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Map location</h3>
                            </div>
                            <div className="card-body">
                                <div className="map-header">
                                    <div className="map-header-layer" id="map2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Preview