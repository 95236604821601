import React from 'react'
import Base from '../../Components/Base/Base'
import { useState, useEffect } from 'react'
import Select from 'react-select';
import { Link } from 'react-router-dom';





import img01 from '../../custom assets/images/avatars/300-1.jpg'
import img02 from '../../custom assets/images/avatars/300-10.jpg'
import img03 from '../../custom assets/images/avatars/300-11.jpg'
import img04 from '../../custom assets/images/avatars/300-12.jpg'
import img05 from '../../custom assets/images/avatars/300-13.jpg'
import img06 from '../../custom assets/images/avatars/300-14.jpg'
import img07 from '../../custom assets/images/avatars/300-15.jpg'
import img08 from '../../custom assets/images/avatars/300-15.jpg'
import img09 from '../../custom assets/images/avatars/300-16.jpg'
import img10 from '../../custom assets/images/avatars/300-17.jpg'
import img11 from '../../custom assets/images/avatars/300-18.jpg'
import img12 from '../../custom assets/images/avatars/300-19.jpg'
import img13 from '../../custom assets/images/avatars/300-2.jpg'
import img14 from '../../custom assets/images/avatars/300-20.jpg'
import img15 from '../../custom assets/images/avatars/300-21.jpg'



const initialData = [
  { id: 1, Seller: [img01, 'Emma Smith'], Email: 'smith @kpmg.com', Phone: '9382746256', Category: 'Furniture', Status: 'Active', SubscriptionDate: '14 Dec 2020', TransactionAmount: '$32453', Actions: 'act' },
  { id: 2, Seller: [img02, 'Melody Macy'], Email: 'melody @altbox.com', Phone: '9865372653', Category: 'Appliances', Status: 'Active', SubscriptionDate: '01/Dec/2020', TransactionAmount: '$4512', Actions: '$act' },
  { id: 3, Seller: [img03, 'Max Smith'], Email: 'max @kt.com', Phone: '9637386372', Category: 'cleaning', Status: 'Active', SubscriptionDate: '12/Nov/2020', TransactionAmount: '$2321', Actions: '$act' },
  { id: 4, Seller: [img04, 'Sean Bean'], Email: 'sean @dellito.com', Phone: '8435361423', Category: 'Realestate', Status: 'Active', TransactionAmount: '$3412', SubscriptionDate: '21/Oct/2020', Actions: '$act' },
  { id: 5, Seller: [img05, 'Brian Cox'], Email: 'brian @exchange.com', Phone: '8464736252', Category: 'Realestate', Status: 'Active', TransactionAmount: '$3212', SubscriptionDate: '19/Oct/2020', Actions: '$act' },
  { id: 6, Seller: [img06, 'Mikaela Collins'], Email: 'mik @pex.com', Phone: '8783635425', Category: 'Realestate', Status: 'Active', TransactionAmount: '$2312', SubscriptionDate: '23/Sep/2020', Actions: '$act' },
  { id: 7, Seller: [img07, 'Francis Mitcham'], Email: 'f.mit@kpmg.com', Phone: '8756241342', Category: 'Realestate', Status: 'Active', TransactionAmount: '$66712', SubscriptionDate: '11/Sep/2020', Actions: '$act' },
  { id: 8, Seller: [img08, 'Olivia Wild'], Email: 'olivia @corpmail.com', Phone: '7847647362', Category: 'Realestate', Status: 'Active', TransactionAmount: '$7612', SubscriptionDate: '03/Sep/2020', Actions: '$act' },
  { id: 9, Seller: [img09, 'Neil Owen'], Email: 'owen.neil@gmail.com', Phone: '8746736744', Category: 'Realestate', Status: 'Active', TransactionAmount: '$7612', SubscriptionDate: '01/Sep/2020', Actions: '$act' },
  { id: 10, Seller: [img10, 'Dan Wilson'], Email: 'dam @consilting.com', Phone: '8784763452', Category: 'Realestate', Status: 'Active', TransactionAmount: '$4512', SubscriptionDate: '18 Aug 2020', Actions: '$act' },
  { id: 11, Seller: [img11, 'Home'], Email: 'img11', Phone: 'Place', Category: 'Realestate', Status: 'Active', SubscriptionDate: '12', TransactionAmount: '$2312', Actions: '$act' },
  { id: 12, Seller: [img12, 'Home'], Email: 'img12', Phone: 'Place', Category: 'Realestate', Status: 'Active', SubscriptionDate: '12', TransactionAmount: '$2312', Actions: '$act' },
  { id: 13, Seller: [img13, 'Home'], Email: 'img13', Phone: 'Place', Category: 'Realestate', Status: 'Active', SubscriptionDate: '12', TransactionAmount: '$2312', Actions: '$act' },
  { id: 14, Seller: [img14, 'Home'], Email: 'img14', Phone: 'Place', Category: 'Realestate', Status: 'Active', SubscriptionDate: '12', TransactionAmount: '$2312', Actions: '$act' },
  { id: 15, Seller: [img15, 'Home'], Email: 'img15', Phone: 'Place', Category: 'Realestate', Status: 'Active', SubscriptionDate: '12', TransactionAmount: '$2312', Actions: '$act' }
]

const SellersList = () => {

  const [data, setData] = useState(initialData);
  const [searchQuery, setSearchQuery] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed

  // Reset current page to 1 when searchQuery or statusFilter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);


  // Filter data based on search query for all columns
  const filteredData = data.filter(item => {
    const itemString = Object.values(item).join(' ').toLowerCase();
    const matchesSearch = itemString.includes(searchQuery.toLowerCase());
    return matchesSearch;
  });



  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = <span key="ellipsis">...</span>;

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={currentPage === i}
            style={{ margin: '0 5px' }}
            className='btn btn-sm btn-outline-primary'

          >
            {i}
          </button>
        );
      }
    } else {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          disabled={currentPage === 1}
          style={{ margin: '0 5px' }}
          className='btn btn-sm btn-outline-primary'

        >
          1
        </button>
      );

      if (currentPage > 3) {
        pageNumbers.push(ellipsis);
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={currentPage === i}
            style={{ margin: '0 5px' }}
            className='btn btn-sm btn-outline-primary'
          >
            {i}
          </button>
        );
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push(ellipsis);
      }

      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          disabled={currentPage === totalPages}
          style={{ margin: '0 5px' }}
          className='btn btn-sm btn-primary'

        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  // Function to get the color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'badge badge-light-primary';
      case 'Inactive':
        return 'badge badge-light-danger';
      default:
        return 'black';
    }
  };


  return (
    <Base>
      {/*<!--begin::Toolbar-->*/}
      <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
        {/*<!--begin::Container-->*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
          {/*<!--begin::Page title-->*/}
          <div className="page-title d-flex flex-column">
            {/*<!--begin::Breadcrumb-->*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                <a href="../../index.html" className="text-gray-600 text-hover-primary">
                  <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                </a>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item">
                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Apps</li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item">
                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Sellers</li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item">
                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-500">Seller list</li>
              {/*<!--end::Item-->*/}
            </ul>
            {/*<!--end::Breadcrumb-->*/}
            {/*<!--begin::Title-->*/}
            <h1
              className="page-heading d-flex flex-column justify-content-start text-gray-900 fw-bolder fs-3 m-0 float-start">
              Seller list</h1>
            {/*<!--end::Title-->*/}
          </div>
          {/*<!--end::Page title-->*/}

        </div>
        {/*<!--end::Container-->*/}
      </div>
      {/*<!--end::Toolbar-->*/}
      {/*<!--begin::Container-->*/}
      <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*<!--begin::Post-->*/}
        <div className="content flex-row-fluid" id="kt_content">
          {/*<!--begin::Card-->*/}
          <div className="card">
            {/*<!--begin::Card header-->*/}
            <div className="card-header border-0 pt-6">
              {/*<!--begin::Card title-->*/}
              <div className="card-title">
                {/*<!--begin::Search-->*/}
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input type="text" data-kt-ecommerce-order-filter="search"
                    className="form-control form-control-solid w-250px ps-12"
                    placeholder="Search Subcategories"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} />
                </div>
                {/*<!--end::Search-->*/}
              </div>
              {/*<!--begin::Card title-->*/}
              {/*<!--begin::Card toolbar-->*/}
              <div className="card-toolbar">
                {/*<!--begin::Toolbar-->*/}
                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                  {/*<!--begin::Filter-->*/}
                  <button type="button" className="btn btn-light-primary me-3"
                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    <i className="ki-duotone ki-filter fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>Filter</button>
                  {/*<!--begin::Menu 1-->*/}
                  <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true" id="kt-toolbar-filter">
                    {/*<!--begin::Header-->*/}
                    <div className="px-7 py-5">
                      <div className="fs-4 text-gray-900 fw-bold">Filter Options</div>
                    </div>
                    {/*<!--end::Header-->*/}
                    {/*<!--begin::Separator-->*/}
                    <div className="separator border-gray-200"></div>
                    {/*<!--end::Separator-->*/}
                    {/*<!--begin::Content-->*/}
                    <div className="px-7 py-5">
                      {/*<!--begin::Input group-->*/}
                      <div className="mb-10">
                        {/*<!--begin::Label-->*/}
                        <label className="form-label fs-5 fw-semibold mb-3">Month:</label>
                        {/*<!--end::Label-->*/}
                        {/*<!--begin::Input-->*/}
                        <select className="form-select form-select-solid fw-bold"
                          data-kt-select2="true" data-placeholder="Select option"
                          data-allow-clear="true" data-kt-customer-table-filter="month"
                          data-dropdown-parent="#kt-toolbar-filter">
                          <option></option>
                          <option value="aug">August</option>
                          <option value="sep">September</option>
                          <option value="oct">October</option>
                          <option value="nov">November</option>
                          <option value="dec">December</option>
                        </select>
                        {/*<!--end::Input-->*/}
                      </div>
                      {/*<!--end::Input group-->*/}
                      {/*<!--begin::Input group-->*/}
                      <div className="mb-10">
                        {/*<!--begin::Label-->*/}
                        <label className="form-label fs-5 fw-semibold mb-3">Payment
                          Type:</label>
                        {/*<!--end::Label-->*/}
                        {/*<!--begin::Options-->*/}
                        <div className="d-flex flex-column flex-wrap fw-semibold"
                          data-kt-customer-table-filter="payment_type">
                          {/*<!--begin::Option-->*/}
                          <label
                            className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                            <input className="form-check-input" type="radio"
                              name="payment_type" value="all" checked="checked" />
                            <span className="form-check-label text-gray-600">All</span>
                          </label>
                          {/*<!--end::Option-->*/}
                          {/*<!--begin::Option-->*/}
                          <label
                            className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                            <input className="form-check-input" type="radio"
                              name="payment_type" value="visa" />
                            <span className="form-check-label text-gray-600">Visa</span>
                          </label>
                          {/*<!--end::Option-->*/}
                          {/*<!--begin::Option-->*/}
                          <label
                            className="form-check form-check-sm form-check-custom form-check-solid mb-3">
                            <input className="form-check-input" type="radio"
                              name="payment_type" value="mastercard" />
                            <span
                              className="form-check-label text-gray-600">Mastercard</span>
                          </label>
                          {/*<!--end::Option-->*/}
                          {/*<!--begin::Option-->*/}
                          <label
                            className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input" type="radio"
                              name="payment_type" value="american_express" />
                            <span className="form-check-label text-gray-600">American
                              Express</span>
                          </label>
                          {/*<!--end::Option-->*/}
                        </div>
                        {/*<!--end::Options-->*/}
                      </div>
                      {/*<!--end::Input group-->*/}
                      {/*<!--begin::Actions-->*/}
                      <div className="d-flex justify-content-end">
                        <button type="reset"
                          className="btn btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="reset">Reset</button>
                        <button type="submit" className="btn btn-primary"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="filter">Apply</button>
                      </div>
                      {/*<!--end::Actions-->*/}
                    </div>
                    {/*<!--end::Content-->*/}
                  </div>
                  {/*<!--end::Menu 1-->*/}
                  {/*<!--end::Filter-->*/}
                  {/*<!--begin::Export-->*/}
                  <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal"
                    data-bs-target="#kt_customers_export_modal">
                    <i className="ki-duotone ki-exit-up fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>Export</button>
                  {/*<!--end::Export-->*/}

                  {/* <div className="dropdown">
                    <button type="button" className="btn btn-primary dropdown-toggle m-0 no-caret"
                      data-bs-toggle="dropdown">
                      <i className="bi bi-person-lines-fill fs-1"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">Contact Adminstrator</a></li>
                      <li><a className="dropdown-item" href="#">Link 2</a></li>
                      <li><a className="dropdown-item" href="#">Link 3</a></li>
                    </ul>
                  </div> */}
                </div>
                {/*<!--end::Toolbar-->*/}
                {/*<!--begin::Group actions-->*/}
                <div className="d-flex justify-content-end align-items-center d-none"
                  data-kt-customer-table-toolbar="selected">
                  <div className="fw-bold me-5">
                    <span className="me-2"
                      data-kt-customer-table-select="selected_count"></span>Selected
                  </div>
                  <button type="button" className="btn btn-danger"
                    data-kt-customer-table-select="delete_selected">Delete Selected</button>
                </div>
                {/*<!--end::Group actions-->*/}
              </div>
              {/*<!--end::Card toolbar-->*/}
            </div>
            {/*<!--end::Card header-->*/}
            {/*<!--begin::Card body-->*/}
            <div className="card-body pt-0">
              {/*<!--begin::Table-->*/}
              <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th className="text-center">
                     Id
                    </th>
                    <th className="text-center">Seller</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Phone</th>
                    <th className="text-center">Category</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Subscription Date</th>
                    <th className="text-center">Transaction Amount</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {currentData.map(item => (
                    <tr key={item.id}>
                      <td className='text-center'>{item.id}</td>
                      <td className='row'>
                       <div className='col-2'>
                          <img src={item.Seller[0]} width="50px" className='rounded-circle' />
                       </div>
                       <div className='col-10 '>
                         
                          <Link to="/sellerdetails">
                            <span className="align-middle">{item.Seller[1]}</span>
                          </Link>
                       </div>
                      </td>
                      <td className='text-center'>{item.Email}</td>
                      <td>{item.Phone}</td>
                      <td className='text-center'>{item.Category}</td>
                      <td><span className={getStatusColor(item.Status)}><b>{item.Status}</b></span></td>
                      <td className='text-center'>{item.SubscriptionDate}</td>

                      <td>{item.TransactionAmount}</td>
                      <td>
                        <div className="dropdown">
                          <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Actions
                          </button>
                          <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Edit</a></li>
                            <li><a className="dropdown-item" href="#">View</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}


                </tbody>
              </table>
              <div style={{ marginTop: '10px' }} className='float-end'>
                <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                  Previous
                </button>
                {renderPageNumbers()}
                <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
              {/*<!--end::Table-->*/}
            </div>
            {/*<!--end::Card body-->*/}
          </div>
          {/*<!--end::Card-->*/}
          {/*<!--begin::Modals-->*/}

          {/*<!--begin::Modal - Adjust Balance-->*/}
          <div className="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
            {/*<!--begin::Modal dialog-->*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
              {/*<!--begin::Modal content-->*/}
              <div className="modal-content">
                {/*<!--begin::Modal header-->*/}
                <div className="modal-header">
                  {/*<!--begin::Modal title-->*/}
                  <h2 className="fw-bold">Export Sellers</h2>
                  {/*<!--end::Modal title-->*/}
                  {/*<!--begin::Close-->*/}
                  <div id="kt_customers_export_close"
                    className="btn btn-icon btn-sm btn-active-icon-primary">
                    <i className="ki-duotone ki-cross fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </div>
                  {/*<!--end::Close-->*/}
                </div>
                {/*<!--end::Modal header-->*/}
                {/*<!--begin::Modal body-->*/}
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  {/*<!--begin::Form-->*/}
                  <form id="kt_customers_export_form" className="form" action="#">
                    {/*<!--begin::Input group-->*/}
                    <div className="fv-row mb-10">
                      {/*<!--begin::Label-->*/}
                      <label className="fs-5 fw-semibold form-label mb-5">Select Export
                        Format:</label>
                      {/*<!--end::Label-->*/}
                      {/*<!--begin::Input-->*/}
                      <select data-control="select2" data-placeholder="Select a format"
                        data-hide-search="true" name="format"
                        className="form-select form-select-solid">
                        <option value="excell">Excel</option>
                        <option value="pdf">PDF</option>
                        <option value="cvs">CVS</option>
                        <option value="zip">ZIP</option>
                      </select>
                      {/*<!--end::Input-->*/}
                    </div>
                    {/*<!--end::Input group-->*/}
                    {/*<!--begin::Input group-->*/}
                    <div className="fv-row mb-10">
                      {/*<!--begin::Label-->*/}
                      <label className="fs-5 fw-semibold form-label mb-5">Select Date
                        Range:</label>
                      {/*<!--end::Label-->*/}
                      {/*<!--begin::Input-->*/}
                      <input className="form-control form-control-solid" placeholder="Pick a date"
                        name="date" />
                      {/*<!--end::Input-->*/}
                    </div>
                    {/*<!--end::Input group-->*/}
                    {/*<!--begin::Row-->*/}
                    <div className="row fv-row mb-15">
                      {/*<!--begin::Label-->*/}
                      <label className="fs-5 fw-semibold form-label mb-5">Payment Type:</label>
                      {/*<!--end::Label-->*/}
                      {/*<!--begin::Radio group-->*/}
                      <div className="d-flex flex-column">
                        {/*<!--begin::Radio button-->*/}
                        <label
                          className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input className="form-check-input" type="checkbox" value="1"
                            checked="checked" name="payment_type" />
                          <span
                            className="form-check-label text-gray-600 fw-semibold">All</span>
                        </label>
                        {/*<!--end::Radio button-->*/}
                        {/*<!--begin::Radio button-->*/}
                        <label
                          className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input className="form-check-input" type="checkbox" value="2"
                            checked="checked" name="payment_type" />
                          <span
                            className="form-check-label text-gray-600 fw-semibold">Visa</span>
                        </label>
                        {/*<!--end::Radio button-->*/}
                        {/*<!--begin::Radio button-->*/}
                        <label
                          className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input className="form-check-input" type="checkbox" value="3"
                            name="payment_type" />
                          <span
                            className="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                        </label>
                        {/*<!--end::Radio button-->*/}
                        {/*<!--begin::Radio button-->*/}
                        <label
                          className="form-check form-check-custom form-check-sm form-check-solid">
                          <input className="form-check-input" type="checkbox" value="4"
                            name="payment_type" />
                          <span
                            className="form-check-label text-gray-600 fw-semibold">American
                            Express</span>
                        </label>
                        {/*<!--end::Radio button-->*/}
                      </div>
                      {/*<!--end::Input group-->*/}
                    </div>
                    {/*<!--end::Row-->*/}
                    {/*<!--begin::Actions-->*/}
                    <div className="text-center">
                      <button type="reset" id="kt_customers_export_cancel"
                        className="btn btn-light me-3">Discard</button>
                      <button type="submit" id="kt_customers_export_submit"
                        className="btn btn-primary">
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">Please wait...
                          <span
                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>
                    {/*<!--end::Actions-->*/}
                  </form>
                  {/*<!--end::Form-->*/}
                </div>
                {/*<!--end::Modal body-->*/}
              </div>
              {/*<!--end::Modal content-->*/}
            </div>
            {/*<!--end::Modal dialog-->*/}
          </div>
          {/*<!--end::Modal - New Card-->*/}
          {/*<!--end::Modals-->*/}
        </div>
        {/*<!--end::Post-->*/}
      </div>
      {/*<!--end::Container-->*/}
    </Base>
  )
}

export default SellersList