import React, { useState } from 'react';
import Base from '../../Components/Base/Base'


const Pricing = () => {

    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);


    const cardStyle1 = {
        transform: isHovered1 ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.2s ease',
    };


    const cardStyle2 = {
        transform: isHovered2 ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.2s ease',
    };

    const cardStyle3 = {
        transform: isHovered3 ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.2s ease',
    };

    const cardStyle4 = {
        transform: isHovered4 ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.2s ease',
    };
    return (
        <Base>
            <div className="row m-9">
                <div className="mb-13 text-center">
                    <h1 className="fs-2hx fw-bold mb-5">Choose Your Plan</h1>
                    <div className="text-gray-600 fw-semibold fs-5">If you need more info about our pricing, please check
                        <a href="#" className="link-primary fw-bold">Pricing Guidelines</a>.
                    </div>
                </div>
                <div className=" mb-15">
                    <button className="btn btn-primary  px-6 py-3 me-2"
                        data-kt-plan="month">Monthly</button>
                    <button className="btn btn-light px-6 py-3"
                        data-kt-plan="annual">Annual</button>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="card mb-lg-0" 
                        style={cardStyle1}
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                        >
                        <div className="card-body text-center">
                            <div className="bg-light fs-3 font-monospace">FREE</div>
                            <div className=" fw-normal display-1 my-4">$0</div>
                            <ul className="list-unstyled leading-loose">
                                <li className='mb-4'><strong>4</strong> Ads</li>
                                <li className='mb-4'><i className="bi bi-check-lg fs-2 text-success me-2"></i> 30 days</li>
                                <li className='mb-4'><i className="bi bi-x-lg fs-3 text-danger me-2"></i> Private Messages</li>
                                <li className='mb-5'><i className="bi bi-x-lg fs-3 text-danger me-2"></i> Urgent Ads</li>
                            </ul>
                            <div className="text-center mt-6">
                                <a href="#" className="btn btn-block w-100 text-white" style={{ backgroundColor:"#3adfab"}}>Choose plan</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="card mb-lg-0"
                        style={cardStyle2}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                        >
                        <div className="card-body text-center">
                            <div className="bg-light fs-3 font-monospace">PREMIUM</div>
                            <div className="fw-normal display-1  my-4">$65</div>
                            <ul className="list-unstyled leading-loose">
                                <li className='mb-4'><strong>50</strong> Ads</li>
                                <li className='mb-4'><i className="bi bi-check-lg fs-2 text-success me-2"></i> 60 Days</li>
                                <li className='mb-4'><i className="bi bi-x-lg fs-3 text-danger me-2"></i> Private Messages</li>
                                <li className='mb-5'><i className="bi bi-x-lg fs-3 text-danger me-2"></i> Urgent ads</li>
                            </ul>
                            <div className="text-center mt-6">
                                <a href="#" className="btn  btn-block w-100  text-white" style={{ backgroundColor: "#3adfab" }}>Choose plan</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="card mb-sm-0"
                        style={cardStyle3}
                        onMouseEnter={() => setIsHovered3(true)}
                        onMouseLeave={() => setIsHovered3(false)}
                        >
                        <div className="card-body text-center">
                            <div className="bg-light fs-3 font-monospace">ENTERPRISE</div>
                            <div className="fw-normal display-1  my-4">$100</div>
                            <ul className="list-unstyled leading-loose">
                                <li className='mb-4'><strong>100</strong> Ads</li>
                                <li className='mb-4'><i className="bi bi-check-lg fs-2  text-success me-2"></i> 180 days</li>
                                <li className='mb-4'><i className="bi bi-check-lg fs-2  text-success me-2"></i> Private Messages</li>
                                <li className='mb-5'><i className="bi bi-x-lg fs-3 text-danger me-2"></i> Urgent ads</li>
                            </ul>
                            <div className="text-center mt-6">
                                <a href="#" className="btn btn-block w-100 text-white" style={{ backgroundColor: "#3adfab" }}>Choose plan</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="card mb-0"
                        style={cardStyle4}
                        onMouseEnter={() => setIsHovered4(true)}
                        onMouseLeave={() => setIsHovered4(false)}
                        >
                        <div className="card-body text-center">
                            <div className="bg-light fs-3 font-monospace">UNLIMITED</div>
                            <div className="fw-normal display-1  my-4">$150</div>
                            <ul className="list-unstyled leading-loose">
                                <li  className='mb-4'><strong>Unlimited</strong> Ads</li>
                                <li  className='mb-4'><i className="bi bi-check-lg fs-2  text-success me-2"></i> 365 Days</li>
                                <li  className='mb-4'><i className="bi bi-check-lg fs-2  text-success me-2"></i> Private Messages</li>
                                <li  className='mb-5'><i className="bi bi-check-lg fs-2  text-success me-2"></i> Urgent ads</li>
                            </ul>
                            <div className="text-center mt-6">
                                <a href="#" className="btn btn-block w-100 text-white" style={{ backgroundColor: "#3adfab" }}>Choose plan</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )
}

export default Pricing