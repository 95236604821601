import React, { useState, useEffect } from 'react'
import Base from '../../Components/Base/Base'
import Select from 'react-select';
import { Link } from 'react-router-dom';


import img01 from '../../custom assets/images/categories/realestate.png'
import img02 from '../../custom assets/images/categories/education.png'
import img03 from '../../custom assets/images/categories/jobs.png'
import img04 from '../../custom assets/images/categories/business.png'
import img05 from '../../custom assets/images/categories/bike03.png'
import img06 from '../../custom assets/images/categories/car03.png'
import img07 from '../../custom assets/images/categories/furniture.png'
import img08 from '../../custom assets/images/categories/appliance.png'
import img09 from '../../custom assets/images/categories/tours.png'
import img10 from '../../custom assets/images/categories/security.jpg'
import img11 from '../../custom assets/images/categories/legal.webp'
import img12 from '../../custom assets/images/categories/healthcare.webp'
import img13 from '../../custom assets/images/categories/rentals.webp'
import img14 from '../../custom assets/images/categories/wellness.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCoffee,
    faHome,
    faCar,
    faPhone,
    faBell,
    faBook,
    faCamera,
    faCloud,
    faEnvelope,
    faHeart,
    faMusic,
    faSearch,
    faShoppingCart,
    faUser, faGlobe, faStar, faMapMarkerAlt, faCameraRetro, faThumbsUp, faTag, faGift, faWrench, faShieldAlt
} from '@fortawesome/free-solid-svg-icons';

const iconOptions = [
    { value: 'coffee', label: <FontAwesomeIcon icon={faCoffee} size='2x' />, icon: faCoffee },
    { value: 'home', label: <FontAwesomeIcon icon={faHome} size='2x' />, icon: faHome },
    { value: 'car', label: <FontAwesomeIcon icon={faCar} size='2x' />, icon: faCar },
    { value: 'phone', label: <FontAwesomeIcon icon={faPhone} size='2x' />, icon: faPhone },
    { value: 'bell', label: <FontAwesomeIcon icon={faBell} size='2x' />, icon: faBell },
    { value: 'book', label: <FontAwesomeIcon icon={faBook} size='2x' />, icon: faBook },
    { value: 'camera', label: <FontAwesomeIcon icon={faCamera} size='2x' />, icon: faCamera },
    { value: 'cloud', label: <FontAwesomeIcon icon={faCloud} size='2x' />, icon: faCloud },
    { value: 'envelope', label: <FontAwesomeIcon icon={faEnvelope} size='2x' />, icon: faEnvelope },
    { value: 'heart', label: <FontAwesomeIcon icon={faHeart} size='2x' />, icon: faHeart },
    { value: 'music', label: <FontAwesomeIcon icon={faMusic} size='2x' />, icon: faMusic },
    { value: 'search', label: <FontAwesomeIcon icon={faSearch} size='2x' />, icon: faSearch },
    { value: 'shopping-cart', label: <FontAwesomeIcon icon={faShoppingCart} size='2x' />, icon: faShoppingCart },
    { value: 'user', label: <FontAwesomeIcon icon={faUser} size='2x' />, icon: faUser },
    { value: 'globe', label: <FontAwesomeIcon icon={faGlobe} size='2x' />, icon: faGlobe },
    { value: 'star', label: <FontAwesomeIcon icon={faStar} size='2x' />, icon: faStar },
    { value: 'map-marker', label: <FontAwesomeIcon icon={faMapMarkerAlt} size='2x' />, icon: faMapMarkerAlt },
    { value: 'camera-retro', label: <FontAwesomeIcon icon={faCameraRetro} size='2x' />, icon: faCameraRetro },
    { value: 'thumbs-up', label: <FontAwesomeIcon icon={faThumbsUp} size='2x' />, icon: faThumbsUp },
    { value: 'tag', label: <FontAwesomeIcon icon={faTag} size='2x' />, icon: faTag },
    { value: 'gift', label: <FontAwesomeIcon icon={faGift} size='2x' />, icon: faGift },
    { value: 'wrench', label: <FontAwesomeIcon icon={faWrench} size='2x' />, icon: faWrench },
    { value: 'shield', label: <FontAwesomeIcon icon={faShieldAlt} size='2x' />, icon: faShieldAlt },
];

const customStyles = {
    menu: (provided) => ({
        ...provided,
        height: '200px', // Set your desired height for the dropdown menu
    }),
    menuList: (provided) => ({
        ...provided,
        height: '100%', // Ensure menu list takes up the full height
        overflowY: 'auto', // Add scroll if necessary
    }),
};



const initialData = [
    {
        id: 1, CategoryName: 'Realestate', Image: img01, Status: 'Active', Description: 'Property buying, selling, renting.', AdsCount: '12', SellersCount: '11', Actions: 'act',
    },
    {
        id: 2, CategoryName: 'Education', Image: img02, Status: 'Active', Description: 'Learning, teaching, academic institutions', AdsCount: '21', SellersCount: '15', Actions: 'act',
    },
    {
        id: 3, CategoryName: 'Jobs', Image: img03, Status: 'Inactive', Description: 'Employment opportunities, career advancements', AdsCount: '21', SellersCount: '22', Actions: 'act',
    },
    {
        id: 4, CategoryName: 'Business Directory', Image: img04, Status: 'Active', Description: 'Listings of businesses, contact information.', AdsCount: '22', SellersCount: '33', Actions: 'act',
    },
    {
        id: 5, CategoryName: 'Bikes', Image: img05, Status: 'Active', Description: 'Two - wheeled vehicles', AdsCount: '22', SellersCount: '12', Actions: 'act',
    },
    {
        id: 6, CategoryName: 'Cars', Image: img06, Status: 'Active', Description: 'Automobiles, transportation	', AdsCount: '24', SellersCount: '12', Actions: 'act',
    },
    {
        id: 7, CategoryName: 'Furniture', Image: img07, Status: 'Inactive', Description: 'Home, office furnishings, decor', AdsCount: '20', SellersCount: '20', Actions: 'act',
    },
    {
        id: 8, CategoryName: 'Appliances', Image: img08, Status: 'Active', Description: 'Household devices, electronic gadgets', AdsCount: '33', SellersCount: '22', Actions: 'act',
    },
    {
        id: 9, CategoryName: 'Tours & Travels	', Image: img09, Status: 'Active', Description: 'Vacations, trips, travel services.', AdsCount: '12', SellersCount: '9', Actions: 'act',
    },
    {
        id: 10, CategoryName: 'Security', Image: img10, Status: 'Active', Description: 'Safeguarding Your World, Securing Tomorrow', AdsCount: '2024', SellersCount: '21', Actions: '12',
    },
    {
        id: 11, CategoryName: 'Legal', Image: img11, Status: 'Active', Description: 'Expert Guidance, Trusted Legal Solutions', AdsCount: '2024', SellersCount: '12', Actions: '4',
    },
    {
        id: 12, CategoryName: 'Healthcare', Image: img12, Status: 'Active', Description: 'Compassionate Care for All', AdsCount: '2024', SellersCount: '22', Actions: '9',
    },
    {
        id: 13, CategoryName: 'Rental', Image: img13, Status: 'Active', Description: 'Unlock Your Dream Rental, Start Living!', AdsCount: '2024', SellersCount: '32', Actions: '13',
    },
    {
        id: 14, CategoryName: 'Wellness', Image: img14, Status: 'Active', Description: 'Wellness is the New Wealth', AdsCount: '2024', SellersCount: '15', Actions: '8',
    },
];


const CategoriesList = () => {

    // icon selection start
    // const [selectedIcon, setSelectedIcon] = useState('');
    // const [selectedIconValue, setSelectedIconValue] = useState('')
    // const [popoverOpen, setPopoverOpen] = useState(false);
    // useEffect(() => {
    //     console.log(selectedIcon, selectedIconValue);
    // }, [selectedIcon, selectedIconValue]);

    // const handleIconClick = (item) => {
    //     setSelectedIcon(item.icon);
    //     setSelectedIconValue(item.value)
    //     setPopoverOpen(false); // Close the popover
    // };

    // const SelectIcon = (
    //     <div className='row' style={{ maxHeight: '200px', overflowY: 'auto' }}>
    //         {iconOptions.map(option => (
    //             <div
    //                 key={option.value}
    //                 className='col-3 ms-1 mb-1'
    //                 onClick={() => handleIconClick(option)}
    //             >
    //                 {option.label}
    //             </div>
    //         ))}
    //     </div>
    // );
    // icon selection end
    const handleChange = (selectedOption) => {
        console.log('Selected:', selectedOption);
    };

    const [data, setData] = useState(initialData);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed

    //add category modal start
    // const [categoryName, setCategoryName] = useState('');
    // const handleCategoryChange = (event) => {
    //     setCategoryName(event.target.value);
    //     console.log(categoryName)
    // };

    // const [Description, setDescription] = useState('');
    // const handleDescriptionChange = (event) => {
    //     setDescription(event.target.value);
    //     console.log(Description)
    // };



    // const [status, setStatus] = useState('Active');
    // const handleStatusChange = (event) => {
    //     setStatus(event.target.value);
    // };
    //add category modal end

    // Reset current page to 1 when searchQuery or statusFilter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery, statusFilter]);

    // Filter data based on search query for all columns
    const filteredData = data.filter(item => {
        const itemString = Object.values(item).join(' ').toLowerCase();
        const matchesSearch = itemString.includes(searchQuery.toLowerCase());
        const matchesStatus = statusFilter === 'All' || item.Status === statusFilter;
        return matchesSearch && matchesStatus;
    });

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span key="ellipsis">...</span>;

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'

                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    disabled={currentPage === 1}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-outline-primary'

                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(ellipsis);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-primary'

                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    // Function to get the color based on status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return 'badge badge-light-primary';
            case 'Inactive':
                return 'badge badge-light-danger';
            default:
                return 'black';
        }
    };
    return (
        <div>
            <Base>
                {/*<!--begin::Toolbar-->*/}
                <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                    {/*<!--begin::Container-->*/}
                    <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                        {/*<!--begin::Page title-->*/}
                        <div className="page-title d-flex flex-column">
                            {/*<!--begin::Breadcrumb-->*/}
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                                {/*<!--begin::Item-->*/}
                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                    <a href="../../index.html" className="text-gray-600 text-hover-primary">
                                        <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                    </a>
                                </li>
                                {/*<!--end::Item-->*/}
                                {/*<!--begin::Item-->*/}
                                <li className="breadcrumb-item">
                                    <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                                </li>
                                {/*<!--end::Item-->*/}
                                {/*<!--begin::Item-->*/}
                                <li className="breadcrumb-item text-gray-500">Categories List</li>
                                {/*<!--end::Item-->*/}
                            </ul>
                            {/*<!--end::Breadcrumb-->*/}
                            {/*<!--begin::Title-->*/}
                            <h1
                                className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                                Categories List</h1>
                            {/*<!--end::Title-->*/}
                        </div>
                        {/*<!--end::Page title-->*/}


                        {/*<!--begin::Action-->*/}
                        {/* <p href="#" className="btn bg-body d-flex flex-center h-35px h-lg-40px" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_category">Create
                            <span className="d-none d-sm-inline ps-2">New</span></p> */}
                        {/*<!--end::Action-->*/}
                    </div>
                    {/*<!--end::Container-->*/}
                </div>
                {/*<!--end::Toolbar-->*/}
                {/*<!--begin::Container-->*/}
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                    {/*<!--begin::Post-->*/}
                    <div className="content flex-row-fluid" id="kt_content">
                        {/*<!--begin::Products-->*/}
                        <div className="card card-flush">
                            {/*<!--begin::Card header-->*/}
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                {/*<!--begin::Card title-->*/}
                                <div className="card-title">
                                    {/*<!--begin::Search-->*/}
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        <input type="text" data-kt-ecommerce-order-filter="search"
                                            className="form-control form-control-solid w-250px ps-12"
                                            placeholder="Search Ads"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                    {/*<!--end::Search-->*/}
                                </div>
                                {/*<!--end::Card title-->*/}
                                {/*<!--begin::Card toolbar-->*/}

                                <div className="d-flex">
                                    <div className='d-flex justify-content-around'>
                                        <label className='text-gray-500 fs-4 fw-semibold mx-3 mt-2'>Status</label>
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                All
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">All</a></li>
                                                <li><a className="dropdown-item" href="#">Active</a></li>
                                                <li><a className="dropdown-item" href="#">Inactive</a></li>
                                            </ul>
                                        </div>
                                        {/*<!--begin::Export-->*/}

                                        {/* <select className="form-select btn btn-outline-secondary  mx-3" 
                                                value={statusFilter}
                                                onChange={(e) => setStatusFilter(e.target.value)}
                                            >
                                                <option value="All" className='rounded'>All</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select> */}
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-light-primary mx-3 btn-sm" data-bs-toggle="modal"
                                            data-bs-target="#kt_customers_export_modal">
                                            <i class="bi bi-upload"></i>Export</button>
                                        {/*<!--end::Export-->*/}
                                    </div>
                                </div>
                                {/*<!--end::Card toolbar-->*/}
                            </div>
                            {/*<!--end::Card header-->*/}
                            {/*<!--begin::Card body-->*/}
                            <div className="card-body pt-0">
                                {/*<!--begin::Table-->*/}
                                <table className="table align-middle table-row-dashed fs-6 gy-5"
                                    id="kt_ecommerce_sales_table">
                                    <thead>
                                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                            <th className="text-center">Category Id</th>
                                            <th className="text-center">Category Name</th>
                                            <th className="text-center">Image</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center ">Description</th>
                                            <th className="text-center ">Ads Count</th>
                                            <th className="text-center ">Seller's count</th>
                                            <th className="text-center ">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                        {currentData.map(item => (
                                            <tr key={item.id}>
                                                <td className='text-center'>{item.id}</td>
                                                <td className='text-center'>{item.CategoryName}</td>
                                                <td>
                                                    <img src={item.Image} width="80px" className='img-thumbnail' />
                                                </td>
                                                <td><span className={getStatusColor(item.Status)}><b>{item.Status}</b></span></td>
                                                <td>{item.Description}</td>
                                                <td>{item.AdsCount}</td>
                                                <td>{item.SellersCount}</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Actions
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            {/* <li><a className="dropdown-item" href="#">Edit</a></li> */}
                                                            <li><a className="dropdown-item" data-bs-toggle="modal"
                                                                data-bs-target="#kt_modal_view_category" href="#">View</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/*<!--end::Table-->*/}
                                <div style={{ marginTop: '10px' }} className='float-end'>
                                    <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                        Previous
                                    </button>
                                    {renderPageNumbers()}
                                    <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/*<!--end::Card body-->*/}
                        </div>
                        {/*<!--end::Products-->*/}
                    </div>
                    {/*<!--end::Post-->*/}
                </div>
                {/*<!--end::Container-->*/}
                {/*<!--begin::Modal - Export-->*/}
                <div className="modal fade" id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
                    {/*<!--begin::Modal dialog-->*/}
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        {/*<!--begin::Modal content-->*/}
                        <div className="modal-content">
                            {/*<!--begin::Modal header-->*/}
                            <div className="modal-header">
                                {/*<!--begin::Modal title-->*/}
                                <h2 className="fw-bold">Export Sellers</h2>
                                {/*<!--end::Modal title-->*/}
                                {/*<!--begin::Close-->*/}
                                <div id="kt_customers_export_close"
                                    className="btn btn-icon btn-sm btn-active-icon-primary">
                                    <i className="ki-duotone ki-cross fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                                {/*<!--end::Close-->*/}
                            </div>
                            {/*<!--end::Modal header-->*/}
                            {/*<!--begin::Modal body-->*/}
                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                {/*<!--begin::Form-->*/}
                                <form id="kt_customers_export_form" className="form" action="#">
                                    {/*<!--begin::Input group-->*/}
                                    <div className="fv-row mb-10">
                                        {/*<!--begin::Label-->*/}
                                        <label className="fs-5 fw-semibold form-label mb-5">Select Export
                                            Format:</label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Input-->*/}
                                        <select data-control="select2" data-placeholder="Select a format"
                                            data-hide-search="true" name="format"
                                            className="form-select form-select-solid">
                                            <option value="excell">Excel</option>
                                            <option value="pdf">PDF</option>
                                            <option value="cvs">CVS</option>
                                            <option value="zip">ZIP</option>
                                        </select>
                                        {/*<!--end::Input-->*/}
                                    </div>
                                    {/*<!--end::Input group-->*/}
                                    {/*<!--begin::Input group-->*/}
                                    <div className="fv-row mb-10">
                                        {/*<!--begin::Label-->*/}
                                        <label className="fs-5 fw-semibold form-label mb-5">Select Date
                                            Range:</label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Input-->*/}
                                        <input className="form-control form-control-solid" placeholder="Pick a date"
                                            name="date" />
                                        {/*<!--end::Input-->*/}
                                    </div>
                                    {/*<!--end::Input group-->*/}
                                    {/*<!--begin::Row-->*/}
                                    <div className="row fv-row mb-15">
                                        {/*<!--begin::Label-->*/}
                                        <label className="fs-5 fw-semibold form-label mb-5">Payment Type:</label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Radio group-->*/}
                                        <div className="d-flex flex-column">
                                            {/*<!--begin::Radio button-->*/}
                                            <label
                                                className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input className="form-check-input" type="checkbox" value="1"
                                                    checked="checked" name="payment_type" />
                                                <span
                                                    className="form-check-label text-gray-600 fw-semibold">All</span>
                                            </label>
                                            {/*<!--end::Radio button-->*/}
                                            {/*<!--begin::Radio button-->*/}
                                            <label
                                                className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input className="form-check-input" type="checkbox" value="2"
                                                    checked="checked" name="payment_type" />
                                                <span
                                                    className="form-check-label text-gray-600 fw-semibold">Visa</span>
                                            </label>
                                            {/*<!--end::Radio button-->*/}
                                            {/*<!--begin::Radio button-->*/}
                                            <label
                                                className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                                <input className="form-check-input" type="checkbox" value="3"
                                                    name="payment_type" />
                                                <span
                                                    className="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                                            </label>
                                            {/*<!--end::Radio button-->*/}
                                            {/*<!--begin::Radio button-->*/}
                                            <label
                                                className="form-check form-check-custom form-check-sm form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="4"
                                                    name="payment_type" />
                                                <span
                                                    className="form-check-label text-gray-600 fw-semibold">American
                                                    Express</span>
                                            </label>
                                            {/*<!--end::Radio button-->*/}
                                        </div>
                                        {/*<!--end::Input group-->*/}
                                    </div>
                                    {/*<!--end::Row-->*/}
                                    {/*<!--begin::Actions-->*/}
                                    <div className="text-center">
                                        <button type="reset" id="kt_customers_export_cancel"
                                            className="btn btn-light me-3">Discard</button>
                                        <button type="submit" id="kt_customers_export_submit"
                                            className="btn btn-primary">
                                            <span className="indicator-label">Submit</span>
                                            <span className="indicator-progress">Please wait...
                                                <span
                                                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                    </div>
                                    {/*<!--end::Actions-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                            {/*<!--end::Modal body-->*/}
                        </div>
                        {/*<!--end::Modal content-->*/}
                    </div>
                    {/*<!--end::Modal dialog-->*/}
                </div>
                {/*<!--end::Modal - New Export-->*/}



                {/*<!--begin::Modal - Create Category-->*/}
                {/* <div className="modal fade" id="kt_modal_create_category" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-rounded">
                            <div className="modal-header d-flex ">
                                <h2>Create Category</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <i className="ki-duotone ki-cross fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                            </div>
                            <div className="modal-body scroll-y">
                                <form className="mx-auto w-100 mw-800px"
                                    id="kt_modal_create_category_stepper_form">
                                    <div className="current" data-kt-stepper-element="content">
                                        <div className="w-100">
                                            <div className="row">
                                                <div className="mb-3 fv-row col-6">
                                                    <label className="required form-label mb-3 float-start">Category Name</label>
                                                    <input
                                                        type="text"
                                                        id="textInput"
                                                        value={categoryName}
                                                        onChange={handleCategoryChange}
                                                        className="form-control form-control-solid"
                                                        name="CategoryName"
                                                    />

                                                </div>
                                                <div className="mb-3 fv-row col-6 row">
                                                    <p className=" fs-5 fw-semibold mb-3 col-12 text-start">Icon:</p>
                                                   
                                                    <Popover placement="bottomLeft"
                                                        content={SelectIcon}
                                                        trigger="click"
                                                        className='col-10'
                                                        zIndex={1100}
                                                        arrow={false}
                                                        align={{ offset: [2, 20] }}
                                                        overlayStyle={{ width: 300 }}
                                                        open={popoverOpen}
                                                        onOpenChange={(open) => setPopoverOpen(open)}

                                                    >
                                                        <span className="menu-link btn col-10 btn-s w-50 h-50" style={{ backgroundColor:"#f9f9f9"}}>
                                                            <p className=''>click to select an Icon</p>
                                                        </span>

                                                    </Popover>
                                                    <div className='col-2 mt-4 justify-content-center h-50' >
                                                        {selectedIcon ? (
                                                            <FontAwesomeIcon icon={selectedIcon} size="3x" />
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="fv-row mb-3 col-12 col-md-4">
                                                    <label className="d-block fw-semibold fs-6 mb-5">
                                                        <span className="required">Category Thumbnail</span>
                                                        <span className="ms-1" data-bs-toggle="tooltip"
                                                            title="E.g. Select a logo to represent the company that's running the campaign.">
                                                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span>
                                                    </label>
                                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder"
                                                        data-kt-image-input="true">
                                                        <div className="image-input-wrapper w-125px h-125px"></div>
                                                        <label
                                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                            data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                                            title="Change avatar">
                                                            <i className="ki-duotone ki-pencil fs-7">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                            <input type="hidden" name="avatar_remove" />
                                                        </label>
                                                        <span
                                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                            data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                                            title="Cancel avatar">
                                                            <i className="ki-duotone ki-cross fs-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                        </span>
                                                        <span
                                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                            data-kt-image-input-action="remove" data-bs-toggle="tooltip"
                                                            title="Remove avatar">
                                                            <i className="ki-duotone ki-cross fs-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                                </div>
                                                <div className="col-12 col-md-8 row">
                                                    <div className="mb-3  fv-row">
                                                        <label className="required form-label mb-5  float-start">Description</label>
                                                        <textarea
                                                            name="Description"
                                                            value={Description}
                                                            onChange={handleDescriptionChange}
                                                            className="form-control form-control-solid"
                                                            rows="3"
                                                            cols="50"
                                                        />

                                                    </div>
                                                    <div className="mb-3 row justify-content-start">
                                                        <label className="required fw-semibold fs-6 mb-5 col-12 text-start">Status</label>
                                                        <div className="d-flex fv-row col-3">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                    id="kt_modal_update_role_option_0"
                                                                    type="radio"
                                                                    value="Active"
                                                                    checked={status === 'Active'}
                                                                    onChange={handleStatusChange} />
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
                                                                    <div className="fw-bold text-gray-800">Active</div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex fv-row col-3">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                    id="kt_modal_update_role_option_1"
                                                                    type="radio"
                                                                    value="Inactive"
                                                                    checked={status === 'Inactive'}
                                                                    onChange={handleStatusChange} />
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
                                                                    <div className="fw-bold text-gray-800">Inactive</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/*<!--end::Modal - Create Category-->*/}



                {/*<!--begin::Modal - View Category-->*/}
                <div className="modal fade" id="kt_modal_view_category" tabIndex="-1" aria-hidden="true">
                    {/*<!--begin::Modal dialog-->*/}
                    <div className="modal-dialog modal-lg" >
                        {/*<!--begin::Modal content-->*/}
                        <div className="modal-content modal-rounded" style={{ maxHeight: "550px" }}>
                            {/*<!--begin::Modal header-->*/}
                            <div className="modal-header">
                                {/*<!--begin::Modal title-->*/}
                                <div className='row justify-content-between col-10'>
                                    <div className='row col-4'>
                                        <div className='col-12 text-start mb-2'>
                                            <img src={img01} className='img-thumbnail' style={{ width: "100px" }} />
                                        </div>
                                        <div className='col-12 text-start'>
                                            <h2 className=''>Realestate <span class="badge badge-primary">Active</span></h2>
                                        </div>
                                    </div>
                                    <div className="col-8 row text-start">
                                        <h6 className="mb-1">Description:</h6>
                                        <p>Property buying, selling, renting</p>
                                    </div>

                                </div>
                                <div className="btn btn-sm btn-icon btn-active-color-primary col-2" data-bs-dismiss="modal">
                                    <i className="ki-duotone ki-cross fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>

                            </div>
                            {/*<!--begin::Modal header-->*/}
                            {/*<!--begin::Modal body-->*/}
                            <div className="modal-body scroll-y">
                                {/*<!--begin::Stepper-->*/}
                                {/*<!--begin::Step 1-->*/}
                                <div className="current" data-kt-stepper-element="content">
                                    {/*<!--begin::Wrapper-->*/}
                                    <div className="w-100">
                                        <div className="table-responsive overflow-y-auto" style={{ minHeight: '100px' }}>
                                            <table className="table  table-row-dashed " >
                                                <thead className=''>
                                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                        <th className="text-center">Id</th>
                                                        <th className="text-center">Subcategory</th>
                                                        <th className="text-center">Status</th>
                                                        <th className="text-center ">Ads Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            Home
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            Apartments
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            Lands
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">12</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            4
                                                        </td>
                                                        <td>
                                                            Plots
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">21</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            5
                                                        </td>
                                                        <td>
                                                            Offices
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">19</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            6
                                                        </td>
                                                        <td>
                                                            Shops
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">23</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            7
                                                        </td>
                                                        <td>
                                                            Villas
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            8
                                                        </td>
                                                        <td>
                                                            Commercial
                                                        </td>
                                                        <td> <span className='badge text-bg-primary'>Active</span> </td>
                                                        <td className="text-center">32</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--end::Modal - View Category-->*/}
            </Base>
        </div>
    )
}

export default CategoriesList