import React from 'react'
import { Link } from 'react-router-dom'

const Signup = () => {
  return (
      <div className="d-flex flex-column flex-root">
          {/*<!--begin::Page bg image-->*/}
          {/* <style>body {background - image: url('assets/media/auth/bg10.jpeg'); } [data-bs-theme="dark"] body {background - image: url('assets/media/auth/bg10-dark.jpeg'); }</style> */}
          {/*<!--end::Page bg image-->*/}
          {/*<!--begin::Authentication - Sign-up -->*/}
          <div className="d-flex flex-column flex-lg-row flex-column-fluid">
              {/*<!--begin::Aside-->*/}
              <div className="d-flex flex-lg-row-fluid">
                  {/*<!--begin::Content-->*/}
                  <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                      {/*<!--begin::Image-->*/}
                      <img className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/media/auth/agency.png" alt="" />
                      <img className="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/media/auth/agency-dark.png" alt="" />
                      {/*<!--end::Image-->*/}
                      {/*<!--begin::Title-->*/}
                      <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">Fast, Efficient and Productive</h1>
                      {/*<!--end::Title-->*/}
                      {/*<!--begin::Text-->*/}
                      <div className="text-gray-600 fs-base text-center fw-semibold">In this kind of post,
                          <a href="#" className="opacity-75-hover text-primary me-1">the blogger</a>introduces a person they’ve interviewed
                          <br />and provides some background information about
                          <a href="#" className="opacity-75-hover text-primary me-1">the interviewee</a>and their
                          <br />work following this is a transcript of the interview.</div>
                      {/*<!--end::Text-->*/}
                  </div>
                  {/*<!--end::Content-->*/}
              </div>
              {/*<!--begin::Aside-->*/}
              {/*<!--begin::Body-->*/}
              <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                  {/*<!--begin::Wrapper-->*/}
                  <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                      {/*<!--begin::Content-->*/}
                      <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                          {/*<!--begin::Wrapper-->*/}
                          <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                              {/*<!--begin::Form-->*/}
                              <form className="form w-100" novalidate="novalidate" id="kt_sign_up_form" data-kt-redirect-url="authentication/layouts/overlay/sign-in.html" action="#">
                                  {/*<!--begin::Heading-->*/}
                                  <div className="text-center mb-11">
                                      {/*<!--begin::Title-->*/}
                                      <h1 className="text-gray-900 fw-bolder mb-3">Sign Up</h1>
                                      {/*<!--end::Title-->*/}
                                    
                                  </div>
                                  {/*<!--begin::Heading-->*/}
                                  {/*<!--begin::Login options-->*/}
                                  <div className="row g-3 mb-9">
                                      {/*<!--begin::Col-->*/}
                                      <div className="col-md-6">
                                          {/*<!--begin::Google link=-->*/}
                                          <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                              <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
                                          {/*<!--end::Google link=-->*/}
                                      </div>
                                      {/*<!--end::Col-->*/}
                                      {/*<!--begin::Col-->*/}
                                      <div className="col-md-6">
                                          {/*<!--begin::Google link=-->*/}
                                          <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
                                              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
                                          {/*<!--end::Google link=-->*/}
                                      </div>
                                      {/*<!--end::Col-->*/}
                                  </div>
                                  {/*<!--end::Login options-->*/}
                                  {/*<!--begin::Separator-->*/}
                                  <div className="separator separator-content my-14">
                                      <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                                  </div>
                                  {/*<!--end::Separator-->*/}
                                  {/*<!--begin::Input group=-->*/}
                                  <div className="fv-row mb-8">
                                      {/*<!--begin::Email-->*/}
                                      <input type="text" placeholder="Email" name="email" autocomplete="off" className="form-control bg-transparent" />
                                      {/*<!--end::Email-->*/}
                                  </div>
                                  {/*<!--begin::Input group-->*/}
                                  <div className="fv-row mb-8" data-kt-password-meter="true">
                                      {/*<!--begin::Wrapper-->*/}
                                      <div className="mb-1">
                                          {/*<!--begin::Input wrapper-->*/}
                                          <div className="position-relative mb-3">
                                              <input className="form-control bg-transparent" type="password" placeholder="Password" name="password" autocomplete="off" />
                                              <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                  <i className="ki-duotone ki-eye-slash fs-2"></i>
                                                  <i className="ki-duotone ki-eye fs-2 d-none"></i>
                                              </span>
                                          </div>
                                          {/*<!--end::Input wrapper-->*/}
                                          {/*<!--begin::Meter-->*/}
                                          <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                                          </div>
                                          {/*<!--end::Meter-->*/}
                                      </div>
                                      {/*<!--end::Wrapper-->*/}
                                      {/*<!--begin::Hint-->*/}
                                      <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
                                      {/*<!--end::Hint-->*/}
                                  </div>
                                  {/*<!--end::Input group=-->*/}
                                  {/*<!--end::Input group=-->*/}
                                  <div className="fv-row mb-8">
                                      {/*<!--begin::Repeat Password-->*/}
                                      <input placeholder="Repeat Password" name="confirm-password" type="password" autocomplete="off" className="form-control bg-transparent" />
                                      {/*<!--end::Repeat Password-->*/}
                                  </div>
                                  {/*<!--end::Input group=-->*/}
                                  {/*<!--begin::Accept-->*/}
                                  <div className="fv-row mb-8">
                                      <label className="form-check form-check-inline">
                                          <input className="form-check-input" type="checkbox" name="toc" value="1" />
                                          <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">I Accept the
                                              <a href="#" className="ms-1 link-primary">Terms</a></span>
                                      </label>
                                  </div>
                                  {/*<!--end::Accept-->*/}
                                  {/*<!--begin::Submit button-->*/}
                                  <div className="d-grid mb-10">
                                      <button type="submit" id="kt_sign_up_submit" className="btn btn-primary">
                                          {/*<!--begin::Indicator label-->*/}
                                          <span className="indicator-label">Sign up</span>
                                          {/*<!--end::Indicator label-->*/}
                                          {/*<!--begin::Indicator progress-->*/}
                                          <span className="indicator-progress">Please wait...
                                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                          {/*<!--end::Indicator progress-->*/}
                                      </button>
                                  </div>
                                  {/*<!--end::Submit button-->*/}
                                  {/*<!--begin::Sign up-->*/}
                                  <div className="text-gray-500 text-center fw-semibold fs-6">Already have an Account?
                                      <Link to="/signin" className="link-primary fw-semibold">Sign in</Link></div>
                                  {/*<!--end::Sign up-->*/}
                              </form>
                              {/*<!--end::Form-->*/}
                          </div>
                          {/*<!--end::Wrapper-->*/}
                          {/*<!--begin::Footer-->*/}
                          <div className="d-flex flex-stack">
                              {/*<!--begin::Languages-->*/}
                              <div className="me-10">
                                  {/*<!--begin::Toggle-->*/}
                                  <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
                                      <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src="assets/media/flags/united-states.svg" alt="" />
                                      <span data-kt-element="current-lang-name" className="me-1">English</span>
                                      <i className="ki-duotone ki-down fs-5 text-muted rotate-180 m-0"></i>
                                  </button>
                                  {/*<!--end::Toggle-->*/}
                                  {/*<!--begin::Menu-->*/}
                                  <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
                                      {/*<!--begin::Menu item-->*/}
                                      <div className="menu-item px-3">
                                          <a href="#" className="menu-link d-flex px-5" data-kt-lang="English">
                                              <span className="symbol symbol-20px me-4">
                                                  <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                                              </span>
                                              <span data-kt-element="lang-name">English</span>
                                          </a>
                                      </div>
                                      {/*<!--end::Menu item-->*/}
                                      {/*<!--begin::Menu item-->*/}
                                      <div className="menu-item px-3">
                                          <a href="#" className="menu-link d-flex px-5" data-kt-lang="Spanish">
                                              <span className="symbol symbol-20px me-4">
                                                  <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                                              </span>
                                              <span data-kt-element="lang-name">Spanish</span>
                                          </a>
                                      </div>
                                      {/*<!--end::Menu item-->*/}
                                      {/*<!--begin::Menu item-->*/}
                                      <div className="menu-item px-3">
                                          <a href="#" className="menu-link d-flex px-5" data-kt-lang="German">
                                              <span className="symbol symbol-20px me-4">
                                                  <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                                              </span>
                                              <span data-kt-element="lang-name">German</span>
                                          </a>
                                      </div>
                                      {/*<!--end::Menu item-->*/}
                                      {/*<!--begin::Menu item-->*/}
                                      <div className="menu-item px-3">
                                          <a href="#" className="menu-link d-flex px-5" data-kt-lang="Japanese">
                                              <span className="symbol symbol-20px me-4">
                                                  <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                                              </span>
                                              <span data-kt-element="lang-name">Japanese</span>
                                          </a>
                                      </div>
                                      {/*<!--end::Menu item-->*/}
                                      {/*<!--begin::Menu item-->*/}
                                      <div className="menu-item px-3">
                                          <a href="#" className="menu-link d-flex px-5" data-kt-lang="French">
                                              <span className="symbol symbol-20px me-4">
                                                  <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/france.svg" alt="" />
                                              </span>
                                              <span data-kt-element="lang-name">French</span>
                                          </a>
                                      </div>
                                      {/*<!--end::Menu item-->*/}
                                  </div>
                                  {/*<!--end::Menu-->*/}
                              </div>
                              {/*<!--end::Languages-->*/}
                              {/*<!--begin::Links-->*/}
                              <div className="d-flex fw-semibold text-primary fs-base gap-5">
                                  <a href="pages/team.html" target="_blank">Terms</a>
                                  <a href="pages/pricing/column.html" target="_blank">Plans</a>
                                  <a href="pages/contact.html" target="_blank">Contact Us</a>
                              </div>
                              {/*<!--end::Links-->*/}
                          </div>
                          {/*<!--end::Footer-->*/}
                      </div>
                      {/*<!--end::Content-->*/}
                  </div>
                  {/*<!--end::Wrapper-->*/}
              </div>
              {/*<!--end::Body-->*/}
          </div>
          {/*<!--end::Authentication - Sign-up-->*/}
      </div>
  )
}

export default Signup