import React from 'react'
import Base from '../../Components/Base/Base'
import { Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import img07 from '../../custom assets/images/categories/furniture.png'
import img01 from './sofa.png'
import img02 from './bed01.png'
import img03 from './bed02.png'
import img04 from './beds.png'
import img05 from './sofa01.png'

const images=[img01,img02,img03,img04,img05]

const AdDetails = () => {
    return (

        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                     
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Ad Details</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}

                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Form-->*/}
                    <form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row"
                        data-kt-redirect="apps/ecommerce/catalog/products.html">
                        {/*<!--begin::Aside column-->*/}
                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                            {/*<!--begin::Thumbnail settings-->*/}
                            <div className="card card-flush py-4">
                                {/*<!--begin::Card header-->*/}
                                <div className="card-header">
                                    {/*<!--begin::Card title-->*/}
                                    <div className="card-title">
                                        <h2>Thumbnail</h2>
                                    </div>
                                    {/*<!--end::Card title-->*/}
                                </div>
                                {/*<!--end::Card header-->*/}
                                {/*<!--begin::Card body-->*/}
                                <div className="card-body text-center pt-0">
                                    {/*<!--begin::Image input-->*/}
                                    {/*<!--begin::Image input placeholder-->*/}

                                    {/*<!--end::Image input placeholder-->*/}
                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                                        data-kt-image-input="true">
                                        <img src={img07} className='img-thumbnail w-100' />
                                    </div>
                                    {/*<!--end::Image input-->*/}

                                </div>
                                {/*<!--end::Card body-->*/}
                            </div>
                            {/*<!--end::Thumbnail settings-->*/}

                        </div>
                        {/*<!--end::Aside column-->*/}
                        {/*<!--begin::Main column-->*/}
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            {/*<!--begin:::Tabs-->*/}
                            <ul
                                className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                {/*<!--begin:::Tab item-->*/}
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                        href="#kt_ecommerce_add_product_general">General</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                                {/*<!--begin:::Tab item-->*/}
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                        href="#kt_ecommerce_add_product_advanced">Additional Details</a>
                                </li>
                                {/*<!--end:::Tab item-->*/}
                            </ul>
                            {/*<!--end:::Tabs-->*/}
                            {/*<!--begin::Tab content-->*/}
                            <div className="tab-content">
                                {/*<!--begin::Tab pane-->*/}
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general"
                                    role="tab-panel">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        {/*<!--begin::General options-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="mb-5 fv-row col-5  text-start">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Ad Title</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="product_name"
                                                            className="form-control mb-2"
                                                            value="Furniture for sale" disabled />
                                                        {/*<!--end::Input-->*/}

                                                    </div>
                                                    {/*<!-- begin::category select -->*/}
                                                    <div className="mb-5 fv-row col-3  text-start">
                                                        {/*<!--begin::Label-->*/}

                                                        <label className="form-label d-block">Categories</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}

                                                        <input type="text" name="product_name"
                                                            className="form-control mb-2"
                                                            value="Furniture" disabled />
                                                        {/*<!--end::Select2-->*/}
                                                    </div>
                                                    {/*<!-- end::category select -->*/}
                                                    {/*<!-- begin::SubCategory select -->*/}
                                                    <div className="mb-5 fv-row col-3 text-start">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label d-block">Subcategories</label>
                                                        {/*<!--end::Label-->*/}
                                                        <input type="text" name="product_name"
                                                            className="form-control mb-2"
                                                            value="Bed" disabled />

                                                        {/*<!--end::Select2-->*/}
                                                    </div>
                                                    {/*<!-- end::subCategory select -->*/}


                                                    {/*<!--end::Input group-->*/}
                                                </div>
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-5  text-start">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Description</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Editor-->*/}
                                                    <textarea
                                                        className="form-control form-control-lg form-control-solid"
                                                        name="campaign_name" placeholder="" value="Luxurious comfort, stylish design, and superior support for perfect sleep." disabled></textarea>
                                                    {/*<!--end::Editor-->*/}

                                                </div>

                                            </div>
                                            {/*<!--end::Card header-->*/}

                                        </div>
                                        {/*<!--begin::Pricing-->*/}
                                        <div className="card card-flush py-4  text-start">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Pricing</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="required form-label">Base Price</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input type="text" name="price" className="form-control mb-2"
                                                        placeholder="Product price" value="$400" disabled />
                                                    {/*<!--end::Input-->*/}

                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row mb-10">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="fs-6 fw-semibold mb-2">Discount Type
                                                        <span className="ms-1" data-bs-toggle="tooltip"
                                                            title="Select a discount type that will be applied to this product">
                                                            <i
                                                                className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span></label>
                                                    {/*<!--End::Label-->*/}
                                                    {/*<!--begin::Row-->*/}
                                                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9"
                                                        data-kt-buttons="true"
                                                        data-kt-buttons-target="[data-kt-button='true']">
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="1"
                                                                        checked="checked" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">No
                                                                        Discount</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="2" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">Percentage
                                                                        %</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                        {/*<!--begin::Col-->*/}
                                                        <div className="col">
                                                            {/*<!--begin::Option-->*/}
                                                            <label
                                                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                                                                data-kt-button="true">
                                                                {/*<!--begin::Radio-->*/}
                                                                <span
                                                                    className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="discount_option" value="3" />
                                                                </span>
                                                                {/*<!--end::Radio-->*/}
                                                                {/*<!--begin::Info-->*/}
                                                                <span className="ms-5">
                                                                    <span
                                                                        className="fs-4 fw-bold text-gray-800 d-block">Fixed
                                                                        Price</span>
                                                                </span>
                                                                {/*<!--end::Info-->*/}
                                                            </label>
                                                            {/*<!--end::Option-->*/}
                                                        </div>
                                                        {/*<!--end::Col-->*/}
                                                    </div>
                                                    {/*<!--end::Row-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-none mb-10 fv-row"
                                                    id="kt_ecommerce_add_product_discount_percentage">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Set Discount Percentage</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Slider-->*/}
                                                    <div className="d-flex flex-column text-center mb-5">
                                                        <div
                                                            className="d-flex align-items-start justify-content-center mb-7">
                                                            <span className="fw-bold fs-3x"
                                                                id="kt_ecommerce_add_product_discount_label">0</span>
                                                            <span className="fw-bold fs-4 mt-1 ms-2">%</span>
                                                        </div>
                                                        <div id="kt_ecommerce_add_product_discount_slider"
                                                            className="noUi-sm"></div>
                                                    </div>
                                                    {/*<!--end::Slider-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set a percentage discount to be
                                                        applied on this product.</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="d-none mb-10 fv-row"
                                                    id="kt_ecommerce_add_product_discount_fixed">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Fixed Discounted Price</label>
                                                    {/*<!--end::Label-->*/}
                                                    {/*<!--begin::Input-->*/}
                                                    <input type="text" name="dicsounted_price"
                                                        className="form-control mb-2" placeholder="Discounted price" />
                                                    {/*<!--end::Input-->*/}
                                                    {/*<!--begin::Description-->*/}
                                                    <div className="text-muted fs-7">Set the discounted product price.
                                                        The product will be reduced at the determined
                                                        fixed price</div>
                                                    {/*<!--end::Description-->*/}
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Tax-->*/}
                                                <div className="d-flex flex-wrap gap-5">
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="fv-row w-100 flex-md-root">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Tax Class</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <select className="form-select mb-2" name="tax"
                                                            data-control="select2" data-hide-search="true"
                                                            data-placeholder="Select an option">
                                                            <option></option>
                                                            <option value="0">Tax Free</option>
                                                            <option value="1">Taxable Goods</option>
                                                            <option value="2">Downloadable Product</option>
                                                        </select>
                                                        {/*<!--end::Select2-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Set the product tax class.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    {/*<!--end::Input group-->*/}
                                                    {/*<!--begin::Input group-->*/}
                                                    <div className="fv-row w-100 flex-md-root">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">VAT Amount (%)</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" className="form-control mb-2" value="" />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">Set the product VAT about.
                                                        </div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                    {/*<!--end::Input group-->*/}
                                                </div>
                                                {/*<!--end:Tax-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Pricing-->*/}
                                        {/*<!--end::General options-->*/}
                                    </div>
                                </div>
                                {/*<!--end::Tab pane-->*/}
                                {/*<!--begin::Tab pane-->*/}
                                <div className="tab-pane fade" id="kt_ecommerce_add_product_advanced" role="tab-panel">
                                    <div className="d-flex flex-column gap-7 gap-lg-10  text-start">
                                        {/*<!--begin::Media-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Media</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row mb-2">
                                                    {images.map((image, index) => (
                                                        <img
                                                            key={index}
                                                            src={image}
                                                            alt={`img${index + 1}`}
                                                            className='rounded mx-2'
                                                            style={{ width: '150px' }} // Inline style for specific width
                                                        />
                                                    ))}                                                 
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Description-->*/}
                                                <div className="text-muted fs-7">Set the product media gallery.</div>
                                                {/*<!--end::Description-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Media-->*/}
                                        {/*<!--begin::Inventory-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Inventory</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0 row">
                                                {/*<!--begin::Input group-->*/}
                                                {/*<!-- begin::category select -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    {/*<!--begin::Label-->*/}
                                                    <label className="form-label">Brand</label>
                                                    {/*<!--begin::Input-->*/}
                                                    <input type="text" name="barcode" className="form-control mb-2"
                                                        placeholder="Barcode Number" value="IKEA" disabled />
                                                    {/*<!--end::Input-->*/}
                                                    {/*<!--end::Select2-->*/}
                                                </div>
                                                {/*<!-- end::category select -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    <label className="form-label">For:</label>
                                                    {/*<!--end::Label-->*/}
                                                    <input type="text" name="barcode" className="form-control mb-2"
                                                        placeholder="Barcode Number" value="Sale" disabled />
                                                </div>
                                                {/*<!--end::Select2-->*/}
                                                {/*<!-- begiin::custom slider -->*/}
                                                {/*<!-- SCRIPT IS AT THE BOTTOM -->*/}
                                                <div className="mb-5 fv-row col-3">
                                                    <div className="mb-0">
                                                        <label className="form-label">Min Price</label>
                                                        {/* <div id="kt_slider_basic"></div>

                                                        <div className="pt-5 row">
                                                            <div className="fw-semibold mb-2 col-6 text-muted">Min:
                                                                <span id="kt_slider_basic_min"></span>
                                                            </div>
                                                            <div className="fw-semibold mb-2 col-6 text-muted">Max:
                                                                <span id="kt_slider_basic_max"></span>
                                                            </div>
                                                        </div> */}
                                                        {/* <PriceSlider></PriceSlider> */}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Price" value="350" disabled />
                                                    </div>
                                                </div>
                                                {/*<!-- end::custom slider -->*/}
                                                <div className='mb-5 fv-row col-3'>
                                                    <div className="mb-0">
                                                        <label className="form-label">Max Price</label>
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Price" value="434" disabled />
                                                    </div>
                                                </div>


                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row row">
                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Quantity</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Quantity" value="1" disabled />
                                                        {/*<!--end::Input-->*/}

                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Listing Type</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="" value="" disabled />
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Tenure</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Tenure" value="1" disabled />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Experience</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter your Experience in years" value="3" disabled />
                                                        {/*<!--end::Input-->*/}
                                                    </div>


                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Current Condition</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="" value="Looks New" disabled />
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label">Style</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="" value="Front" disabled />
                                                    </div>
                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Measurement</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Measurements" value="150 x 250" disabled />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Serial Number</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter the Serial Number" value="234322" disabled />
                                                        {/*<!--end::Input-->*/}
                                                    </div>

                                                    <div className="col-4 mb-5">
                                                        <label className="form-label">Mode of Delivery</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Select2-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="" value="Online" disabled />
                                                    </div>
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                                {/*<!--begin::Input group-->*/}
                                                <div className="fv-row">
                                                    <div className="mb-5 fv-row col-4">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="form-label d-block">Keywords</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        {/* <input id="kt_ecommerce_add_product_tags"
                                                            name="kt_ecommerce_add_product_tags"
                                                            className="form-control mb-2" value="" /> */}
                                                        <Select
                                                            mode="tags"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Tags Mode"
                                                            defaultValue={['furniture', 'beds']}
                                                            disabled
                                                        // onChange={handleChange}
                                                        />
                                                        {/*<!--end::Input-->*/}
                                                        {/*<!--begin::Description-->*/}
                                                        <div className="text-muted fs-7">keywords to the Ad
                                                            so they can be found in search results</div>
                                                        {/*<!--end::Description-->*/}
                                                    </div>
                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Inventory-->*/}
                                        {/*<!--begin::Variations-->*/}
                                        <div className="card card-flush py-4">
                                            {/*<!--begin::Card header-->*/}
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Address</h2>
                                                </div>
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                            {/*<!--begin::Card body-->*/}
                                            <div className="card-body pt-0">
                                                {/*<!--begin::Input group-->*/}
                                                <div className="mb-10 fv-row row">
                                                    <div className="mb-5 fv-row col-3">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">City</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="sku" className="form-control mb-2"
                                                            placeholder="Enter City" value="New York" disabled />
                                                        {/*<!--end::Input-->*/}

                                                    </div>
                                                    <div className="mb-5 fv-row col-4">
                                                        <label className="form-label">Country</label>

                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="Barcode Number" value="United States" disabled />
                                                        {/*<!--end::Input-->*/}

                                                    </div>
                                                    <div className="col-6">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">Latitude</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="" value="12.34567" disabled/>
                                                        {/*<!--end::Input-->*/}
                                                       
                                                    </div>
                                                    <div className="col-6">
                                                        {/*<!--begin::Label-->*/}
                                                        <label className="required form-label">longitude</label>
                                                        {/*<!--end::Label-->*/}
                                                        {/*<!--begin::Input-->*/}
                                                        <input type="text" name="barcode" className="form-control mb-2"
                                                            placeholder="" value="11.34567" disabled/>
                                                        {/*<!--end::Input-->*/}
                                                      
                                                    </div>

                                                </div>
                                                {/*<!--end::Input group-->*/}
                                            </div>
                                            {/*<!--end::Card header-->*/}
                                        </div>
                                        {/*<!--end::Variations-->*/}
                                        <div className="d-flex justify-content-end">
                                            {/*<!--begin::Button-->*/}
                                            <a href="apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel"
                                                className="btn btn-danger me-5">Cancel</a>
                                            {/*<!--end::Button-->*/}
                                            {/*<!-- begin::preview -->*/}
                                            <button type="button" className="btn btn-warning me-5" data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_1">
                                                Launch Preview
                                            </button>
                                            <div className="modal fade" tabindex="-1" id="kt_modal_1">
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content" style={{ height: "550px" }}>
                                                        <div className="modal-header">Preview
                                                          <button type="button" className="btn btn-lg" data-bs-dismiss="modal"><i className="bi bi-x-lg fs-1"></i></button>
                                                      </div>
                                                        {/* <iframe src="./Preview.html" frameborder="0"
                                                            className="w-100 h-100 border-0" allowfullscreen></iframe> */}
                                                            <div className='modal-body'>
                                                            <h2>preview will be shown here</h2>
                                                            </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-danger"
                                                                data-bs-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<!-- end::preview -->*/}
                                            {/*<!--begin::Button-->*/}
                                            <button type="submit" id="kt_ecommerce_add_product_submit"
                                                className="btn btn-primary">
                                                <span className="indicator-label">Save</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            {/*<!--end::Button-->*/}
                                        </div>
                                    </div>
                                </div>
                                {/*<!--end::Tab pane-->*/}
                            </div>
                            {/*<!--end::Tab content-->*/}


                        </div>
                        {/*<!--end::Main column-->*/}
                    </form>
                    {/*<!--end::Form-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
        </Base>

    )
}

export default AdDetails