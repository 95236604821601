import React from 'react'
import { useState,useEffect } from 'react';
import Base from '../../Components/Base/Base'
import { Link } from 'react-router-dom';
import { Rate } from 'antd';



const initialData = [
    { id: 1, Title: 'Stylish Sofas on Sale', CategoryName: 'Furniture', Status: 'Active', Price: '$752.34', rating: 3.2, dateAdded: '05/10/2023' },
    { id: 2, Title: 'Elegant Dining Tables Available', CategoryName: 'Furniture', Status: 'Inactive', Price: '$928.50', rating: 2.9, dateAdded: '11/14/2022' },
    { id: 3, Title: 'Luxury Beds for Comfort', CategoryName: 'Furniture', Status: 'Sold', Price: '$472.19', rating: 4.1, dateAdded: '03/28/2023' },
    { id: 4, Title: 'Affordable Modern Furniture Sets', CategoryName: 'Furniture', Status: 'Active', Price: '$214.58', rating: 1.8, dateAdded: '06/12/2023' },
    { id: 5, Title: 'Chic Coffee Tables Sale', CategoryName: 'Furniture', Status: 'Inactive', Price: '$349.64', rating: 3.7, dateAdded: '09/07/2022' },
    { id: 6, Title: 'Vintage Chairs, Great Prices', CategoryName: 'Furniture', Status: 'Sold', Price: '$786.42', rating: 2.5, dateAdded: '02/14/2023' },
    { id: 7, Title: 'Contemporary Office Desks Discount', CategoryName: 'Furniture', Status: 'Active', Price: '$631.23', rating: 4.8, dateAdded: '12/25/2022' },
    { id: 8, Title: 'Beautiful Dressers for Bedrooms', CategoryName: 'Furniture', Status: 'Inactive', Price: '$528.31', rating: 1.2, dateAdded: '08/16/2022' },
    { id: 9, Title: 'Outdoor Patio Furniture Deals', CategoryName: 'Furniture', Status: 'Sold', Price: '$911.00', rating: 3.3, dateAdded: '04/11/2023' },
    { id: 10, Title: 'Premium Living Room Furniture', CategoryName: 'Furniture', Status: 'Active', Price: '$101.50', rating: 2.0, dateAdded: '10/21/2022' },
    { id: 11, Title: 'Home', CategoryName: 'Realestate', Status: 'Inactive', Price: '$755.90', rating: 4.2, dateAdded: '01/05/2023' },
    { id: 12, Title: 'Home', CategoryName: 'Realestate', Status: 'Sold', Price: '$615.60', rating: 1.9, dateAdded: '07/08/2022' },
    { id: 13, Title: 'Home', CategoryName: 'Realestate', Status: 'Active', Price: '$877.21', rating: 3.6, dateAdded: '03/19/2023' },
    { id: 14, Title: 'Home', CategoryName: 'Realestate', Status: 'Inactive', Price: '$318.74', rating: 2.7, dateAdded: '06/01/2023' }
];


const SellerAds = () => {

    const [data, setData] = useState(initialData);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Adjust rows per page as needed


    // Reset current page to 1 when searchQuery or statusFilter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Filter data based on search query for all columns
    const filteredData = data.filter(item => {
        const itemString = Object.values(item).join(' ').toLowerCase();
        const matchesSearch = itemString.includes(searchQuery.toLowerCase());
        return matchesSearch;
    });

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const ellipsis = <span key="ellipsis">...</span>;

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'

                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    disabled={currentPage === 1}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-outline-primary'

                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(ellipsis);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        disabled={currentPage === i}
                        style={{ margin: '0 5px' }}
                        className='btn btn-sm btn-outline-primary'
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(ellipsis);
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    disabled={currentPage === totalPages}
                    style={{ margin: '0 5px' }}
                    className='btn btn-sm btn-primary'

                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    // Function to get the color based on status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return 'badge badge-light-primary';
            case 'Inactive':
                return 'badge badge-light-danger';
            case 'Sold':
                return 'badge badge-light-warning';
            default:
                return 'black';
        }
    };
    return (
        <Base>
            {/*<!--begin::Toolbar-->*/}
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
                {/*<!--begin::Container-->*/}
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    {/*<!--begin::Page title-->*/}
                    <div className="page-title d-flex flex-column">
                        {/*<!--begin::Breadcrumb-->*/}
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <a href="../../index.html" className="text-gray-600 text-hover-primary">
                                    <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                                </a>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            {/*<!--end::Item-->*/}
                            {/*<!--begin::Item-->*/}
                            <li className="breadcrumb-item text-gray-500">Seller Ads</li>
                            {/*<!--end::Item-->*/}
                        </ul>
                        {/*<!--end::Breadcrumb-->*/}
                        {/*<!--begin::Title-->*/}
                        <h1
                            className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                            Seller Ads</h1>
                        {/*<!--end::Title-->*/}
                    </div>
                    {/*<!--end::Page title-->*/}
                    <Link to="/newad" className="btn bg-body d-flex flex-center h-35px h-lg-40px">Create
                        <span className="d-none d-sm-inline ps-2">New</span></Link>
                </div>
                {/*<!--end::Container-->*/}
            </div>
            {/*<!--end::Toolbar-->*/}
            {/*<!--begin::Container-->*/}
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                {/*<!--begin::Post-->*/}
                <div className="content flex-row-fluid" id="kt_content">
                    {/*<!--begin::Products-->*/}
                    <div className="card card-flush">
                        {/*<!--begin::Card header-->*/}
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            {/*<!--begin::Card title-->*/}
                            <div className="card-title">
                                {/*<!--begin::Search-->*/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search"
                                        className="form-control form-control-solid w-250px ps-12"
                                        placeholder="Search Subcategories"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                </div>
                                {/*<!--end::Search-->*/}
                            </div>
                            {/*<!--end::Card title-->*/}
                            {/*<!--begin::Card toolbar-->*/}
                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                {/*<!--begin::Flatpickr-->*/}
                                {/*<!-- <div className="input-group w-250px">
                                    <input className="form-control form-control-solid rounded rounded-end-0"
                                        placeholder="Pick date range" id="kt_ecommerce_sales_flatpickr" />
                                    <button className="btn btn-icon btn-light" id="kt_ecommerce_sales_flatpickr_clear">
                                        <i className="ki-duotone ki-cross fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </button>
                                </div> -->*/}
                                {/*<!--end::Flatpickr-->*/}
                                <div className="w-100 mw-150px">
                                    {/*<!--begin::Select2-->*/}
                                    <select className="form-select form-select-solid" data-control="select2"
                                        data-hide-search="true" data-placeholder="Status"
                                        data-kt-ecommerce-order-filter="status">
                                        <option></option>
                                        <option value="all">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Sold">Sold</option>
                                    </select>
                                    {/*<!--end::Select2-->*/}
                                </div>
                                {/* <div className="dropdown">
                                    <button type="button" className="btn btn-primary dropdown-toggle m-0 no-caret"
                                        data-bs-toggle="dropdown">
                                        <i className="bi bi-person-lines-fill fs-1"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Contact Adminstrator</a></li>
                                        <li><a className="dropdown-item" href="#">Link 2</a></li>
                                        <li><a className="dropdown-item" href="#">Link 3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                            {/*<!--end::Card toolbar-->*/}
                        </div>
                        {/*<!--end::Card header-->*/}
                        {/*<!--begin::Card body-->*/}
                        <div className="card-body pt-0">
                            {/*<!--begin::Table-->*/}
                            <table className="table align-middle table-row-dashed fs-6 gy-5"
                                id="kt_ecommerce_sales_table">
                                <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                        
                                        <th className="text-center">Listing ID</th>
                                        <th className="text-center">Title</th>
                                        <th className="text-center">Category</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">Rating</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Date Added</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">

                                    {currentData.map(item => (
                                        <tr key={item.id}>
                                            <td className='text-center'>{item.id}</td>
                                            <span className='d-inline-block text-truncate' style={{ maxWidth: '150px' }}>{item.Title}</span>
                                            <td className='text-center'>{item.CategoryName}</td>

                                            <td>
                                              {item.Price}
                                            </td>
                                            <td className='text-center'><Rate disabled allowHalf defaultValue={item.rating} style={{ fontSize: '16px' }} /></td>
                                            <td><span className={getStatusColor(item.Status)}><b>{item.Status}</b></span></td>
                                            <td>{item.dateAdded}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">View</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '10px' }} className='float-end'>
                                <button className='btn btn-primary btn-sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                {renderPageNumbers()}
                                <button className='btn btn-primary  btn-sm' onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    Next
                                </button>
                            </div>
                            {/*<!--end::Table-->*/}
                        </div>
                        {/*<!--end::Card body-->*/}
                    </div>
                    {/*<!--end::Products-->*/}
                </div>
                {/*<!--end::Post-->*/}
            </div>
            {/*<!--end::Container-->*/}
        </Base>
    )
}

export default SellerAds