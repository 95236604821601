import React from 'react'
import Base from '../Components/Base/Base';
import img01 from '../custom assets/images/apartment for slae.png';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Base>
      {/*<!--begin::Toolbar-->*/}
      <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
        {/*<!--begin::Container-->*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
          {/*<!--begin::Page title-->*/}
          <div className="page-title d-flex flex-column">
            {/*<!--begin::Breadcrumb-->*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                <a href="index.html" className="text-gray-600 text-hover-primary">
                  <i className="ki-duotone ki-home text-gray-700 fs-6"></i>
                </a>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item">
                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Account</li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item">
                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
              </li>
              {/*<!--end::Item-->*/}
              {/*<!--begin::Item-->*/}
              <li className="breadcrumb-item text-gray-500">Overview</li>
              {/*<!--end::Item-->*/}
            </ul>
            {/*<!--end::Breadcrumb-->*/}
            {/*<!--begin::Title-->*/}
            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
              Dashboard
              {/*<!--begin::Description-->*/}
              <span className="page-desc text-muted fs-7 fw-semibold pt-1"></span>
              {/*<!--end::Description-->*/}
            </h1>
            {/*<!--end::Title-->*/}
          </div>
          {/*<!--end::Page title-->*/}

        </div>
        {/*<!--end::Container-->*/}
      </div>
      {/*<!--end::Toolbar-->*/}
      {/*<!--begin::Container-->*/}
      <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*<!--begin::Post-->*/}
        <div className="content flex-row-fluid" id="kt_content">
          {/*<!--begin::Row-->*/}
          <div className="row g-5 g-lg-10">
            {/*<!--begin::Col-->*/}
            <div className="col-xl-4 mb-xl-10">
              {/*<!--begin::Mixed Widget 19-->*/}
              <div className="card h-md-100">
                {/*<!--begin::Beader-->*/}
                <div className="card-header border-0 py-5">
                  <h3 className="card-title align-items-start flex-column">

                    <span className="card-label fw-bold fs-3 mb-1">
                      <i className="bi bi-graph-up-arrow  text-primary fs-1 ms-n1 flex-grow-1"></i>
                      Weekly Stats
                    </span>
                  </h3>
                  <div className="card-toolbar">
                    {/*<!--begin::Menu-->*/}
                    <i className="ki-duotone ki-category fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>

                    {/*<!--end::Menu 1-->*/}
                    {/*<!--end::Menu-->*/}
                  </div>
                </div>
                {/*<!--end::Header-->*/}
                {/*<!--begin::Body-->*/}
                <div className="card-body p-0 d-flex flex-column">
                  {/*<!--begin::Items-->*/}
                  <div className="card-px pt-5 pb-10 flex-grow-1">
                    {/*<!--begin::Item-->*/}
                    <div className="d-flex align-items-sm-center mb-7">
                      {/*<!--begin::Symbol-->*/}
                      <div class="symbol symbol-50px me-5">
                        <span class="symbol-label">
                          <i className="bi bi-newspaper text-success fs-1 ms-n1 flex-grow-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      {/*<!--end::Symbol-->*/}
                      {/*<!--begin::Section-->*/}
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                          <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold">New Ads</a>
                          <span class="text-muted fw-semibold d-block fs-7">Latest Ad Releases</span>
                        </div>
                        <span className="badge badge-light fw-bold my-2">4,234</span>
                      </div>
                      {/*<!--end::Section-->*/}
                    </div>
                    {/*<!--end::Item-->*/}
                    {/*<!--begin::Item-->*/}
                    <div className="d-flex align-items-sm-center mb-7">
                      <div class="symbol symbol-50px me-5">
                        <span class="symbol-label">
                          <i className="bi bi-person-fill-check text-primary fs-1 ms-n1 flex-grow-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      {/*<!--begin::Section-->*/}
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">

                          <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold">New
                            Sellers</a>
                          <span class="text-muted fw-semibold d-block fs-7">Newest Seller Profiles</span>
                        </div>
                        <span className="badge badge-light fw-bold my-2">1,032</span>
                      </div>
                      {/*<!--end::Section-->*/}
                    </div>
                    {/*<!--end::Item-->*/}
                    {/*<!--begin::Item-->*/}
                    <div className="d-flex align-items-sm-center">
                      {/*<!--begin::Symbol-->*/}
                      <div class="symbol symbol-50px me-5">
                        <span class="symbol-label">
                          <i className="bi bi-bag-fill text-info fs-1 ms-n1 flex-grow-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                          </i>                        
                          </span>
                      </div>
                      {/*<!--end::Symbol-->*/}
                      {/*<!--begin::Section-->*/}
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                          <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">New Buyers</a>
                          <span class="text-muted fw-semibold d-block fs-7">This Week's Buyers</span>
                        </div>
                        <span className="badge badge-light fw-bold my-2">4,567</span>
                      </div>
                      {/*<!--end::Section-->*/}
                    </div>
                    {/*<!--end::Item-->*/}
                  </div>
                  {/*<!--end::Items-->*/}
                  {/*<!--begin::Chart-->*/}
                  <div id="kt_charts_mixed_widget_19_chart" className="card-rounded-bottom" style={{ height: "150px" }}>
                  </div>
                  {/*<!--end::Chart-->*/}
                </div>
                {/*<!--end::Body-->*/}
              </div>
              {/*<!--end::Mixed Widget 19-->*/}
            </div>
            {/*<!--end::Col-->*/}
            {/*<!--begin::Col-->*/}
            <div className="col-xl-4">
              {/*<!--begin::Row-->*/}
              <div className="row g-5 g-lg-10">
                {/*<!--begin::Col-->*/}
                <div className="col-lg-6 mb-lg-10">
                  {/*<!--begin::Tiles Widget 5-->*/}
                  <Link to='listings' className="card bg-success h-150px">
                    {/*<!--begin::Body-->*/}
                    <div className="card-body d-flex flex-column text-start">
                      <i className="ki-duotone ki-element-11 text-white fs-2hx ms-n1 flex-grow-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                      <div className="">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">33,900</div>
                        <div className="text-white fw-semibold fs-4">Total Ads</div>
                      </div>
                    </div>
                    {/*<!--end::Body-->*/}
                  </Link>
                  {/*<!--end::Tiles Widget 5-->*/}
                </div>
                {/*<!--end::Col-->*/}
                {/*<!--begin::Col-->*/}
                <div className="col-lg-6 mb-lg-10">
                  {/*<!--begin::Tiles Widget 5-->*/}
                  <Link to='/buyers' className="card bg-info h-150px">
                    {/*<!--begin::Body-->*/}
                    <div className="card-body d-flex flex-column text-start">

                      <i className="bi bi-bag-fill text-white fs-2hx ms-n1 flex-grow-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                      <div className="">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">12,931</div>
                        <div className="text-white fw-semibold fs-6">Total buyers</div>
                      </div>
                    </div>
                    {/*<!--end::Body-->*/}
                  </Link>
                  {/*<!--end::Tiles Widget 5-->*/}
                </div>
                {/*<!--end::Col-->*/}
              </div>
              {/*<!--end::Row-->*/}
              {/*<!--begin::Row-->*/}
              <div className="row g-5 g-lg-10">
                {/*<!--begin::Col-->*/}
                <div className="col-lg-6 mb-lg-10">
                  <a href="#" className="card bg-warning h-150px">
                    {/*<!--begin::Body-->*/}
                    <div className="card-body d-flex flex-column text-start">

                      <i className="bi bi-newspaper text-white fs-2hx ms-n1 flex-grow-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <div className="d-flex flex-column">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">809</div>
                        <div className="text-white fw-semibold fs-6">Today's New Ads</div>
                      </div>
                    </div>
                    {/*<!--end::Body-->*/}
                  </a>
                </div>
                {/*<!--end::Col-->*/}
                {/*<!--begin::Col-->*/}
                <div className="col-lg-6 mb-5 mb-lg-10">
                  {/*<!--begin::Tiles Widget 5-->*/}
                  <Link to='/sellers' className="card bg-primary h-150px">
                    {/*<!--begin::Body-->*/}
                    <div className="card-body d-flex flex-column text-start">
                      <i className="bi bi-person-fill-check text-white fs-2hx ms-n1 flex-grow-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>

                      <div className="d-flex flex-column">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">4,093</div>
                        <div className="text-white fw-semibold fs-6">Total Sellers</div>
                      </div>
                    </div>
                    {/*<!--end::Body-->*/}
                  </Link>
                  {/*<!--end::Tiles Widget 5-->*/}
                </div>
                {/*<!--end::Col-->*/}
              </div>
              {/*<!--end::Row-->*/}
              {/*<!--begin::Engage widget 4-->*/}
              <a href="#" className="card bgi-no-repeat h-150px mb-xl-10" style={{
                backgroundColor: '#4AB58E',
                backgroundPosition: 'calc(100% + 1rem) bottom',
                backgroundSize: '25% auto',
                backgroundImage: "url('assets/media/svg/humans/custom-1.svg')"
              }}>
                {/*<!--begin::Body-->*/}
                <div className="card-body d-flex flex-column align-items-start justify-content-center">
                  <h3 className="text-white fw-bold mb-3"></h3>
                  <p className="text-white fs-7">Streamlining the marketplace—your expertise
                    <br /> makes every transaction seamless.
                  
                  </p>
                </div>
                {/*<!--end::Body-->*/}
              </a>
              {/*<!--end::Engage widget 4-->*/}
            </div>
            {/*<!--end::Col-->*/}
            {/*<!--begin::Col-->*/}
            <div className="col-xl-4 mb-xl-10">
              {/*<!--begin::Engage widget 3-->*/}

              <div id="carouselAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="card">
                      <div className="card-img">
                        <img src={img01} alt="img"
                          className="img-fluid rounded-top" />
                      </div>
                      <div className="card-body ">
                        <div className="card-img-overlay row align-items-en">
                          <a to="/product" className="col-6 text-white  h4">Appartment</a>
                          <h4 className="col-6 mb-0 text-end text-white">$925</h4>
                        </div>
                        <div className="card-text">
                          <a to="/product" className="text-dark">
                            <h4 className="text-muted">Appartment for sale</h4>
                          </a>
                          <div className=" mb-2 row">
                            <div className="col-6 my-2 "> <i className="bi bi-geo-alt text-primary"></i>
                              Los Angles</div>
                            <div className="col-6 my-2"> <i className="bi bi-calendar-event text-primary"></i> 5
                              hours
                              ago</div>
                            <div className="col-6 my-2"> <i className="bi bi-person text-primary"></i> Sally
                              Peake</div>
                            <div className="col-6 my-2"> <i className="bi bi-telephone text-primary"></i> 567
                              9876 087</div>
                          </div>
                          <p className="mb-0">Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem....</p>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="d-md-flex mt-0 mb-0">
                          <div className="d-flex footerimg-l mb-0">
                            <img src="./images/300-1.jpg" alt="image"
                              className="me-2 img-fluid rounded-circle" width="35px" />
                            <h5 className="time-title text-muted p-0 leading-normal mt-2 mb-0">Joan Hunter<i
                              className="si si-check text-success fs-12 ms-1" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="verified"></i></h5>
                          </div>
                          <div className="mt-2 ms-auto">
                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top"
                              title="Comments"><span className="text-muted me-2"><i
                                className="fa fa-comment-o"></i> 19</span></a>
                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top"
                              title="Views"><span className="text-muted"><i className="fa fa-eye"></i>
                                24</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<!-- <div className="carousel-item">
                  <img src="./images/appartment sale.png" className="d-block w-100" alt="...">
                </div>
                <div className="carousel-item">
                  <img src="./images/appartments.png" className="d-block w-100" alt="...">
                </div> -->*/}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselAutoplaying"
                  data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselAutoplaying"
                  data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              {/*<!--end::Engage widget 3-->*/}
            </div>
            {/*<!--end::Col-->*/}
            <div className="card mb-5 mb-xl-8">
              {/*<!--begin::Header-->*/}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Recent Ads</span>
                </h3>
                <div className="card-toolbar">
                  {/*<!--begin::Menu-->*/}
                  <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    <i className="ki-duotone ki-category fs-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                  </button>
                  {/*<!--begin::Menu 2-->*/}
                  <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                    data-kt-menu="true">
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3">
                      <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick
                        Actions</div>
                    </div>
                    {/*<!--end::Menu item-->*/}
                    {/*<!--begin::Menu separator-->*/}
                    <div className="separator mb-3 opacity-75"></div>
                    {/*<!--end::Menu separator-->*/}
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">New Ticket</a>
                    </div>
                    {/*<!--end::Menu item-->*/}
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">New Customer</a>
                    </div>
                    {/*<!--end::Menu item-->*/}
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3" data-kt-menu-trigger="hover"
                      data-kt-menu-placement="right-start">
                      {/*<!--begin::Menu item-->*/}
                      <a href="#" className="menu-link px-3">
                        <span className="menu-title">New Group</span>
                        <span className="menu-arrow"></span>
                      </a>
                      {/*<!--end::Menu item-->*/}
                      {/*<!--begin::Menu sub-->*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*<!--begin::Menu item-->*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">Admin Group</a>
                        </div>
                        {/*<!--end::Menu item-->*/}
                        {/*<!--begin::Menu item-->*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">Staff Group</a>
                        </div>
                        {/*<!--end::Menu item-->*/}
                        {/*<!--begin::Menu item-->*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">Member Group</a>
                        </div>
                        {/*<!--end::Menu item-->*/}
                      </div>
                      {/*<!--end::Menu sub-->*/}
                    </div>
                    {/*<!--end::Menu item-->*/}
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">New Contact</a>
                    </div>
                    {/*<!--end::Menu item-->*/}
                    {/*<!--begin::Menu separator-->*/}
                    <div className="separator mt-3 opacity-75"></div>
                    {/*<!--end::Menu separator-->*/}
                    {/*<!--begin::Menu item-->*/}
                    <div className="menu-item px-3">
                      <div className="menu-content px-3 py-3">
                        <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                      </div>
                    </div>
                    {/*<!--end::Menu item-->*/}
                  </div>
                  {/*<!--end::Menu 2-->*/}
                  {/*<!--end::Menu-->*/}
                </div>
              </div>
              {/*<!--end::Header-->*/}
              {/*<!--begin::Body-->*/}
              <div className="card-body py-3">
                {/*<!--begin::Table container-->*/}
                <div className="table-responsive">
                  {/*<!--begin::Table-->*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    {/*<!--begin::Table head-->*/}
                    <thead>
                      <tr className="fw-bold text-muted">
                        <th className="w-25px">
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" value="1"
                              data-kt-check="true" data-kt-check-target=".widget-13-check" />
                          </div>
                        </th>
                        <th className="min-w-150px">Ad Id</th>
                        <th className="min-w-140px">Country</th>
                        <th className="min-w-120px">Date</th>
                        <th className="min-w-120px">Category</th>
                        <th className="min-w-120px">Price</th>
                        <th className="min-w-120px">Status</th>
                        <th className="min-w-100px text-end">Actions</th>
                      </tr>
                    </thead>
                    {/*<!--end::Table head-->*/}
                    {/*<!--begin::Table body-->*/}
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input widget-13-check" type="checkbox" value="1" />
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">56037-XDER</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Brasil</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">05/28/2020</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Furniture</a>
                        </td>
                        <td className="text-gray-900 fw-bold text-hover-primary fs-6">$3560</td>
                        <td>
                          <span className="badge badge-light-success">Active</span>
                        </td>
                        <td className="text-end">
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-switch fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-pencil fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="ki-duotone ki-trash fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                            </i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input widget-13-check" type="checkbox" value="1" />
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">05822-FXSP</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Belarus</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">04/18/2021</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Real
                            Estate</a>
                        </td>
                        <td className="text-gray-900 fw-bold text-hover-primary fs-6">$4850</td>
                        <td>
                          <span className="badge badge-light-warning">Sold</span>
                        </td>
                        <td className="text-end">
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-switch fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-pencil fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="ki-duotone ki-trash fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                            </i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input widget-13-check" type="checkbox" value="1" />
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">4472-QREX</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Phillipines</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">07/23/2019</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Jobs</a>
                        </td>
                        <td className="text-gray-900 fw-bold text-hover-primary fs-6">$8376</td>
                        <td>
                          <span className="badge badge-light-danger">Sold</span>
                        </td>
                        <td className="text-end">
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-switch fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-pencil fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="ki-duotone ki-trash fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                            </i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input widget-13-check" type="checkbox" value="1" />
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">00347-BCLQ</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Argentina</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">12/21/2021</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Plumbing</a>
                        </td>
                        <td className="text-gray-900 fw-bold text-hover-primary fs-6">$9486</td>
                        <td>
                          <span className="badge badge-light-info">Active</span>
                        </td>
                        <td className="text-end">
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-switch fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-pencil fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="ki-duotone ki-trash fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                            </i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input widget-13-check" type="checkbox" value="1" />
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">59486-XDER</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Agoda</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">05/28/2020</a>
                        </td>
                        <td>
                          <a href="#"
                            className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">Beauty</a>
                        </td>
                        <td className="text-gray-900 fw-bold text-hover-primary fs-6">$8476</td>
                        <td>
                          <span className="badge badge-light-primary">Active</span>
                        </td>
                        <td className="text-end">
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-switch fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="ki-duotone ki-pencil fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                          <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="ki-duotone ki-trash fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                            </i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    {/*<!--end::Table body-->*/}
                  </table>
                  {/*<!--end::Table-->*/}
                </div>
                {/*<!--end::Table container-->*/}
              </div>
              {/*<!--begin::Body-->*/}
            </div>
          </div>
        </div>
        {/*<!--end::Post-->*/}
      </div>


      <i class="fa-solid fa-truck"></i>
    </Base>)
}

export default Home